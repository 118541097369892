import React from 'react'
import { Button, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ActivityStatusSelectFilter from '../List/Filters/ActivityStatusSelectFilter'

const useActivityFilterToolbarStyles = makeStyles({
    gridContainer: {
        margin: '0 10px 10px 0',
    },
    leftGrid: {
        padding: '12px 12px 0 0',
    },
    gridItem: {
        padding: '12px 12px 0 12px',
    },
    filterRoot: {
        display: 'flex',
    },
    textField: {
        '& > div.MuiInputBase-root': {
            height: '45px',
            borderRadius: '0',
        },
    },
    clearButton: {
        fontFamily: 'RobotoCondensedBold',
        letterSpacing: '1.5px',
        color: '#626FFC',
    },
})

/**
 * Filter toolbar!
 * @param filters
 * @param setFilters
 * @param props
 * @returns {*}
 * @constructor
 */
export default ({ setFilters, filterValues }) => {
    const classes = useActivityFilterToolbarStyles()

    const handleChange = (source, e) => {
        setFilters({ ...filterValues, [source]: e.target.value })
    }

    return (
        <Grid
            className={classes.gridContainer}
            container
            spacing={0}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >
            <Grid item className={classes.gridItem}>
                <ActivityStatusSelectFilter
                    handleChange={handleChange}
                    filters={filterValues}
                />
            </Grid>
            <Grid item className={classes.gridItem}>
                <Button
                    onClick={() => setFilters({})}
                    className={classes.clearButton}
                >
                    Clear All
                </Button>
            </Grid>
        </Grid>
    )
}
