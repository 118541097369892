import React from 'react'
import { Tab, Tabs } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useTabBarStyles = makeStyles(({ PandiumColors }) => ({
    tabsIndicator: {
        backgroundColor: '#353DDF',
        height: '3px',
    },
    tabsRoot: {
        margin: '15px 0',
    },
    tabRoot: {
        '&:disabled': {
            color: '#353DDF',
            opacity: '1',
        },
        color: '#353DDF',
        opacity: '1',
    },
    tab: {
        borderBottom: `2px solid ${PandiumColors.grey}`,
        padding: '12px 30px',
    },
}))

/**
 * A Pandium-styled bar of tabs
 * @param tabs an array of tab names
 * @param handleChange a function that takes in (event, value) and returns a new tab value
 * @param tabValue the current value of the tab, must be an int between 0 and tabs.length - 1
 **/
export default ({ tabs, handleChange, tabValue }) => {
    const classes = useTabBarStyles()
    return (
        <Tabs
            classes={{
                indicator: classes.tabsIndicator,
                root: classes.tabsRoot,
            }}
            value={tabValue}
            onChange={handleChange}
        >
            {tabs.map((tab) => (
                <Tab
                    key={tab}
                    className={classes.tab}
                    classes={{ selected: classes.tabRoot }}
                    label={tab}
                    disabled={!handleChange}
                />
            ))}
        </Tabs>
    )
}
