import React, { useState, useEffect, useMemo } from 'react'
import { Button, Menu, LinearProgress, MenuItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import BulkEditReleases from './BulkEditTenantRelease'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
    useUnselectAll,
    useRefresh,
    useUpdateMany,
    useNotify,
} from 'react-admin'
import { get, uniq, find } from 'lodash-es'

const useMenuStyles = makeStyles({
    menuWrapper: {
        padding: '0 15px',
    },
})

/**
 * Handles rendering bulk actions. Responsible for fetching relevant resources for bulk edit releases.
 * @param props
 * @returns {*}
 */
const BulkTenantEditMenu = (props) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [hasCongruousIntegrations, setHasCongruousIntegrations] = useState(
        true
    )
    const {
        selectedIds,
        resource,
        integration,
        tenants,
        integrations,
        integrationsLoading,
    } = props
    const classes = useMenuStyles()
    const unselectAll = useUnselectAll('tenants')
    const refresh = useRefresh()
    const [updateMany] = useUpdateMany()
    const notify = useNotify()

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const bulkUpdateSchedule = (selectedIds, paused) => {
        updateMany(
            'tenants',
            { ids: selectedIds, data: { paused: paused } },
            {
                onSuccess: (data) => {
                    refresh()
                    unselectAll()
                    notify('Tenants Updated!')
                },
                onError: (error) => {
                    console.error('Error: ', error)
                    notify('Error Updating Tenants.')
                },
            }
        )
        handleCloseMenu()
    }

    // integration only exists when in IntegrationShow page, otherwise we need to get the integration
    // relevant to the selected tenants on the main list page
    const integrationIds = useMemo(
        () =>
            !integration
                ? selectedIds?.map((id) => {
                      return get(find(tenants, ['id', id]), 'integration.id')
                  })
                : [],
        [integration, selectedIds, tenants]
    )

    useEffect(() => {
        // True if all selected tenants have the same integration
        setHasCongruousIntegrations(uniq(integrationIds).length === 1)
    }, [integrationIds])

    if (integrationsLoading) {
        return <LinearProgress />
    }

    return (
        <div className={classes.menuWrapper}>
            <Button onClick={handleOpenMenu} variant="outlined">
                {' '}
                Select Action <ArrowDropDownIcon />{' '}
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                <MenuItem
                    variant="outlined"
                    onClick={() => bulkUpdateSchedule(selectedIds, true)}
                >
                    Pause Sync
                </MenuItem>
                <MenuItem
                    variant="outlined"
                    onClick={() => bulkUpdateSchedule(selectedIds, false)}
                >
                    Resume Sync
                </MenuItem>
                <BulkEditReleases
                    selectedIds={selectedIds}
                    resource={resource}
                    integration={
                        // Returns integration if from integration show page. Else returns selected tenants integration
                        // Else you selected mixed integrations, return false
                        integration
                            ? integration
                            : hasCongruousIntegrations
                            ? find(integrations, ['id', integrationIds[0]])
                            : false
                    }
                    handleCloseMenu={handleCloseMenu}
                />
            </Menu>
        </div>
    )
}

export default BulkTenantEditMenu
