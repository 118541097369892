import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import Keycloak from 'keycloak-js'
import ErrorPage from './components/ErrorPage'
import * as Sentry from '@sentry/browser'
import * as configs from './appConfigs'
import { StylesProvider, createGenerateClassName } from '@mui/styles'

Sentry.init({
    dsn:
        'https://62475519f136423abbd1189488d94e16@o249596.ingest.sentry.io/1433200',
    enabled: process.env.NODE_ENV === 'production',
})

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { error: null }
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error })
        Sentry.withScope((scope) => {
            Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key])
            })
            Sentry.captureException(error)
        })
    }

    render() {
        if (this.state.error) {
            return <ErrorPage />
        } else {
            //when there's not an error, render children untouched
            return this.props.children
        }
    }
}

const keycloak = new Keycloak(configs.KeycloakConfig)

const generateClassName = createGenerateClassName({
    productionPrefix: 'c',
    disableGlobal: true,
})

keycloak
    .init({ onLoad: 'login-required' })
    .then(() => {
        sessionStorage.setItem('kc', JSON.stringify(keycloak))
        sessionStorage.setItem('token', keycloak.token)
        const root = createRoot(document.getElementById('root'))
        root.render(
            <ErrorBoundary>
                <StylesProvider generateClassName={generateClassName}>
                    <App keycloak={keycloak} />
                </StylesProvider>
            </ErrorBoundary>
        )
    })
    .catch(() => {
        //TODO: 'Will need a 500 "page"'. May we can push to some state here...
        console.error('Bruh')
    })

keycloak.onTokenExpired = () => {
    keycloak
        .updateToken()
        .then(() => {
            sessionStorage.setItem('token', keycloak.token)
        })
        .catch(() => {
            console.debug('Failed to refresh token')
        })
}
