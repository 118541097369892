import { React, useState } from 'react'
import { useUpdate, useNotify, Confirm } from 'react-admin'
import { Grid, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { get, isEmpty } from 'lodash-es'
import PandiForm from '../../themeConfig/PandiForm'
import { emailForm } from './UserFormSchema'
import { useForm, FormProvider } from 'react-hook-form'

const useEmailSectionStyles = makeStyles({
    flexWrapper: {
        display: 'flex',
    },
    button: {
        margin: '0 2px 6px',
        padding: '0 12px',
    },
})

const validateUserEdit = (values) => {
    const errors = {}
    if (
        !isEmpty(values.confirmEmail) &&
        values.newEmail !== values.confirmEmail
    ) {
        errors.confirmEmail = 'Does not match email'
    }
    if (!values.currentPassword) {
        errors.currentPassword = "Password can't be blank."
    }

    return { values: values, errors: errors }
}

const EmailFormActions = ({ values, record, formState }) => {
    const classes = useEmailSectionStyles()
    const [updateUserEmail] = useUpdate()
    const [openConfirm, setOpenConfirm] = useState(false)
    const notify = useNotify()
    const updateEmail = () => {
        updateUserEmail(
            'users',
            {
                id: record.id,
                data: {
                    ...record,
                    currentPassword: values.currentPassword,
                    email: values.newEmail,
                },
                previousData: record,
            },
            {
                onSuccess: (data) => {
                    setOpenConfirm(false)
                    notify('Email Updated Successfully!')
                    // Resets form and removes errors from validation updates current email to newly set email
                },
                onFailure: (error) => {
                    setOpenConfirm(false)
                    if (error.status === 403) {
                        notify('Email Failed to Update: Invalid Credentials', {
                            type: 'warning',
                        })
                    } else {
                        notify('Email Failed to Update: ' + error['message'], {
                            type: 'warning',
                        })
                    }
                    console.debug('Error: ', get(error, 'message', error))
                },
            }
        )
    }

    const disabled = !formState.isDirty || !formState.isValid

    return (
        <Grid className={classes.button}>
            <Button
                disabled={disabled}
                onClick={() => setOpenConfirm(true)}
                className={'filledButton'}
            >
                Change your email
            </Button>
            <Confirm
                isOpen={openConfirm}
                title="Change Email"
                content="Are you sure you want to change your email?"
                onConfirm={() => updateEmail()}
                onClose={() => setOpenConfirm(false)}
                confirmColor={'#626FFC'}
            />
        </Grid>
    )
}

/**
 *
 * @param record
 * @returns {JSX.Element}
 */
export default ({ record }) => {
    const classes = useEmailSectionStyles()
    const methods = useForm({
        defaultValues: {
            currentEmail: record.email,
        },
        resolver: validateUserEdit,
        mode: 'onBlur',
    })

    return (
        <Grid item xs>
            <FormProvider {...methods}>
                <Grid container item spacing={0} direction="row">
                    <Grid item xs={6}>
                        <PandiForm
                            schema={[...emailForm]}
                            values={methods.getValues()}
                        />
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={6} className={classes.flexWrapper}>
                        <EmailFormActions
                            values={methods.getValues()}
                            record={record}
                            formState={methods.formState}
                        />
                    </Grid>
                </Grid>
            </FormProvider>
        </Grid>
    )
}
