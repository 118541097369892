import React from 'react'
import PandiForm from '../themeConfig/PandiForm'
import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { validateEmail } from '../../inputValidators'
import { Capitalize } from '@thefront/pandipackV2'
import { UpdateButton } from '../../Wizard/WizardFormButtons'
import { useForm, FormProvider } from 'react-hook-form'

const createField = (values) => {
    // TODO: Redo layout when more notifications are added
    // Helps try to force a specific order on frontend
    let fields = {
        'accountNotifications.integration_created': {},
        'accountNotifications.tenant_created': {},
        'accountNotifications.partner_integration_updated': {},
    }

    const { accountNotifications } = values
    for (let key of Object.keys(accountNotifications)) {
        const { description } = accountNotifications[key]
        // If there is no description in the db the name will be used for label
        const label =
            description ||
            key
                .split('_')
                .map((word) => Capitalize(word))
                .join(' ')
        fields[`accountNotifications.${key}`] = {
            type: 'mailingList',
            label: label,
            validate: [emailStringValidation],
        }
    }
    return fields
}

const generalNotifications = (fields) => [
    {
        title: 'General Notifications',
        subheading:
            'These notifications are global, and will be sent to all emails that have been entered into the below fields.',
        docLink:
            'https://docs.pandium.com/platform-guides/administrator-guides/administrator-settings#partners',
        fields: fields,
    },
]

const useNotificationFormStyles = makeStyles({
    actionButtonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    button: {
        margin: '0 24px',
    },
})

const formatValues = (values) => {
    const { accountNotifications } = values
    for (let notif in accountNotifications) {
        const { emails } = accountNotifications[notif]
        if (emails) {
            accountNotifications[notif].emails = emails.replace(
                /,(?=[^\s])/g,
                ', '
            )
        }
    }
    return { ...values, accountNotifications: accountNotifications }
}

export default ({ resource, record }) => {
    const classes = useNotificationFormStyles()
    const notificationSchema = generalNotifications(createField(record))
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            ...record,
            accountNotifications: {
                ...record?.accountNotifications,
                integration_created: {
                    ...record?.accountNotifications?.integration_created,
                    enabled:
                        record.accountNotifications?.integration_created
                            ?.enabled || false,
                },
                tenant_created: {
                    ...record?.accountNotifications?.tenant_created,
                    enabled:
                        record?.accountNotifications?.tenant_created?.enabled ||
                        false,
                },
                partner_integration_updated: {
                    ...record?.accountNotifications
                        ?.partner_integration_updated,
                    enabled:
                        record.accountNotifications?.partner_integration_updated
                            ?.enabled || false,
                },
            },
        },
    })
    return (
        <FormProvider {...methods}>
            <>
                <PandiForm
                    schema={notificationSchema}
                    values={methods.getValues()}
                />
                <div className={classes.actionButtonsWrapper}>
                    <div>
                        <Button
                            onClick={() => {
                                methods.reset()
                            }}
                            className={`clearButton ${classes.button}`}
                        >
                            {' '}
                            CANCEL{' '}
                        </Button>
                        <UpdateButton
                            resource={resource}
                            values={formatValues(methods.getValues())}
                            record={record}
                            disabled={
                                !methods.formState.isDirty ||
                                !methods.formState.isValid
                            }
                            redirectOnSuccess={false}
                            successMessage={'Notification Settings'}
                        >
                            Save
                        </UpdateButton>
                    </div>
                </div>
            </>
        </FormProvider>
    )
}

const emailStringValidation = (str) => {
    if (str) {
        const emails = str.split(',').map((e) => e.trim())
        for (let email of emails) {
            if (email.length && validateEmail(email)) {
                return `${email} is an invalid email address.`
            }
        }
    }
    return undefined
}
