import { Link, IconButton, DialogContentText } from '@mui/material'
import { truncate } from 'lodash-es'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileCopy from '@mui/icons-material/FileCopy'
import { withJsonFormsControlProps } from '@jsonforms/react'

const LinkWithCopyControl = ({ label, path, data }) => {
    return (
        <div>
            <DialogContentText
                sx={{
                    fontSize: '0.82rem',
                }}
            >
                {label}
            </DialogContentText>
            <CopyToClipboard text={data}>
                <IconButton>
                    <FileCopy />
                </IconButton>
            </CopyToClipboard>
            <Link
                href={data}
                sx={{
                    color: '#1976d2',
                }}
                target="_blank"
                rel="noopener noreferrer"
            >
                {' '}
                {truncate(data, {
                    length: 50,
                })}{' '}
            </Link>
        </div>
    )
}

export default withJsonFormsControlProps(LinkWithCopyControl)
