import React, { useState, useRef, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import {
    Button,
    MenuList,
    MenuItem,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    Typography,
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { useLogout } from 'ra-core'
import { MenuItemLink } from 'react-admin'

const useAppBarMenuStyles = makeStyles((theme) => ({
    username: {
        fontFamily: 'RobotoCondensedBold',
        color: 'white',
        textDecoration: 'none',
        fontSize: '20px',
        lineHeight: '28px',
        padding: '0 8px',
        margin: '0',
        textTransform: 'none',
        letterSpacing: '1px',
    },
    paper: {
        width: '170px',
        borderRadius: '4px',
        fontFamily: 'RobotoCondensedBold',
    },
    menuItem: {
        display: 'flex',
        justifyContent: 'space-between',
        color: 'black',
    },
    icon: {
        color: '#757575',
    },
}))

const AppBarMenu = ({ username, user_id }) => {
    const classes = useAppBarMenuStyles()
    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null)

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open)
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = open
    }, [open])

    const logout = useLogout()

    return (
        <div>
            <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="inherit"
                aria-label="account of current user"
            >
                <Typography className={classes.username}>{username}</Typography>
                {open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement="bottom-end"
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'right top'
                                    : 'right bottom',
                        }}
                    >
                        <Paper classes={{ root: classes.paper }} square={true}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <MenuItemLink
                                        className={classes.menuItem}
                                        primaryText={
                                            <>
                                                Your account{' '}
                                                <AccountCircle
                                                    className={classes.icon}
                                                />
                                            </>
                                        }
                                        to={`/users/${user_id}/show/`}
                                        sidebarIsOpen={true}
                                    />
                                    <MenuItem
                                        onClick={logout}
                                        className={classes.menuItem}
                                    >
                                        Log out{' '}
                                        <PowerSettingsNewIcon
                                            className={classes.icon}
                                        />
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}

export default AppBarMenu
