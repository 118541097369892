import React, { Fragment } from 'react'
import BulkTenantEditMenu from './BulkTenantEditMenu'
import { useListContext } from 'react-admin'

/**
 *
 * @param props
 * @returns {*}
 */
export default (props) => {
    const { data: records, resource } = useListContext()
    return (
        <Fragment>
            <BulkTenantEditMenu
                {...props}
                resource={resource}
                tenants={records}
            />
        </Fragment>
    )
}
