import React from 'react'
import { Create } from 'react-admin'
import { useNavigate } from 'react-router-dom'
import { Divider, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PageTitle } from '../../../common'
import { useForm, FormProvider } from 'react-hook-form'
import CreateForm from './CreateForm'
import { validateCreateForm } from './CreateForm'
import { CreateButton } from '../../../Wizard/WizardFormButtons'
import { useSearchParams } from 'react-router-dom'

const usePartnerCreateStyles = makeStyles({
    edit: {
        margin: '20px',
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '300px',
    },
})

export default (props) => {
    const { namespace, ...rest } = props
    const classes = usePartnerCreateStyles()
    let [searchParams] = useSearchParams()
    const partnerInvite =
        searchParams.has('invitePartner') &&
        searchParams.get('invitePartner') === 'true'

    const navigate = useNavigate()
    const methods = useForm({
        defaultValues: {
            namespace: namespace,
            org_type: 'Partner',
            settings: {},
            approval_status: partnerInvite ? 'Pending' : 'Approved',
        },
        resolver: validateCreateForm,
        mode: 'onBlur', //validation mode
    })
    return (
        <div>
            <PageTitle
                title={
                    partnerInvite ? 'Invite New Partner' : 'Create New Partner'
                }
            />
            <Divider className="pageHead" />
            <Create
                {...rest}
                resource="partners"
                basePath="partners/"
                component="div"
                className={classes.edit}
            >
                <FormProvider {...methods}>
                    <CreateForm {...methods} />
                    <div className={classes.buttonWrapper}>
                        <Button
                            className="clearButton"
                            onClick={() => navigate('/partners')}
                        >
                            {' '}
                            CANCEL{' '}
                        </Button>
                        <CreateButton
                            resource="partners"
                            className="filledButton"
                            values={methods.getValues()}
                            disabled={!methods.formState.isValid}
                            shouldRedirect={true}
                            text="CREATE"
                        />
                    </div>
                </FormProvider>
            </Create>
        </div>
    )
}
