import React from 'react'
import { Show } from 'react-admin'
import { Divider } from '@mui/material'

const classes = {
    showRoot: {
        margin: '0',
        display: 'flex',
        flexDirection: 'column',
    },
    showMain: {
        margin: '20px 0 0 0',
    },
    showCard: {
        boxShadow: 'none',
        flex: '1',
        borderRadius: '0',
    },
}

/**
 * A Pandium styled show component
 * @param TopToolbar
 * @param children
 * @param props
 * @returns {*}
 */
export default ({ TopToolbar, children, hasShow, ...props }) => {
    return (
        <>
            {React.cloneElement(TopToolbar)}
            <Divider className="pageHead" />
            <Show
                resource={props.resource}
                actions={null}
                sx={{
                    '& .RaShow-root': classes.showRoot,
                    '& .RaShow-main': classes.showMain,
                    ' & .RaShow-card': classes.showCard,
                }}
            >
                {children}
            </Show>
        </>
    )
}
