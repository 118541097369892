import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

const useCustomLabelStyle = makeStyles({
    root: {
        margin: '14px 0 10px 0',
    },
    title: {
        color: '#6d6d6d',
        fontFamily: 'Roboto Condensed',
        fontSize: '21px',
        display: 'block',
        margin: '10px 0 0 0',
    },
    subtitle: {
        display: 'block',
        margin: '16px 0 36px 0px',
        fontFamily: 'Roboto Condensed',
    },
})

export const CustomLabel = ({ title, subtitle }) => {
    const classes = useCustomLabelStyle()
    return (
        <div className={classes.root}>
            <label className={classes.title}> {title} </label>
            {subtitle && (
                <label className={classes.subtitle}> {subtitle} </label>
            )}
        </div>
    )
}
