import React from 'react'
import { Datagrid } from 'react-admin'
import makeStyles from '@mui/styles/makeStyles'

const useBaseDatagridStyles = makeStyles({
    listMain: {
        border: '1px solid #DBDEE5',
        borderTop: '1px solid #DBDEE5',
    },
})

export default ({ children, rowClick = null, empty = null, ...props }) => {
    const classes = useBaseDatagridStyles()
    return (
        <Datagrid
            rowClick={rowClick}
            {...props}
            className={classes.listMain}
            empty={empty}
            bulkActionButtons={props.bulkActionButtons}
        >
            {React.Children.map(children, (child) => {
                return React.cloneElement(child)
            })}
        </Datagrid>
    )
}
