import React, { useState } from 'react'

// Have to do import from es5 for this to work when we build production build.
import { LazyLog } from 'react-lazylog'
import { Card, CardHeader, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { API_URI } from '../appConfigs'
import { CloudDownload } from '@mui/icons-material'
import { RefreshButton } from 'react-admin'

const useDownloadButtonStyles = makeStyles({
    downloadIcon: {
        color: '#626FFC',
        margin: '0 10px 0 0',
    },
    generalButton: {
        display: 'flex',
        fontFamily: 'RobotoCondensedBold',
        color: '#626FFC',
        fontSize: '17px',
        letterSpacing: '3px',
        border: 'none',
        backgroundColor: 'white',
        marginTop: '3px',
    },
})

const DownloadButton = ({ record = {}, namespace, resource }) => {
    const classes = useDownloadButtonStyles()

    const downloader = () => {
        const token = sessionStorage.getItem('token')
        fetch(`${API_URI}/runs/${record.id}/log`, {
            headers: {
                'X-NAMESPACE': namespace,
                Admin: true,
                Authorization: `Bearer ${token}`, // access will be denied without bearer token
            },
        })
            .then((data) => {
                return data.blob()
            })
            .then((blob) => {
                //unless we use a library this is general advice for downloading data from api call
                //https://stackoverflow.com/questions/44656610/download-a-string-as-txt-file-in-react
                const element = document.createElement('a')
                element.href = URL.createObjectURL(blob)
                element.download = `${record.name}.txt`
                document.body.appendChild(element) // Required for this to work in FireFox
                element.click()
            })
            .catch((err) => console.error(err))
    }

    return (
        <Button className={classes.generalButton} onClick={downloader}>
            <CloudDownload className={classes.downloadIcon} /> DOWNLOAD
        </Button>
    )
}

const useLogToolbarStyles = makeStyles({
    logToolbarRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    logToolbarButtons: {
        display: 'flex',
    },
    generalButton: {
        display: 'flex',
        fontFamily: 'RobotoCondensedBold',
        color: '#626FFC',
        fontSize: '17px',
        letterSpacing: '3px',
        border: 'none',
        backgroundColor: 'white',
        marginTop: '3px',
    },
})

const LogToolbar = ({
    record,
    namespace,
    refreshKey,
    setRefreshKey,
    ...rest
}) => {
    const classes = useLogToolbarStyles()
    return (
        <div className={classes.logToolbarRoot}>
            Log
            <div className={classes.logToolbarButtons}>
                <RefreshButton
                    className={classes.generalButton}
                    onClick={() => setRefreshKey(refreshKey + 1)}
                />
                <DownloadButton
                    record={record}
                    namespace={namespace}
                    className={classes.generalButton}
                />
            </div>
        </div>
    )
}

export const LogField = ({ record, source, namespace }) => {
    const token = sessionStorage.getItem('token')
    const [refreshKey, setRefreshKey] = useState(0)

    return (
        <Card>
            <CardHeader
                title={
                    <LogToolbar
                        record={record}
                        namespace={namespace}
                        refreshKey={refreshKey}
                        setRefreshKey={setRefreshKey}
                    />
                }
            />
            <div>
                <LazyLog
                    key={refreshKey}
                    style={{ overflowX: 'auto', overflowY: 'auto' }}
                    containerStyle={{ overflow: 'initial' }}
                    extraLines={1}
                    height={500}
                    width="auto"
                    url={`${API_URI}/runs/${record[source]}/log`}
                    fetchOptions={{
                        headers: {
                            'X-NAMESPACE': namespace,
                            Admin: true,
                            Authorization: `Bearer ${token}`,
                        },
                    }}
                    follow={true}
                    selectableLines={true}
                />
            </div>
        </Card>
    )
}
