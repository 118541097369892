import React from 'react'
import { LinearProgress, Box } from '@mui/material'
import { PageTitle } from '../../../common'
import { isEmpty } from 'lodash-es'
import ProvisionConnectorForm from '../../../Auth/ProvisionConnectorForm'
import IntegrationEditFormWrapper from './IntegrationEditFormWrapper'

export default (props) => {
    const { integration, values } = props
    return (
        <Box
            sx={(theme) => ({
                width: '776px',
                [theme.breakpoints.up('sm')]: {
                    width: '896px',
                },
                [theme.breakpoints.up('md')]: {
                    width: '960px',
                },
                [theme.breakpoints.up('lg')]: {
                    width: '1120px',
                },
                [theme.breakpoints.up('xl')]: {
                    width: '1280px',
                },
                [theme.breakpoints.up('xxl')]: {
                    width: '1608px',
                },
            })}
        >
            <PageTitle title={`Reprovision Connectors: ${integration.name}`} />
            {!isEmpty(integration) ? (
                <IntegrationEditFormWrapper
                    page={'provision'}
                    cancelLabel={'CLOSE'}
                    {...props}
                >
                    <ProvisionConnectorForm mode="EDIT" values={values} />
                </IntegrationEditFormWrapper>
            ) : (
                <LinearProgress />
            )}
        </Box>
    )
}
