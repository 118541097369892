import React from 'react'
import { LogField } from '../../LogField'
import { SimpleShowLayout, useGetOne, Link } from 'react-admin'
import { KeyboardArrowLeft } from '@mui/icons-material'
import { get } from 'lodash-es'
import { Card, Button, LinearProgress, Box, Typography } from '@mui/material'
import { PageTitle, HorizontalTextField, RunPhaseField } from '../../common'
import { format } from 'date-fns'
import BaseShow from '../Show/BaseShow'
import { useParams, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

const jobTopToolbarStyles = {
    generalButton: {
        display: 'flex',
        fontFamily: 'RobotoCondensedBold',
        color: '#626FFC',
        fontSize: '17px',
        letterSpacing: '3px',
        border: 'none',
        backgroundColor: 'white',
        marginTop: '3px',
    },
    titleRoot: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
}

const JobTopToolbar = () => {
    const navigate = useNavigate()
    return (
        <div>
            <Button
                onClick={() => navigate(-1)}
                sx={jobTopToolbarStyles.generalButton}
            >
                <KeyboardArrowLeft /> Back{' '}
            </Button>
            <Box sx={jobTopToolbarStyles.titleRoot}>
                <Box sx={jobTopToolbarStyles.titleWrapper}>
                    <PageTitle title="Activity Detail" />
                </Box>
            </Box>
        </div>
    )
}

const generalCardStyles = {
    valueText: {
        fontFamily: 'RobotoCondensedBold',
        fontSize: '20px',
        paddingBottom: '5px',
    },
    fieldText: {
        color: '#6d6d6d',
        fontSize: '20px',
        marginBottom: '10px',
    },
    flex1: {
        flex: '1',
        padding: '3px',
    },
    firstCard: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-around',
        boxShadow: 'none',
        flex: '1',
        borderRadius: '0',
        margin: '0px 0 30px 0',
        padding: '10px 50px 10px 50px',
    },
}

const GeneralCard = ({ record, userId, tenantId, isSuperUser }) => {
    // very specific line of code in order for users to get a link to the integration being built by source control
    const integrationBuilt =
        record.jobSpec?.integration?.name.includes('source-control') &&
        !['init', 'webhook'].includes(record.mode) &&
        record?.jobSpec?.tenant?.configs?.force_build_id

    return (
        <Card sx={generalCardStyles.firstCard}>
            <Box sx={generalCardStyles.flex1}>
                <Box sx={generalCardStyles.fieldText}>ID</Box>
                <Box sx={generalCardStyles.valueText}>{record.id}</Box>
            </Box>
            {(isSuperUser ||
                record?.jobSpec?.integration?.type !== 'Pandium') && (
                <Box sx={generalCardStyles.flex1}>
                    <Box sx={generalCardStyles.fieldText}>{'TENANT'}</Box>
                    <Link
                        to={'/tenants/' + tenantId + '/show'}
                        sx={generalCardStyles.valueText}
                    >
                        {userId ? userId : tenantId}
                    </Link>
                </Box>
            )}
            {(isSuperUser ||
                record.jobSpec?.integration?.type !== 'Pandium') && (
                <Box sx={generalCardStyles.flex1}>
                    <Box sx={generalCardStyles.fieldText}>INTEGRATION</Box>
                    <Link
                        to={`/integrations/${record?.integrationId}/show`}
                        sx={generalCardStyles.valueText}
                    >
                        {record?.jobSpec?.integration?.name}
                    </Link>
                </Box>
            )}
            {integrationBuilt && (
                <Box sx={generalCardStyles.flex1}>
                    <Box sx={generalCardStyles.fieldText}>
                        INTEGRATION BUILT
                    </Box>
                    <Link
                        to={`/integrations/${integrationBuilt}/show`}
                        sx={generalCardStyles.valueText}
                    >
                        {record?.jobSpec?.tenant?.configs?.force_build}
                    </Link>
                </Box>
            )}
            <Box sx={generalCardStyles.flex1}>
                <Box sx={generalCardStyles.fieldText}>STATUS</Box>
                <Box sx={generalCardStyles.valueText}>
                    <RunPhaseField record={record} source="status.phase" />{' '}
                </Box>
            </Box>
        </Card>
    )
}

const configsStyles = {
    configurationCard: {
        borderBottom: '1px solid #D8D8D8',
        borderTop: '1px solid #D8D8D8',
        boxShadow: 'none',
        flex: '1',
        borderRadius: '0',
        padding: '36.5px 50px 25.5px 50px',
        display: 'flex',
        margin: '0px 0 30px 0',
        '& > div': {
            width: '100%',
        },
    },
    fieldText: {
        color: '#6d6d6d',
        fontSize: '20px',
        marginBottom: '10px',
    },
}

const ConfigsAndDetails = ({ configs, details }) => {
    return (
        <Card sx={configsStyles.configurationCard}>
            <div>
                <Typography sx={configsStyles.fieldText}>
                    CONFIGURATION
                </Typography>
                {Object.keys(configs).map((key) => (
                    <HorizontalTextField
                        key={key}
                        record={configs}
                        source={key}
                        label={false}
                    />
                ))}
            </div>
            <div>
                <Typography sx={configsStyles.fieldText}>DETAILS</Typography>
                {Object.keys(details).map((key) => (
                    <HorizontalTextField
                        key={key}
                        record={details}
                        source={key}
                        label={false}
                    />
                ))}
            </div>
        </Card>
    )
}

const JobShow = ({ namespace, isSuperUser }) => {
    const { id } = useParams()
    const { data: run, runLoading } = useGetOne('runs', { id: id })
    if (!run || runLoading) {
        return <LinearProgress />
    }

    const tenant = run.jobSpec?.tenant
    const details = {
        Start: run?.startedDate
            ? format(new Date(run.startedDate), 'M/dd/yyyy, h:mm:ss aa')
            : 'n/a',
        Finish: run?.completedDate
            ? format(new Date(run.completedDate), 'M/dd/yyyy, h:mm:ss aa')
            : 'in progress',
        'Integration Release': run.jobSpec?.integration_release?.tag,
    }

    return (
        <BaseShow actions={null} TopToolbar={<JobTopToolbar />}>
            <SimpleShowLayout>
                <GeneralCard
                    isSuperUser={isSuperUser}
                    record={run}
                    userId={get(tenant, 'name')}
                    tenantId={get(tenant, 'id')}
                />
                {tenant && (
                    <ConfigsAndDetails
                        configs={tenant?.configs}
                        details={details}
                    />
                )}
                {
                    <LogField
                        record={run}
                        source="id"
                        namespace={namespace}
                        label={false}
                    />
                }
            </SimpleShowLayout>
        </BaseShow>
    )
}

const mapStateToProps = (state) => ({
    isSuperUser: state.org.isSuperUser,
})

export default connect(mapStateToProps)(JobShow)
