import React, { useState } from 'react'
import ListTopToolbar from '../List/ListTopToolbar'
import UserListTabBar from './UserListTabBar'
import UserListTab from './UserListTab'

export default ({ namespace, ...props }) => {
    const [tabValue, setTabValue] = useState(1)

    const handleChange = (event, newValue) => {
        setTabValue(newValue)
    }

    return (
        <>
            <ListTopToolbar pageTitle="Admin Settings" />
            <UserListTabBar tabValue={tabValue} handleChange={handleChange} />
            {tabValue === 1 && (
                <UserListTab
                    namespace={namespace}
                    basePath="/users"
                    resource="users"
                />
            )}
        </>
    )
}
