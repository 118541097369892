import React, { Fragment, useState, useEffect } from 'react'
import {
    Box,
    Link,
    Typography,
    Divider,
    FormHelperText,
    Grid,
    Paper,
    Button,
} from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { CustomLabel } from '../../../CustomLabel'
import LogoUploader from '../../../react_admin/LogoUploader'
import {
    RichTextInput,
    RichTextInputToolbar,
    LinkButtons,
    FormatButtons,
} from 'ra-input-rich-text'
import {
    ReferenceArrayInput,
    SelectArrayInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    ArrayInput,
    NumberInput,
    BooleanInput,
    SimpleFormIterator,
    FormGroupsProvider,
    useRecordContext,
    useGetList,
} from 'react-admin'
import {
    alphaNumericDash,
    required,
    isLowerCase,
} from '../../../inputValidators'
import TenantCardPreview from '../../../TenantCardPreview'
import MediaCarouselUploader from '../../../react_admin/MediaCarouselUploader'
import { get } from 'lodash-es'
import { IntegrationCard } from '@thefront/pandipackV2'
import { connect } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import ClearIcon from '@mui/icons-material/Clear'
import AddIcon from '@mui/icons-material/Add'

const marketplaceStyle = {
    inputField: {
        minWidth: '300px',
        display: 'flex',
        marginBottom: '10px',
    },
    arrayInput: {
        minWidth: '447px',
        display: 'flex',
        paddingBottom: '15px',
    },
    divider: {
        margin: '32px 0 32px 0',
    },
    publishToggle: {
        border: '1px solid #DBDEE5',
        borderRadius: '4px',
        backgroundColor: '#F7FAFF',
        display: 'inline-flex',
        alignItems: 'center',
        marginTop: '25px',
        padding: '10px',
        maxWidth: '820px',
        width: '100%',
        '.MuiFormControlLabel-label': {
            fontFamily: 'RobotoCondensedBold',
        },
    },
    booleanInput: {
        '.MuiFormControlLabel-root': {
            flexDirection: 'row-reverse',
            marginLeft: '0',
            '.MuiSwitch-root': {
                marginLeft: '16px',
            },
        },
    },
    subtitle: {
        display: 'block',
        margin: '16px 0 36px 0px',
        fontFamily: 'RobotoCondensedRegular',
    },
    mediaSubtitle: {
        display: 'block',
        margin: '16px 0 0 0px',
        fontFamily: 'RobotoCondensedRegular',
    },
    link: {
        color: '#1976d2',
    },
    rowContainer: {
        color: 'red',
    },
    label: {
        fontFamily: 'RobotoCondensedBold',
    },
    richTextContainer: {
        minWidth: '300px',
        display: 'block',
        marginBottom: '10px',
        backgroundColor: '#000000a',
    },
    previewContainer: {
        backgroundColor: '#F1F4FB',
        width: '310px',
        padding: '15px 5px',
        boxShadow: 'none',
        margin: '30px 0 0 0',
    },
    primaryLogoImg: {
        maxWidth: '480px',
    },
    simpleFormIterator: {
        maxWidth: '700px',
    },
    gridOverflow: {
        overflowX: 'auto',
    },
}

const MarketplaceTab = ({
    marketplaceTheme,
    isExternal,
    isParentOrg,
    mode,
    orgName,
    hasGallery,
}) => {
    const { getValues, watch } = useFormContext()
    const formValues = getValues()
    const [flag, setFlag] = useState()
    const [categories, setCategories] = useState([])

    // Update preview card in real-time
    watch('marketplaceSettings.cardShortDescription')
    watch('marketplaceSettings.primaryLogo')
    watch('marketplaceSettings.secondaryLogo')
    watch('categories', [])
    watch('flag')
    const { data: flags, isLoading } = useGetList('integrationlabels', {
        sort: { field: 'name', order: 'ASC' },
        filter: { labelType: 'FLAG' },
        pagination: { page: 1, perPage: 500 },
    })
    const { data: cats } = useGetList('integrationlabels', {
        sort: { field: 'name', order: 'ASC' },
        filter: { label_type__in: JSON.stringify(['CATEGORY', 'SUBCATEGORY']) },
        pagination: { page: 1, perPage: 500 },
    })
    useEffect(() => {
        !isLoading &&
            setFlag(flags?.find((flag) => flag.id === formValues?.flag?.id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues?.flag?.id])

    useEffect(() => {
        cats &&
            setCategories(
                formValues.categories.map((id) =>
                    cats.find((cat) => cat.id === id)
                )
            )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues.categories, cats])

    const toolbarOptions = () => (
        <RichTextInputToolbar>
            <FormatButtons size="large" />
            <LinkButtons size="large" />
        </RichTextInputToolbar>
    )

    const CategoryOptionText = () => {
        const record = useRecordContext()
        return (
            <>
                {record.name.includes('--') ? (
                    <span>
                        <b>{record.name.split('--')[0]}</b>
                        {' > '}
                        <i>{record.name.split('--')[1]}</i>
                    </span>
                ) : (
                    <span>
                        <b>{record.name}</b>
                    </span>
                )}
            </>
        )
    }

    return (
        <Grid container spacing={3}>
            {isParentOrg && (
                <Grid item xs={12}>
                    <Box sx={marketplaceStyle.publishToggle}>
                        <BooleanInput
                            helperText=""
                            label={`Publish to ${orgName}`}
                            source="marketplaceSettings.published"
                            sx={marketplaceStyle.booleanInput}
                        />
                        <FormHelperText sx={{ margin: '0px' }}>
                            {formValues?.marketplaceSettings?.published
                                ? 'Listing is visible in the Marketplace.'
                                : 'Listing is hidden in Marketplace but available to be installed in the AdminDash.'}
                        </FormHelperText>
                    </Box>
                </Grid>
            )}
            <Grid item xs={6}>
                <CustomLabel title="LINKS" />
                <Typography sx={marketplaceStyle.subtitle}>
                    {' '}
                    This information will appear in the Marketplace once your
                    integration is published.
                </Typography>
                <TextInput
                    source="name"
                    label="Integration Name"
                    sx={marketplaceStyle.inputField}
                    helperText="Unique identifier. Must be lowercase and kebab-case. IE: test-integration"
                    validate={[required, alphaNumericDash, isLowerCase]}
                    disabled={mode === 'EDIT'}
                    options={{
                        InputLabelProps: {
                            sx: { root: marketplaceStyle.label },
                        },
                    }}
                    variant="filled"
                />
                <TextInput
                    source="longName"
                    label="Long Name"
                    helperText="Longer name will be displayed in the Marketplace"
                    sx={marketplaceStyle.inputField}
                    validate={required}
                    options={{
                        InputLabelProps: {
                            sx: { root: marketplaceStyle.label },
                        },
                    }}
                    variant="filled"
                />
                <div>
                    {/* TODO: the ReferenceArrayInput component breaks our test 
                    runner and can't be deactivated. We might consider replacing it */}
                    <ReferenceArrayInput
                        source="categories"
                        resource="integrationlabels"
                        filter={{
                            label_type__in: JSON.stringify([
                                'CATEGORY',
                                'SUBCATEGORY',
                            ]),
                            limit: 500,
                        }}
                        reference="integrationlabels"
                        sort={{ field: 'name', order: 'ASC' }}
                    >
                        <SelectArrayInput
                            sx={marketplaceStyle.arrayInput}
                            variant="filled"
                            label="Categories & Subcategories"
                            translateChoice={false}
                            options={{
                                MenuProps: {
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    sx: { marginLeft: '42px' },
                                },
                            }}
                            optionText={<CategoryOptionText />}
                            helperText="Ensure your app is tied to a category unless you only want to display it in the ‘All’ view."
                        />
                    </ReferenceArrayInput>
                </div>
                <div>
                    <ReferenceArrayInput
                        source="tags"
                        resource="integrationlabels"
                        filter={{ label_type: 'TAG' }}
                        reference="integrationlabels"
                        sort={{ field: 'name', order: 'ASC' }}
                    >
                        <SelectArrayInput
                            sx={marketplaceStyle.arrayInput}
                            variant="filled"
                            label="Tags"
                            translateChoice={false}
                            options={{
                                MenuProps: {
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    sx: { marginLeft: '40px' },
                                },
                            }}
                        />
                    </ReferenceArrayInput>
                </div>
                <div>
                    <ReferenceInput
                        source="flag.id"
                        resource="integrationlabels"
                        reference="integrationlabels"
                        filter={{ label_type: 'FLAG' }}
                        sort={{ field: 'name', order: 'ASC' }}
                    >
                        <SelectInput
                            optionText={'name'}
                            emptyText={'Select One'}
                            emptyValue={''}
                            sx={marketplaceStyle.arrayInput}
                            variant="filled"
                            label="Flag"
                            translateChoice={false}
                            onChange={(e) =>
                                setFlag(
                                    flags?.find(
                                        (flag) => flag.id === e.target.value
                                    )
                                )
                            }
                        />
                    </ReferenceInput>
                </div>
                <TextInput
                    source="marketplaceSettings.devURL"
                    variant="filled"
                    label="Developer Website URL"
                    sx={marketplaceStyle.inputField}
                    options={{
                        InputLabelProps: {
                            sx: { root: marketplaceStyle.label },
                        },
                    }}
                />
                <TextInput
                    source="marketplaceSettings.supportURL"
                    variant="filled"
                    label="Support URL"
                    sx={marketplaceStyle.inputField}
                    options={{
                        InputLabelProps: {
                            sx: { root: marketplaceStyle.label },
                        },
                    }}
                />
                <TextInput
                    source="marketplaceSettings.docURL"
                    variant="filled"
                    label="Documentation URL"
                    sx={marketplaceStyle.inputField}
                    options={{
                        InputLabelProps: {
                            sx: { root: marketplaceStyle.label },
                        },
                    }}
                />
                <BooleanInput
                    source="marketplaceSettings.isFeatured"
                    label="Include in Marketplace Carousel"
                    sx={{
                        '& .MuiFormControlLabel-root': {
                            paddingLeft: '4px',
                        },
                    }}
                />
                <Divider sx={marketplaceStyle.divider} />
            </Grid>
            <Grid
                item
                container
                xs={12}
                spacing={3}
                sx={marketplaceStyle.gridOverflow}
            >
                <Grid item xs={6}>
                    <CustomLabel title="LISTING AND FEATURE CARD" />
                    <Typography sx={marketplaceStyle.subtitle}>
                        {' '}
                        This will be shown in search results, the homepage and
                        category pages.
                    </Typography>
                    <TextInput
                        variant="filled"
                        source="marketplaceSettings.cardShortDescription"
                        label="Card Short Description"
                        sx={marketplaceStyle.inputField}
                        helperText="Limit 125 characters."
                        multiline
                        options={{
                            InputLabelProps: {
                                sx: { root: marketplaceStyle.label },
                            },
                        }}
                    />
                    <NumberInput
                        variant="filled"
                        source="marketplaceSettings.orderIndex"
                        label="Listing Order"
                        helperText="Adjust the position of the of the feature card"
                        options={{
                            InputLabelProps: {
                                sx: { root: marketplaceStyle.label },
                            },
                        }}
                    />
                    <LogoUploader
                        source="marketplaceSettings.primaryLogo"
                        label={'LOGO (2X1)'}
                        formDescription={'Best size 480 px x 240 px'}
                        helperText="Appears on feature card. Renders best with a transparent background. Max size 256KB."
                        resource="integrations"
                        id={formValues?.id}
                    />
                    <LogoUploader
                        source="marketplaceSettings.secondaryLogo"
                        label={'LOGO (1X1)'}
                        formDescription={'Best size 240 px x 240 px'}
                        helperText="Appears on installed apps. Renders best with a background color. Max size 256KB."
                        resource="integrations"
                        id={formValues?.id}
                    />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4}>
                    <div>
                        <Paper sx={marketplaceStyle.previewContainer}>
                            {marketplaceTheme && (
                                <IntegrationCard
                                    integration={{
                                        ...formValues,
                                        flag,
                                        categories,
                                    }}
                                    muiTheme={createTheme(
                                        marketplaceTheme.configs
                                    )}
                                    marketplaceSettings={
                                        marketplaceTheme.configs
                                            .marketplaceSettings
                                    }
                                    navigate={() => console.log('hi there!')}
                                />
                            )}
                            <TenantCardPreview
                                img={
                                    formValues?.marketplaceSettings
                                        ?.secondaryLogo
                                }
                                integrationName={get(
                                    formValues,
                                    'longName',
                                    get(formValues, 'name', 'Placeholder')
                                )}
                                dateFormat={get(marketplaceTheme, [
                                    'configs',
                                    'dateFormat',
                                ])}
                            />
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Divider sx={marketplaceStyle.divider} />
                <CustomLabel title="LISTING DETAILS" />
                <Typography sx={marketplaceStyle.subtitle}>
                    {' '}
                    This information is shown on your listing page.
                </Typography>
                <TextInput
                    variant="filled"
                    source="marketplaceSettings.detailShortDescription"
                    label="Detail Short Description"
                    sx={marketplaceStyle.inputField}
                    helperText="Limit 125 characters."
                    multiline
                    options={{
                        InputLabelProps: {
                            classes: { root: marketplaceStyle.label },
                        },
                    }}
                />
                <CustomLabel title="DETAILED DESCRIPTION" />
                <FormGroupsProvider>
                    <ArrayInput
                        source="marketplaceSettings.longDescriptions"
                        sx={marketplaceStyle.arrayInput}
                    >
                        <SimpleFormIterator
                            addButton={
                                <Button>
                                    <AddIcon /> Add Section
                                </Button>
                            }
                            removeButton={
                                <span>
                                    <Button disableRipple={true}>
                                        <ClearIcon /> Remove{' '}
                                    </Button>
                                </span>
                            }
                            disableReordering
                            disableClear
                        >
                            <TextInput
                                variant="filled"
                                source="Subtitle"
                                label="Section name (optional)"
                                helperText="Add a heading above the detailed description. Limit 100 characters."
                                fullWidth={true}
                                options={{
                                    InputLabelProps: {
                                        classes: {
                                            root: marketplaceStyle.label,
                                        },
                                    },
                                }}
                            />
                            <RichTextInput
                                source="Description"
                                label=""
                                toolbar={toolbarOptions()}
                                fullWidth={true}
                                placeholder="Add description here!"
                                helperText="Styling with Markdown is supported. Limit 1000 characters."
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormGroupsProvider>

                <CustomLabel title="MEDIA AND SCREENSHOTS" />
                <Typography sx={marketplaceStyle.mediaSubtitle}>
                    {' '}
                    Add a media gallery to your listing page. Use high
                    resolution images at least 1200px wide.
                </Typography>
                <MediaCarouselUploader source="marketplaceSettings.media" />
                {!isExternal && (
                    <Fragment>
                        <Divider sx={marketplaceStyle.divider} />
                        <CustomLabel title="SETUP CONTENT" />
                        <Typography sx={marketplaceStyle.subtitle}>
                            {' '}
                            Add guided instructions, help text and links to the
                            integration setup. More information can be found in
                            our{' '}
                            <Link
                                sx={marketplaceStyle.link}
                                href="https://docs.pandium.com/platform-guides/administrator-guides/marketplace-settings"
                                target="_blank"
                            >
                                {' '}
                                developer documentation.{' '}
                            </Link>
                        </Typography>
                        <span>
                            Description in the installed app status page
                            <RichTextInput
                                source="marketplaceSettings.tenantShowContent"
                                label="Installed App status page description"
                                toolbar={toolbarOptions()}
                                fullWidth={true}
                                placeholder="Add a description to the installed app status page"
                            />
                        </span>
                        <Divider sx={marketplaceStyle.divider} />
                        <CustomLabel title="SETTINGS CONTENT" />
                        <Typography sx={marketplaceStyle.subtitle}>
                            {' '}
                            Add help text and links to the integration settings
                            page. More information can be found in our{' '}
                            <Link
                                sx={marketplaceStyle.link}
                                href="https://docs.pandium.com/platform-guides/administrator-guides/marketplace-settings"
                                target="_blank"
                            >
                                {' '}
                                developer documentation.{' '}
                            </Link>
                        </Typography>
                        <RichTextInput
                            source="marketplaceSettings.connectionSettingsContent"
                            label="Connection Settings Page Description"
                            toolbar={toolbarOptions()}
                            fullWidth={true}
                            placeholder="Add a description to the connection settings page"
                        />
                        <TextInput
                            variant="filled"
                            source="marketplaceSettings.connectionTooltip"
                            label="'Connect your accounts' Tooltip (optional)"
                            helperText="Additional instructions to help users with authentication"
                            fullWidth={true}
                        />
                        <RichTextInput
                            source="marketplaceSettings.syncScheduleDescription"
                            label="Sync Schedule Description"
                            toolbar={toolbarOptions()}
                            fullWidth={true}
                            placeholder="Add a description to the sync schedule page"
                        />
                        <RichTextInput
                            source="marketplaceSettings.connectYourAccountsDescription"
                            label="Connect Account Description"
                            toolbar={toolbarOptions()}
                            fullWidth={true}
                            placeholder="Add a description to the connection settings page"
                        />
                    </Fragment>
                )}
                {hasGallery && (
                    <>
                        <Divider />
                        <CustomLabel title="SEO" />
                        <TextInput
                            variant="filled"
                            source="marketplaceSettings.metaTitle"
                            label="Meta Title"
                            helperText="Will default to the integration name if left blank"
                            fullWidth={true}
                            options={{
                                InputLabelProps: {
                                    classes: { root: marketplaceStyle.label },
                                },
                            }}
                        />
                        <TextInput
                            variant="filled"
                            source="marketplaceSettings.metaDescription"
                            label="Meta Description"
                            helperText="Will default to the short description if left blank"
                            fullWidth={true}
                            options={{
                                InputLabelProps: {
                                    classes: { root: marketplaceStyle.label },
                                },
                            }}
                        />
                    </>
                )}
            </Grid>
        </Grid>
    )
}
const mapStateToProps = (state) => {
    return {
        isParentOrg: state.org.isParentOrg,
        orgName: state.org.name,
        hasGallery: state.org.hasGallery,
    }
}
export default connect(mapStateToProps)(MarketplaceTab)
