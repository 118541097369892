import React from 'react'
import { get } from 'lodash-es'
import { BooleanInput } from 'react-admin'
import { Box } from '@mui/material'

/**
 *
 * @param paused
 * @param source
 * @param label
 * @param helpText
 * @returns {*}
 */
export default ({ values, label, helpText, source }) => {
    return (
        <Box
            sx={{
                display: 'flex',
            }}
        >
            <BooleanInput
                initialValue={get(values, source)}
                source={source}
                label={label}
                sx={{
                    margin: '20px 0',
                    width: 'auto',
                    '& label': {
                        flexDirection: 'row-reverse',
                        margin: '0',
                        '& span': {
                            fontFamily: 'RobotoCondensedBold',
                            fontSize: '16px',
                            lineHeight: '24px',
                        },
                    },
                }}
            />
            {get(values, source) && (
                <Box
                    component="span"
                    sx={{
                        fontFamily: 'RobotoCondensedItalic',
                        fontSize: '13px',
                        lineHeight: '16px',
                        alignSelf: 'center',
                        marginBottom: '24px',
                    }}
                >
                    {helpText}
                </Box>
            )}
        </Box>
    )
}
