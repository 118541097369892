import { IconButton, DialogContentText } from '@mui/material'
import { truncate } from 'lodash-es'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileCopy from '@mui/icons-material/FileCopy'
import { withJsonFormsControlProps } from '@jsonforms/react'

const TextWithCopyControl = ({ label, path, data }) => {
    return (
        <div>
            <DialogContentText
                sx={{
                    fontSize: '0.82rem',
                }}
            >
                {label}
            </DialogContentText>
            <CopyToClipboard text={data}>
                <IconButton>
                    <FileCopy />
                </IconButton>
            </CopyToClipboard>
            <DialogContentText
                sx={{
                    display: 'inline',
                    marginRight: '8px',
                }}
            >
                {truncate(data, {
                    length: 50,
                })}
            </DialogContentText>
        </div>
    )
}

export default withJsonFormsControlProps(TextWithCopyControl)
