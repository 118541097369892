import React from 'react'
import { TopToolbar } from 'react-admin'
import { Divider } from '@mui/material'

/**
 * Modular TopToolbar, mostly used in ListViews
 * @param pageTitle
 * @param children
 * @returns {*}
 */
export default ({ pageTitle, children }) => (
    <>
        <TopToolbar>
            <h1>{pageTitle}</h1>
            {children}
        </TopToolbar>
        <Divider className="pageHead" />
    </>
)
