import React, { useEffect, useState } from 'react'
import { Button, FormHelperText } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { get } from 'lodash-es'
import { connect } from 'react-redux'
import { uploadImage } from '../common'
import { useFormContext } from 'react-hook-form'

const logoUploaderStyles = makeStyles({
    formContainer: {
        margin: '15px 5px 30px 5px',
    },
    formStyle: {
        width: '500px',
    },
    innerFormStyle: {
        minHeight: '66px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '7px 20px',
        boxShadow: '0 0 0 1px #b8bbc185, 0 0 0 2px #dbdee5c7',
        borderRadius: '3px',
    },
    filledButton: {
        color: 'white',
        width: '135px',
        height: '40px',
        backgroundColor: '#626FFC',
        fontSize: '15px',
        fontFamily: 'RobotoCondensedBold',
        borderRadius: '0',
        letterSpacing: '2px',
        margin: '0 5px 0 5px',
        '&:disabled': {
            background: 'transparent',
        },
        boxShadow: 'none',
    },
    labelStyle: {
        fontSize: '15px',
        fontWeight: 200,
        paddingRight: '20px',
        fontFamily: 'RobotoCondensedRegular',
        color: '#999BA2',
    },
    formDescriptionStyle: {
        fontSize: '15px',
        fontFamily: 'RobotoCondensedRegular',
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        display: 'none',
    },
    textDiv: {
        display: 'inline-flex',
    },
    removeButton: {
        fontSize: '13px',
        fontFamily: 'RobotoCondensedBold',
        letterSpacing: '1.73px',
        color: '#626FFC',
        marginLeft: '10px',
    },
    imageStyle: {
        maxHeight: '45px',
    },
    errorStyle: {
        fontSize: '11px',
        color: 'red',
    },
})

const ACCEPTEDFILEEXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'svg']

export const getBase64DataFromFile = (file, fileExtension) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        // Corner case for svg
        fileExtension = fileExtension.replace('svg', 'svg+xml')
        reader.readAsDataURL(file)
        // Split the value because first half is mimetype info, second half is actual data
        reader.onload = () =>
            resolve(
                `data:image/${fileExtension};base64, ${
                    reader.result.split(',', 2)[1]
                }`
            )
        reader.onerror = (error) => reject(error)
    })
}

const LogoUploader = ({
    label,
    formDescription,
    source,
    helperText,
    resource,
    id,
}) => {
    const formMethods = useFormContext()

    const classes = logoUploaderStyles()
    const [fileData, setFileData] = useState(
        get(formMethods.getValues(), source)
    )
    const [fileName, setFileName] = useState('')
    const [uploadPresent, setupLoadPresent] = useState(true)
    const [uploadSucess, setUploadSucess] = useState(true)
    //on page load check if current logo is a manually uploaded one
    useEffect(() => {
        if (fileData) setupLoadPresent(true)
        else setupLoadPresent(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onFileChange = async (e) => {
        const targetFile = e.target.files[0]
        const fileExtension = targetFile.name.split('.').pop()
        let val = ''

        // 5MB
        const maxFileSize = 5 * 1024 * 1024
        if (e.target.files[0].size > maxFileSize) {
            window.alert('Please upload an image smaller than 5MB.')
        } else if (!ACCEPTEDFILEEXTENSIONS.includes(fileExtension)) {
            window.alert(
                'Accepted file extensions are jpg, jpeg, png, svg and gif'
            )
        } else {
            if (targetFile) {
                val = await getBase64DataFromFile(targetFile, fileExtension)
            }
            setupLoadPresent(true)
            setFileData(val)
            setFileName(get(targetFile, 'name', ''))
            console.debug('Filename: ', fileName)
            uploadImage(resource, targetFile, id, 'logos').then((resp) => {
                if (resp !== 'error') {
                    formMethods.setValue(source, resp, { shouldDirty: true })
                    setUploadSucess(true)
                } else setUploadSucess(false)
            })
        }
    }

    return (
        <div className={classes.formContainer}>
            <div className={classes.formStyle}>
                <div className={classes.innerFormStyle}>
                    <div className={classes.textDiv}>
                        <div className={classes.labelStyle}>
                            <p>{label}</p>
                        </div>
                        <div className={classes.formDescriptionStyle}>
                            {uploadPresent && uploadSucess ? (
                                <img
                                    src={fileData}
                                    className={classes.imageStyle}
                                    alt={source}
                                    onError={(ev) =>
                                        (ev.target.src =
                                            'https://upload.wikimedia.org/wikipedia/commons/thumb/8/81/Blank-document-broken.svg/48px-Blank-document-broken.svg.png?20191204040146')
                                    }
                                />
                            ) : (
                                <div>
                                    <p>{formDescription}</p>
                                    {!uploadSucess && (
                                        <p className={classes.errorStyle}>
                                            Error uploading image !
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <>
                        <input
                            accept="image/*"
                            className={classes.input}
                            onChange={onFileChange}
                            onClick={(e) => {
                                // Clear value so we can upload again
                                e.target.value = ''
                            }}
                            id={source}
                            type="file"
                        />
                        {fileData ? (
                            <Button
                                children={
                                    <span className={classes.removeButton}>
                                        REMOVE
                                    </span>
                                }
                                variant="text"
                                component={'span'}
                                onClick={(e) => {
                                    // Setting this value to null, rather than an empty string, preserves the key
                                    // in the database.
                                    // This prevents a bug where the last key in an object could not be deleted.
                                    formMethods.setValue(source, null, {
                                        shouldDirty: true,
                                    })
                                    e.target.value = ''
                                    setFileName('')
                                    setFileData('')
                                    setupLoadPresent(false)
                                }}
                            />
                        ) : (
                            <label htmlFor={source}>
                                <Button
                                    children="Upload File"
                                    variant="contained"
                                    component={'span'}
                                    className={classes.filledButton}
                                />
                            </label>
                        )}
                    </>
                </div>
            </div>
            <FormHelperText> {helperText} </FormHelperText>
        </div>
    )
}

export default LogoUploader

const orgMapState = (state) => ({ orgId: state.org.orgId })

export const UploadLogoToOrg = connect(orgMapState)(({ orgId, ...props }) => (
    <LogoUploader resource="organizations" id={orgId} {...props} />
))
