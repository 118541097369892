import React, { useState } from 'react'
import { PageTitle } from '../../../common'
import MarketplaceTab from './MarketplaceEditForm'
import EmpSettingsForm from './EmpSettingsForm'
import IntegrationEditFormWrapper from './IntegrationEditFormWrapper'
import TabsBar from '../../../TabsBar'
import { connect } from 'react-redux'

const empConnectors = {
    wix: 'Wix',
    bigcommerce: 'BigCommerce',
}

const IntegrationEditMarketplace = ({
    orgName,
    theme,
    hasGallery,
    ...props
}) => {
    const { integration } = props
    // Loop through the connectors attached to this integration to
    // see if any are included in the above list of connectors that
    // connect to an External Marketplace (empConnectors). If the integration
    // has a connector on that list, we grab the display name from the object
    // and use it to display a dedicated tab for that external marketplace.
    const empConnector = integration.connectors
        ?.map((connector) => connector.name)
        .filter((name) => Object.keys(empConnectors).includes(name))[0]
    const empName = empConnector ? empConnectors[empConnector] : null
    const [tabValue, setTabValue] = useState(0)
    const handleChange = (event, newValue) => {
        setTabValue(newValue)
    }

    return (
        <div>
            <PageTitle title={`Marketplace Settings: ${integration.name}`} />
            {empName && (
                <TabsBar
                    tabs={[orgName, empName]}
                    handleChange={handleChange}
                    tabValue={tabValue}
                />
            )}

            {tabValue === 0 && (
                <IntegrationEditFormWrapper {...props}>
                    <MarketplaceTab
                        isExternal={integration.type === 'External'}
                        marketplaceTheme={theme}
                        mode="EDIT"
                    />
                </IntegrationEditFormWrapper>
            )}
            {tabValue === 1 && (
                <IntegrationEditFormWrapper {...props}>
                    <EmpSettingsForm empName={empName} />
                </IntegrationEditFormWrapper>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    orgName: state.org.name,
    theme: state.org.theme,
    hasGallery: state.org.hasGallery,
})
export default connect(mapStateToProps)(IntegrationEditMarketplace)
