import React, { useState } from 'react'
import { connect } from 'react-redux'
import { API_URI } from '../../appConfigs'
import Iframe from 'react-iframe'
import Spinner from 'react-spinkit'

const MetricsPage = () => {
    const [loading, setLoading] = useState(true)
    return (
        <div style={{ height: '90vh', minHeight: '70vh' }}>
            {loading ? (
                <div
                    style={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        marginBottom: '85px',
                    }}
                >
                    <Spinner
                        className="loading text-center"
                        name="ball-spin-fade-loader"
                        role="loader"
                        color="white"
                        fadeIn="none"
                        style={{ left: '40%' }}
                    />
                </div>
            ) : null}
            <Iframe
                url={`${API_URI.replace('api', 'analytics').replace(
                    '/v1',
                    ''
                )}/sso`}
                width="100%"
                height="100%"
                id="umami-iframe"
                title="umami-iframe"
                ariaHidden={loading}
                display="initial"
                position="relative"
                onLoad={() => setLoading(false)}
                frameBorder={0}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    org: state.org,
})

export default connect(mapStateToProps)(MetricsPage)
