import React from 'react'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { FileInput, FileField } from 'react-admin'

const JsonFileInput = ({ handleChange, path, label }) => {
    const getBase64DataFromFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            // Split the value because first half if mimetype info, second half is actual data
            reader.onload = () => resolve(reader.result.split(',', 2)[1])
            reader.onerror = (error) => reject(error)
        })
    }

    const onFileChange = async (e) => {
        const targetFile = e[0]
        let val = ''

        if (targetFile) {
            val = await getBase64DataFromFile(targetFile)
        }

        handleChange(path, val)
    }

    return (
        <FileInput
            label={label}
            name={'File Input'}
            options={{ onDrop: onFileChange, multiple: false }}
            sx={{
                '& .RaFileInput-dropZone': {
                    background: '#f5f2f0',
                    color: 'black',
                },
            }}
        >
            <FileField source="src" title="title" />
        </FileInput>
    )
}

export default withJsonFormsControlProps(JsonFileInput)
