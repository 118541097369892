import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { TextInput } from 'react-admin'
import { CustomLabel } from '../../../CustomLabel'
import { get } from 'lodash-es'
import { FormProvider } from 'react-hook-form'

export const validateCreateForm = (values) => {
    const errors = {}
    if (!get(values, 'name')) {
        errors.name = 'Required'
    }
    if (!get(values, 'primaryUser.email')) {
        errors.primaryUser = { email: 'Required' }
    }
    return { values: values, errors: errors }
}

const useCreateTabStyles = makeStyles({
    inputField: {
        width: '328px',
        margin: '0',
        display: 'flex',
    },
})
export default (methods) => {
    const classes = useCreateTabStyles()
    return (
        <FormProvider {...methods}>
            <CustomLabel
                title="Organization"
                subtitle="Enter your organization details below"
            />
            <TextInput
                source="name"
                label="Organization"
                required={true}
                InputProps={{ disableUnderline: true }}
                className={classes.inputField}
            />
            <CustomLabel
                title="Organization Admin"
                subtitle="Enter your organization admin details below"
            />
            <TextInput
                source="primaryUser.firstName"
                label="Primary Contact First Name"
                required={true}
                InputProps={{ disableUnderline: true }}
                className={classes.inputField}
            />
            <TextInput
                source="primaryUser.lastName"
                label="Primary Contact Last Name"
                required={true}
                InputProps={{ disableUnderline: true }}
                className={classes.inputField}
            />
            <TextInput
                source="primaryUser.email"
                label="Primary Contact Email"
                required={true}
                InputProps={{ disableUnderline: true }}
                className={classes.inputField}
            />
        </FormProvider>
    )
}
