import React, { useState } from 'react'
import { useListFilterContext } from 'react-admin'
import { Grid, Button, TextField, Box } from '@mui/material'

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export default () => {
    const { filterValues, setFilters } = useListFilterContext()
    const [nameFilter, setNameFilter] = useState(filterValues['name'] || '')

    return (
        <Grid
            container
            gap={2}
            my={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >
            <Grid item>
                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        gap: 2,
                    }}
                    onSubmit={(e) => {
                        e.preventDefault()
                        setFilters({
                            ...filterValues,
                            name__contains: nameFilter,
                        })
                    }}
                >
                    <TextField
                        variant="outlined"
                        label=""
                        sx={{
                            '& > div.MuiInputBase-root': {
                                height: '42px',
                                borderRadius: '0',
                            },
                        }}
                        placeholder={'Partner Name'}
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                        helperText="Search by Organization"
                    />
                    <Button
                        type="submit"
                        className="filledButton"
                        sx={{ mt: 1 }}
                    >
                        {' '}
                        search{' '}
                    </Button>
                </Box>
            </Grid>
            <Grid item>
                <Button
                    onClick={() => {
                        setFilters({})
                        setNameFilter('')
                    }}
                    sx={{
                        fontFamily: 'RobotoCondensedBold',
                        letterSpacing: '1.5px',
                        color: '#626FFC',
                        mb: 2.5,
                    }}
                >
                    Clear
                </Button>
            </Grid>
        </Grid>
    )
}
