import React, { useEffect, useState } from 'react'
import { Typography, Select, MenuItem, ListItemText } from '@mui/material'
import { useDataProvider, useNotify, useUpdate } from 'ra-core'
import { API_URI } from '../appConfigs'

export default ({ current, username, userId }) => {
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const [accounts, setAccounts] = useState([])
    const [update] = useUpdate()

    const [selected, setSelected] = useState(current)

    const changeAccount = (newAccount) => {
        // for just a second before we redirect, the user would
        // like to see their selected account
        setSelected(newAccount)
        update(
            'users',
            {
                id: userId,
                data: {
                    username: username,
                    enabled: true,
                    attributes: {
                        account: newAccount,
                    },
                },
            },
            {
                onSuccess: () => {
                    // we can't stay on an individual resoure page because
                    // the new account will lose access, but we can stay in
                    // the same general part of the app.
                    const basepath = window.location.pathname.split('/')[1]
                    window.location.replace(`/${basepath}`)
                },
                onError: (error) => {
                    setSelected(undefined)
                    console.error(error)
                },
            }
        )
    }

    useEffect(() => {
        const fetchAccounts = async () => {
            let organizations = []
            let page = 1
            let perPage = 25
            try {
                while (true) {
                    const { data } = await dataProvider.getList(
                        'organizations',
                        {
                            pagination: { page: page, perPage: perPage },
                            filter: { org_type: 'ACCOUNT' },
                            sort: { field: 'name', order: 'ASC' },
                        }
                    )
                    organizations.push(...data)
                    if (data.length < perPage) {
                        break
                    }
                    page++
                }
                setAccounts(organizations)
            } catch (error) {
                notify('Error fetching organizations', 'error')
            }
        }
        fetchAccounts()
    }, [dataProvider, notify])

    const backgroundColor = API_URI.includes('pandium.io')
        ? 'linear-gradient(47.86108581789776deg, rgba(255, 255, 0, 1) 0%, rgba(255, 105, 180, 1) 100%)'
        : 'linear-gradient(47.86108581789776deg, rgba(157, 116, 249, 1) 0%, rgba(121, 204, 255, 1) 100%)'

    return (
        <div
            style={{
                opacity: 1,
                background: backgroundColor,
                width: '100%',
                height: '56px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '9999',
                position: 'fixed',
            }}
        >
            {API_URI.includes('pandium.io') && (
                <Typography
                    sx={{
                        color: 'white',
                        fontWeight: 'bold',
                        paddingRight: '24px',
                    }}
                >
                    PRODUCTION
                </Typography>
            )}
            <Typography
                sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    paddingRight: '24px',
                }}
            >
                VIEWING AS
            </Typography>
            <Select
                onChange={(e) => {
                    changeAccount(e.target.value)
                }}
                value={selected}
                name="namespace"
                variant="outlined"
                sx={{
                    backgroundColor: 'rgba(241,244,251, 1)',
                    height: '40px',
                    width: '240px',
                }}
            >
                {accounts?.map((account) => (
                    <MenuItem
                        key={account.id}
                        value={`${account.id}--${account.name}`}
                    >
                        <ListItemText>{account.name}</ListItemText>
                    </MenuItem>
                ))}
            </Select>
        </div>
    )
}
