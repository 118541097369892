import { Tab, Tabs } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

const useUserListTabBarStyles = makeStyles({
    tab: {
        borderBottom: 'solid 2px #DBDEE5',
        fontFamily: 'RobotoCondensedBold',
        fontSize: '15px',
        letterSpacing: '2px',
        color: '#999BA2',
    },
    tabsIndicator: {
        backgroundColor: '#635ee7',
    },
    tabsRoot: {
        margin: '24px 0',
    },
    tabRoot: {
        '&:disabled': {
            color: '#635ee7',
            opacity: '1',
        },
        color: '#635ee7',
        opacity: '1',
    },
})

export default ({ handleChange, tabValue }) => {
    const classes = useUserListTabBarStyles()

    return (
        <Tabs
            classes={{
                indicator: classes.tabsIndicator,
                root: classes.tabsRoot,
            }}
            value={tabValue}
            onChange={handleChange}
        >
            <Tab
                className={classes.tab}
                classes={{ selected: classes.tabRoot }}
                label="Company"
                disabled
            />
            <Tab
                className={classes.tab}
                classes={{ selected: classes.tabRoot }}
                label="Users"
            />
            <Tab
                className={classes.tab}
                classes={{ selected: classes.tabRoot }}
                label="Billing"
                disabled
            />
            <Tab
                className={classes.tab}
                classes={{ selected: classes.tabRoot }}
                label="Logs"
                disabled
            />
        </Tabs>
    )
}
