import React from 'react'
import { FormControl, Select, MenuItem, InputLabel } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Controller } from 'react-hook-form'

const fontList = [
    'Arimo',
    'Arvo',
    'Babylonica',
    'BioRhyme',
    'Cagliostro',
    'Comic Neue',
    'Cormorant',
    'Dosis',
    'Epilogue',
    'Gelasio',
    'Inter',
    'Lato',
    'Nunito',
    'Open Sans',
    'Proxima Nova',
    'Quicksand',
    'Raleway',
    'Righteous',
    'Roboto',
    'Send Flowers',
    'Spectral',
    'Vollkorn SC',
]

const useFontPickerStyles = makeStyles({
    // This is how to pass props into styling for dynamic styles :)
    select: (props) => {
        return {
            fontFamily: props.fontFamily,
        }
    },
    root: {
        margin: '15px 0',
    },
    labelText: (props) => ({
        fontFamily: props.fontFamily,
        color: 'black',
        fontSize: '18px',
    }),
})

const RenderFontPicker = ({ input, label }) => {
    const classes = useFontPickerStyles({ fontFamily: input.value })
    return (
        <FormControl fullWidth margin="normal" size="medium" variant="filled">
            <InputLabel id="primary-font-label">
                {label ?? 'Primary Font'}
            </InputLabel>
            <Select
                labelId="primary-font-label"
                classes={{ select: classes.select, root: classes.selectRoot }}
                fullWidth
                label={label ?? 'Primary Font'}
                size="medium"
                variant="filled"
                value={input.value}
                onChange={(event, value) => {
                    input.onChange(value.props.value)
                }}
            >
                {fontList.map((font) => (
                    <MenuItem
                        key={font}
                        value={font}
                        style={{ fontFamily: font, fontSize: '20px' }}
                    >
                        {font}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default ({ source, label }) => (
    <Controller
        name={source}
        render={({ field }) => <RenderFontPicker input={field} label={label} />}
        label={label}
    />
)
