import React, { useEffect, useMemo, useState } from 'react'
import { withJsonFormsControlProps, useJsonForms } from '@jsonforms/react'
import {
    Grid,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Box,
} from '@mui/material'
import { useDynamicConfigs } from './JsonFormsWrapper'
import { rankWith, uiTypeIs } from '@jsonforms/core'

// Tells Jsonforms which control/component should be used.
export const DependentSelectControlTester = rankWith(
    Number.MAX_VALUE,
    // TODO As we expand support for configurable uischemas
    // Create a DependentSelectControl wrapper to wrap all dependent components
    uiTypeIs('DependentSelectControl')
)

const DependentSelectControl = ({
    path,
    schema,
    uischema,
    data,
    handleChange,
    required,
}) => {
    const dynamicConfigs = useDynamicConfigs()
    const formContext = useJsonForms()
    const [selectError, setSelectError] = useState('')
    const parentScope = uischema?.options?.parentScope || ''
    const dependentMappingScope = uischema?.options?.dependentMappingScope || ''

    useEffect(() => {
        let errorString = ''

        if (!uischema?.options?.parentScope) {
            errorString += 'parentScope is missing from uischema options\n'
        }
        if (!uischema?.options?.dependentMappingScope) {
            errorString +=
                'dependentMappingScope is missing from uischema options\n'
        }

        setSelectError(errorString)
    }, [uischema])

    // https://jsonforms.discourse.group/t/access-other-fields-value-data-in-custom-renderer/224
    // This grabs the value of the parent from the form data using the end of the parent scope path
    // i.e. parentScope = '#/properties/first_name', will get data from the form data.first_name
    const parentValue = formContext.core.data?.[parentScope.split('/').at(-1)]
    const staticConfigs = formContext.core.schema.properties

    const selectList = useMemo(() => {
        const isDynamic = dependentMappingScope.startsWith('#/definitions/')

        const pathArray = dependentMappingScope
            .replace(isDynamic ? '#/definitions/' : '#/properties/', '')
            .split('/')

        const targetValue = pathArray.reduce(
            (obj, key) => obj?.[key],
            isDynamic ? dynamicConfigs : staticConfigs
        )

        let selectContent = targetValue?.[parentValue]

        if (!Array.isArray(selectContent) || selectContent?.length < 1) {
            return [{ title: 'Placeholder', const: 'Placeholder' }]
        }

        switch (typeof selectContent[0]) {
            case 'object':
                break
            case 'string':
                // Convert enum to match oneOf format to standardize the component
                selectContent = targetValue?.[parentValue]?.map((value) => ({
                    title: value,
                    const: value,
                }))
                break
            default:
                selectContent = [{ title: 'Placeholder', const: 'Placeholder' }]
                break
        }

        return selectContent
    }, [dependentMappingScope, dynamicConfigs, staticConfigs, parentValue])

    // Clear the component data when the dynamic key changes
    useEffect(() => {
        if (!selectList.find((o) => o?.const === data)) {
            handleChange(path, '')
        }
    }, [parentValue, selectList, data, handleChange, path])

    return (
        <Grid container direction="column">
            {selectError ? (
                <Box>
                    <Box>{uischema.label}</Box>
                    <Box sx={{ marginLeft: '20px', whiteSpace: 'pre-line' }}>
                        {selectError}
                    </Box>
                </Box>
            ) : (
                <FormControl
                    variant="standard"
                    margin="normal"
                    required={required}
                >
                    <InputLabel id="list-label">{`${uischema.label}`}</InputLabel>
                    <Select
                        variant="standard"
                        labelId="list-label"
                        value={data}
                        onChange={(e) => {
                            handleChange(path, e.target.value)
                        }}
                    >
                        {selectList.map(
                            (listItem) =>
                                listItem.title &&
                                listItem.const && (
                                    <MenuItem
                                        key={listItem.title}
                                        value={listItem.const}
                                    >
                                        {listItem.title}
                                    </MenuItem>
                                )
                        )}
                    </Select>
                </FormControl>
            )}
        </Grid>
    )
}

export default withJsonFormsControlProps(DependentSelectControl)
