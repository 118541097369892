import { format } from 'date-fns'

export const isLowerCase = (str, allValues) =>
    str && str.toLowerCase() !== str ? 'Must be all lowercase' : undefined

//Input must consist of alphnumeric or '-' chars
export const alphaNumericDash = (value, allValues) =>
    value && !value.match(/^[a-zA-Z0-9]([-a-zA-Z0-9]*[a-zA-Z0-9])?$/)
        ? 'Must be alphanumeric separated by "-"'
        : undefined

//Input must consist of alphnumeric, '-', '+', '.' or '@'
export const alphaNumericDashUnderscorePeriod = (value, allValues) =>
    value && !value.match(/^[a-zA-Z0-9]([-a-zA-Z0-9._]*[a-zA-Z0-9])?$/)
        ? 'First and last characters must be lowercase alphanumeric. Other characters may include: "-", "_", or "."'
        : undefined

export const dateFormatValidation = (value, allValues) => {
    if (value !== undefined) {
        const date = new Date('12/29/1994')
        try {
            format(date, value)
        } catch (err) {
            return 'Input is Invalid'
        }
    }
}

export const urlValidation = (value, allValues) =>
    value && !/^(ftp|http|https):\/\/[^ "]+$/.test(value)
        ? 'please enter a valid URL'
        : undefined

export const required = (value, allValues) => (value ? undefined : 'Required')

export const validateInputChoice = (value, allValues) =>
    value !== -1 ? undefined : 'Select One'

export const validateEmail = (email, allValues) => {
    if (
        !email ||
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(
            email
        )
    ) {
        return 'Invalid email address'
    }
}
