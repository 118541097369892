import React, { useState } from 'react'
import {
    TopToolbar,
    useGetList,
    useListContext,
    useRedirect,
} from 'react-admin'
import {
    Button,
    Card,
    Typography,
    Divider,
    Grid,
    Box,
    CardMedia,
} from '@mui/material'
import IntegrationCreateButton from './Create/IntegrationCreateButton'
import { connect } from 'react-redux'
import { get, isEmpty } from 'lodash-es'
import { RunPhaseField } from '../../common'
import humanizeDuration from 'humanize-duration'
import TabsBar from '../../TabsBar'
import IntegrationCornerMenu from './IntegrationCornerMenu'
import { format } from 'date-fns'
import { EmptyState } from '../../react_admin/EmptyState'
import BaseList from '../List/BaseList'

const GridActions = ({ location, parentOrgName, isParentOrg }) => {
    const startCreateView = location === '/integrations/create'
    const externalOnly = !isParentOrg && parentOrgName === 'vena'
    return (
        <>
            <TopToolbar>
                <h1>Integrations</h1>
                <IntegrationCreateButton
                    startCreateView={startCreateView}
                    externalOnly={externalOnly}
                />
            </TopToolbar>
            <Divider className="pageHead" />
        </>
    )
}

const displayIntegrationNameStyles = {
    nameWrapper: {
        margin: '10px 0 0 0',
        flexGrow: '3',
        height: '60px',
        '& > div': {
            display: 'flex',
            justifyContent: 'flex-start',
            '& :nth-child(1)': {
                paddingRight: '12px',
                width: '80px',
            },
            '& :nth-child(2)': {
                width: '100px',
            },
        },
    },
    imageStyle: {
        width: '60px',
        height: '60px',
        margin: '10px',
        objectFit: 'contain',
        flexGrow: '1',
        borderRadius: '10px',
        '&:hover': {
            background: '#f6f6f6',
            cursor: 'pointer',
        },
    },
    integrationName: {
        fontFamily: 'RobotoCondensedBold',
        fontSize: '20px',
        color: 'rgba(0,0,0,0.87)',
        letterSpacing: '0.25px',
        lineHeight: '24px',
        textTransform: 'none',
    },
    buttonStyle: {
        padding: '0',
    },
    content: {
        fontFamily: 'RobotoCondensedRegular',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        textTransform: 'uppercase',
    },
}

const DisplayIntegrationName = ({ record, showIntegration }) => {
    const logo = get(record, 'marketplaceSettings.secondaryLogo')
    const src = logo?.src ? logo.src : logo

    return (
        <>
            <CardMedia
                component={'img'}
                onClick={showIntegration}
                src={src}
                alt={''}
                sx={displayIntegrationNameStyles.imageStyle}
                onError={(ev) => {
                    ev.target.style = 'display:none'
                }}
                onLoad={(ev) => {
                    ev.target.style = 'display:inline'
                }}
            />
            <Box sx={displayIntegrationNameStyles.nameWrapper}>
                <Button
                    sx={displayIntegrationNameStyles.buttonStyle}
                    onClick={showIntegration}
                >
                    <Typography
                        sx={displayIntegrationNameStyles.integrationName}
                        gutterBottom
                        component="p"
                    >
                        {record.longName}
                    </Typography>
                </Button>
                <div>
                    <Typography
                        sx={displayIntegrationNameStyles.content}
                        color={'textSecondary'}
                        component="span"
                        title={record.name}
                    >
                        ID: {record.id}
                    </Typography>
                    <Typography
                        sx={displayIntegrationNameStyles.content}
                        color={'textSecondary'}
                        component="span"
                    >
                        TYPE: {record.type}
                    </Typography>
                    <div></div>
                </div>
            </Box>
        </>
    )
}

const secondRowStyles = (PandiumColors) => ({
    secondaryTitle: {
        fontFamily: 'RobotoCondensedRegular',
        color: '#999BA2',
        lineHeight: '15px',
        fontSize: '12px',
        letterSpacing: '2px',
        textAlign: 'Center',
    },
    dataText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'RobotoCondensedRegular',
        fontSize: '16px',
        color: 'rgba(0,0,0,0.6)',
        letterSpacing: '0.5px',
        lineHeight: '28px',
        textTransform: 'None',
        textAlign: 'Center',
        '& span': {
            // pad RunPhaseField
            paddingLeft: '8px',
        },
    },
    lastRunTimeText: {
        width: '100px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    oval: {
        display: 'flex',
        justifyContent: 'space-around',
        backgroundColor: PandiumColors.purple,
        width: '138px',
        height: '20px',
        borderRadius: '12px',
        fontFamily: 'RobotoCondensedBold',
        color: 'white',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.1px',
    },
    blank: {
        backgroundColor: 'white',
        width: '138px',
        height: '20px',
        color: 'white',
    },
})

const PublishedDetails = ({ record }) => {
    const timeDifference = (lastRunTime) => {
        const now = new Date()
        const difference = now - lastRunTime
        return (
            humanizeDuration(difference, { round: true, largest: 1 }) + ' ago '
        )
    }

    const lastRunTime = get(record, 'status.last_run_status.startTime')
        ? get(record, 'status.last_run_status.completionTime')
            ? timeDifference(
                  Date.parse(
                      get(record, 'status.last_run_status.completionTime')
                  )
              )
            : 'In Progress'
        : 'n/a'

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography
                    color={'textSecondary'}
                    component="p"
                    sx={(theme) =>
                        secondRowStyles(theme.PandiumColors).secondaryTitle
                    }
                >
                    {' '}
                    TENANTS{' '}
                </Typography>
                <Box
                    sx={(theme) =>
                        secondRowStyles(theme.PandiumColors).dataText
                    }
                >
                    {get(record, 'status.tenant_count', 0)}
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography
                    color={'textSecondary'}
                    component="p"
                    sx={(theme) =>
                        secondRowStyles(theme.PandiumColors).secondaryTitle
                    }
                >
                    {' '}
                    ACTIVITY{' '}
                </Typography>
                <Box
                    sx={(theme) =>
                        secondRowStyles(theme.PandiumColors).dataText
                    }
                >
                    {' '}
                    {get(record, 'status.run_count', 0)}{' '}
                </Box>
            </Box>
            <div>
                <Typography
                    color={'textSecondary'}
                    component="p"
                    sx={(theme) =>
                        secondRowStyles(theme.PandiumColors).secondaryTitle
                    }
                >
                    {' '}
                    LAST ACTIVITY{' '}
                </Typography>
                <Box
                    sx={(theme) =>
                        secondRowStyles(theme.PandiumColors).dataText
                    }
                >
                    <Typography
                        color={'textSecondary'}
                        component="p"
                        sx={(theme) =>
                            secondRowStyles(theme.PandiumColors).lastRunTimeText
                        }
                    >
                        {' '}
                        {lastRunTime
                            .replace('minutes', 'min')
                            .replace('minute', 'min')
                            .replace('hour', 'hr')
                            .replace('seconds', 'sec')
                            .replace('second', 'sec')}{' '}
                    </Typography>
                    {lastRunTime !== 'n/a' && (
                        <RunPhaseField
                            record={record}
                            source="status.last_run_status.phase"
                        />
                    )}
                </Box>
            </div>
        </>
    )
}

const PendingSecondRow = ({ record, isParentOrg }) => {
    const reviewRequired = isParentOrg
        ? record.approvalStatus === 'Submitted'
        : record.approvalStatus === 'Reviewed'
    const submittedTime = record?.submittedDate
        ? format(new Date(record.submittedDate + 'Z'), 'M/dd/yyyy, h:mm:ss aa')
        : 'n/a'

    return (
        <>
            <div>
                <Typography
                    color={'textSecondary'}
                    component="p"
                    sx={(theme) =>
                        secondRowStyles(theme.PandiumColors).secondaryTitle
                    }
                >
                    {' '}
                    SUBMITTED{' '}
                </Typography>
                <Box
                    sx={(theme) =>
                        secondRowStyles(theme.PandiumColors).dataText
                    }
                >
                    {' '}
                    {submittedTime}{' '}
                </Box>
            </div>
            <Box
                sx={(theme) =>
                    reviewRequired
                        ? secondRowStyles(theme.PandiumColors).oval
                        : secondRowStyles(theme.PandiumColors).blank
                }
            >
                REQUIRES REVIEW
            </Box>
        </>
    )
}

const flagStyles = (PandiumColors) => ({
    flagCorner: {
        top: '-50px',
        right: '-244px',
        width: '178px',
        position: 'relative',
        minWidth: '40px',
        transform: 'rotate(35deg)',
        textAlign: 'center',
        paddingTop: '40px',
        backgroundColor: PandiumColors.purple,
        marginBottom: '-87px',
        '& p': {
            fontFamily: 'RobotoCondensedBold',
            fontSize: '12px',
            paddingBottom: '5px',
            paddingTop: '26px',
            color: 'white',
            width: '75%',
            margin: 'auto',
        },
    },
})

const Flag = ({ name }) => {
    return (
        <Box sx={(theme) => flagStyles(theme.PandiumColors).flagCorner}>
            <p>{name}</p>
        </Box>
    )
}

const integrationCardStyles = {
    gridWrapper: {
        boxShadow: 'none',
    },
    cardContent: {
        width: 340,
        height: 198,
    },
    cardRoot: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff',
        border: '1px solid #D8D8D8',
        boxShadow: 'none',
        borderRadius: '4px',
        padding: '1px',
        textAlign: 'left',
    },
    flexContainer: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
    },
    row1: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    row2: {
        display: 'flex',
        justifyContent: 'space-around',
        margin: '4px 8px 0 8px',
        '& :nth-child(3)': {
            width: '120px',
        },
    },
    row3: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0px 8px 2px 8px',
    },
}
export const IntegrationCard = ({
    record,
    marketplaceBaseUrl,
    isParentOrg,
}) => {
    const navigate = useRedirect()

    const inReview =
        record?.approvalStatus === 'Submitted' ||
        record?.approvalStatus === 'Reviewed'

    const isDraft = record?.approvalStatus === 'Draft'

    const showIntegration = () => {
        navigate(`/integrations/${record?.id}/edit`)
    }

    const cornerMenuItems = [
        {
            text: 'Live Link',
            button: true,
            component: 'a',
            target: '_blank',
            href: `${marketplaceBaseUrl}?integration_id=${record?.id}`,
            disabled: !marketplaceBaseUrl,
        },
    ]

    return record ? (
        <Card
            sx={{
                ...integrationCardStyles.cardContent,
                ...integrationCardStyles.cardRoot,
            }}
        >
            {record.partnerId && !inReview && !isDraft && isParentOrg && (
                <Flag name="PARTNER" />
            )}

            <Box sx={integrationCardStyles.row1}>
                <DisplayIntegrationName
                    record={record}
                    showIntegration={showIntegration}
                />
            </Box>

            {inReview ? (
                <Box sx={integrationCardStyles.row2}>
                    <PendingSecondRow
                        record={record}
                        isParentOrg={isParentOrg}
                    />
                </Box>
            ) : (
                <Box sx={integrationCardStyles.row2}>
                    <PublishedDetails record={record} />
                </Box>
            )}

            <Box sx={integrationCardStyles.row3}>
                <Button onClick={showIntegration}>Details</Button>
                <IntegrationCornerMenu items={cornerMenuItems} />
            </Box>
        </Card>
    ) : null
}

const IntegrationGrid = ({ ...rest }) => {
    const { data, isLoading } = useListContext()
    return isEmpty(data) && !isLoading ? (
        <EmptyState emptyStateText={'No integrations to show here'} />
    ) : (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
        >
            {data?.map((record) => {
                return (
                    <Grid item key={record.id}>
                        <IntegrationCard record={record} {...rest} />
                    </Grid>
                )
            })}
        </Grid>
    )
}

const integrationListStyles = {
    listRoot: {
        margin: '0',
    },
    listContent: {
        boxShadow: 'none',
    },
}

const IntegrationList = ({
    entitlements,
    isParentOrg,
    marketplaceBaseUrl,
    parentOrgName,
    isSuperUser,
    ...props
}) => {
    const [tabValue, setTabValue] = useState(0)

    const { loading: integrationsLoading } = useGetList('integrations', {
        sort: { field: 'long_name', order: 'DESC' },
        pagination: { page: 1, perPage: 500 },
    })

    let filter = {}
    switch (tabValue) {
        case 0:
            filter = {
                approval_status: 'APPROVED',
                marketplace_settings__published: true,
                type__in: JSON.stringify(['EXTERNAL', 'INTERNAL']),
                with_status: true,
            }
            break
        case 1:
            filter = {
                approval_status: 'APPROVED',
                marketplace_settings__published: false,
                type__in: JSON.stringify(['EXTERNAL', 'INTERNAL']),
                with_status: true,
            }
            break
        case 2:
            filter = {
                archived: true,
                with_status: true,
            }
            break
        case 3:
            filter = {
                approval_status__in: JSON.stringify(['SUBMITTED', 'REVIEWED']),
                type__in: JSON.stringify(['EXTERNAL', 'INTERNAL']),
                with_status: true,
            }
            break
        case 4:
            filter = {
                approval_status: 'DRAFT',
                type__in: JSON.stringify(['EXTERNAL', 'INTERNAL']),
                with_status: true,
            }
            break
        case 5:
            filter = {
                type: 'PANDIUM',
                with_status: true,
            }
            break
        default:
            break
    }

    // TODO: designs call for this tab label to preview the number of integrations
    // requiring the user's attention, i.e. 'In Review (3)' if a customer / parent
    // user is logged in and there are three "submitted" reviews
    const reviewTabLabel = isParentOrg ? 'In Review' : 'Pending'
    const isLiteAccount = entitlements?.level === 'lite'

    const integrationTabs =
        isLiteAccount && !isSuperUser
            ? ['Published', 'Unpublished', 'Archived']
            : ['Published', 'Unpublished', 'Archived', reviewTabLabel, 'Drafts']

    if (isSuperUser) {
        integrationTabs.push('Pandium')
    }

    return (
        <>
            <GridActions
                location={get(props, 'location.pathname', '/integrations')}
                isParentOrg={isParentOrg}
                parentOrgName={parentOrgName}
            />
            <TabsBar
                tabs={integrationTabs}
                handleChange={(e, value) => setTabValue(value)}
                tabValue={tabValue}
            />
            {!integrationsLoading && (
                <BaseList
                    empty={
                        <EmptyState
                            emptyStateText={
                                'Welcome to Pandium!\nCreate an integration to view and manage activity.'
                            }
                        />
                    }
                    title="Integrations"
                    resource="integrations"
                    perPage={50}
                    sx={{
                        root: integrationListStyles.listRoot,
                        content: integrationListStyles.listContent,
                    }}
                    actions={null}
                    sort={{
                        field: 'long_name',
                        order: 'DESC',
                    }}
                    filter={filter}
                >
                    <IntegrationGrid
                        marketplaceBaseUrl={marketplaceBaseUrl}
                        isParentOrg={isParentOrg}
                    />
                </BaseList>
            )}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        entitlements: state.org.entitlements,
        isSuperUser: state.org.isSuperUser,
        parentOrgName: state.org.parentName,
        isParentOrg: state.org.isParentOrg,
        marketplaceBaseUrl: state.org.marketplaceBaseUrl,
    }
}

export default connect(mapStateToProps)(IntegrationList)
