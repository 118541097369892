// We store organizations in state in two ways. One is as a resource
// managed by react-admin, which allows customers to add and edit their
// partner organizations. But when a user logs in they also need site-wide
// access to the information for the organization of which that user is
// a member, which is stored in state.org. This actions and the associated
// reducer keep track of the user's organization for the duration of a session.
export const getOrgAction = (
    org_id,
    parent_id,
    is_parent_org,
    is_super_user
) => ({
    type: 'ORG_FETCH_REQUESTED',
    payload: {
        orgId: org_id,
        parentId: parent_id,
        isParentOrg: is_parent_org,
        isSuperUser: is_super_user,
    },
})

export const updateOrgAction = (org) => ({
    type: 'UPDATE_ORG',
    payload: org,
})
