import React, { useState, useEffect, useRef } from 'react'
import { JsonForms } from '@jsonforms/react'
import { materialRenderers } from '@jsonforms/material-renderers'
import { Grid, Typography } from '@mui/material'
import { makeStyles, useTheme, ThemeProvider } from '@mui/styles'
import { connect } from 'react-redux'
import BooleanWithLink from '../../../jsonFormComponents/BooleanWithLink'
import BooleanWithLinkTester from '../../../jsonFormComponents/BooleanWithLinkTester'
import { useFormContext } from 'react-hook-form'
import { jsonFormTheme } from '../../../jsonFormComponents/JsonFormsWrapper'

const usePartnerSubmissionFormStyles = makeStyles({
    root: {
        '& .MuiFormControl-root': {
            marginTop: '20px',
            width: '50%',
        },
    },
    heading: {
        color: '#707279',
    },
    requiredNotice: {
        marginLeft: '20px',
        marginTop: '20px',
    },
})

const PartnerSubmissionForm = ({
    customSubmissionForm,
    values,
    setValues,
    readOnly,
    orgName,
    errors,
    setErrors,
}) => {
    const form = useFormContext()
    const [answers, setAnswers] = useState(values?.discussion?.answers || {})
    const theme = useTheme()
    const newTheme = {}
    Object.assign(newTheme, theme, jsonFormTheme)

    let errorsChecked = useRef(false)
    useEffect(
        () => {
            if (!readOnly) {
                setValues({
                    ...values,
                    discussion: {
                        answers: answers,
                        schemas: customSubmissionForm,
                        partnerName: orgName,
                        enableSubmit: errorsChecked && !errors.length,
                    },
                })
                if (!errorsChecked) {
                    errorsChecked.current = true
                }
                form.setValue('discussion.answers', answers)
                form.setValue(
                    'discussion.enableSubmit',
                    errorsChecked && !errors.length
                )
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [answers, errors]
    )

    if (errors.length) {
        errors.forEach((error) =>
            console.debug(
                `input error: ${error.parentSchema.type} ${error.message}`
            )
        )
    }

    const classes = usePartnerSubmissionFormStyles()
    const submissionForm = values.discussion?.schemas || customSubmissionForm
    if (!submissionForm) {
        return <div />
    }
    const { schema, uischema } = submissionForm
    return (
        <>
            <Grid item xs={6} className={classes.root}>
                <Typography variant="h5" className={classes.heading}>
                    Integration Submission Form
                </Typography>
            </Grid>
            <Grid item xs={6} className={classes.root}>
                <ThemeProvider theme={newTheme}>
                    <JsonForms
                        data={answers}
                        schema={schema}
                        uischema={uischema}
                        renderers={[
                            ...materialRenderers,
                            {
                                tester: BooleanWithLinkTester,
                                renderer: BooleanWithLink,
                            },
                        ]}
                        onChange={({ errors, data }) => {
                            setAnswers(data)
                            setErrors(errors)
                        }}
                        readonly={readOnly}
                        validationMode="ValidateAndShow"
                    />
                </ThemeProvider>
            </Grid>
            {schema?.required.length > 0 && (
                <Grid item xs={9}>
                    <Typography
                        variant="subtitle2"
                        className={classes.requiredNotice}
                    >
                        * Indicates a required field
                    </Typography>
                </Grid>
            )}
        </>
    )
}

const mapStateToProps = (state) => ({
    customSubmissionForm: state.org.customSubmissionForm,
    orgName: state.org.name,
})

export default connect(mapStateToProps)(PartnerSubmissionForm)
