import React from 'react'
import { Box, Link, Typography } from '@mui/material'

export default function CliDownloadPage() {
    const staticUrl =
        process.env.REACT_APP_STATIC_URI ||
        `https://static.${window.location.hostname
            .split('.')
            .slice(1)
            .join('.')}`
    return (
        <Box sx={{ margin: '14px', width: '940px' }}>
            <Box width="66%" marginBottom="12px">
                <Typography
                    variant="h6"
                    sx={{ color: '#707279', marginBottom: '14px' }}
                >
                    [Beta] Download the Pandium CLI
                </Typography>
                <Typography variant="subtitle1">
                    The CLI allows developers to connect to Pandium from their
                    local enviroment in order to securely test integration
                    scripts using live data. Download the binary for your system
                    here. If you don't see your platform of choice listed,
                    please let us know. Once you have the file, make it
                    executable and move it into your path.
                </Typography>
            </Box>
            {[
                {
                    name: 'MacOS aarch64',
                    path: '/cli/macos/aarch64/pandium',
                },
                {
                    name: 'MacOS x86_64',
                    path: '/cli/macos/x86_64/pandium',
                },
                {
                    name: 'Linux x86_64',
                    path: '/cli/linux/x86_64/pandium',
                },
                {
                    name: 'Windows x86_64',
                    path: '/cli/windows/x86_64/pandium.exe',
                },
            ].map(({ name, path }) => (
                <Box margin={'8px'}>
                    <Link href={`${staticUrl}${path}`}>{name}</Link>
                </Box>
            ))}
        </Box>
    )
}
