import React, { useState } from 'react'
import { Tabs, Tab, Divider, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { isEmpty, get } from 'lodash-es'
import { NextButton, CreateButton, UpdateButton } from './WizardFormButtons'

const useWizardStyles = makeStyles({
    divider: {
        margin: '30px 0 25px 0',
    },
    tabsIndicator: { backgroundColor: '#635ee7' },
    tabsRoot: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        margin: '0 0 35px 0',
    },
    tabRoot: {
        '&:disabled': {
            color: '#635ee7',
            opacity: '1',
        },
        color: '#635ee7',
        opacity: '1',
    },
    contentContainer: {
        minWidth: '960px',
        '& > fieldset': {
            border: 'none',
        },
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
})

const WizardForm = (props) => {
    const {
        children,
        initialTab,
        formErrorState,
        recordData,
        setRecordData,
        validate,
    } = props
    // This allows us to conditionally render children. EX: InternalWizardFormWrapper
    // conditionally rendering the Connectors tab. Else it will count the null in the total children.
    const classes = useWizardStyles()
    const navigate = useNavigate()
    const filteredChildren = React.Children.toArray(children).filter(Boolean)
    const [tabIndex, setTabIndex] = useState(initialTab || 0)

    const methods = useForm({
        defaultValues: recordData,
        resolver: validate,
        mode: 'onBlur', //validation mode
    })
    const totalTabs = React.Children.count(children) - 1
    const isLastTab = tabIndex === filteredChildren.length - 1

    const back = () => {
        if (tabIndex > 0) {
            setTabIndex(tabIndex - 1)
        }
    }

    const next = (data) => {
        if (tabIndex < totalTabs) {
            setRecordData(data)
            setTabIndex(tabIndex + 1)
        }
        window.scrollTo(0, 0)
    }

    const cancel = (record, resource) => {
        navigate('/' + resource)
    }

    const onTabClick = (event, tabIndex) => {
        setTabIndex(tabIndex)
    }

    // Uses current tabIndex to clone the right child element
    const getTabContent = (tabIndex, children, values, methods) => {
        const currentTab = filteredChildren[tabIndex]
        const currentTabKids = currentTab.props.children

        return React.cloneElement(currentTab, {
            children: currentTabKids,
            values: values,
            methods: methods,
        })
    }

    const actionButtonHelper = (isLastTab, invalid, filteredChildren) => {
        const {
            resource,
            jsonFormValues,
            record,
            integrationReleases,
            integrations,
            isJsonFormValid,
            formErrorState,
        } = props

        const notValid = tabIndex === 2 && !isJsonFormValid
        return (
            <div>
                {tabIndex === 0 && (
                    <Button
                        className="clearButton"
                        onClick={() => cancel(record, resource)}
                    >
                        {' '}
                        CANCEL{' '}
                    </Button>
                )}
                {tabIndex > 1 && (
                    <Button onClick={back} className="clearButton">
                        {' '}
                        Back{' '}
                    </Button>
                )}
                {tabIndex === 0 && (
                    <CreateButton
                        shouldRedirect={false}
                        disabled={invalid}
                        values={methods.getValues()}
                        resource={resource}
                        next={next}
                        integrationReleases={integrationReleases}
                        integrations={integrations}
                    />
                )}
                {tabIndex > 0 && !isLastTab && (
                    <NextButton
                        next={() => next(recordData)}
                        tabIndex={tabIndex}
                        children={filteredChildren}
                        disabled={invalid || notValid}
                    />
                )}
                {isLastTab && !isEmpty(recordData) && (
                    <UpdateButton
                        disabled={invalid || formErrorState}
                        values={{
                            ...recordData, //this is a bit hacky...but this form is only used for tenant create so leaving this way for now
                            paused: methods.getValues()['paused'],
                            userSchedule: methods.getValues()['userSchedule'],
                        }}
                        resource={resource}
                        jsonFormValues={jsonFormValues}
                        record={recordData}
                        integrationReleases={integrationReleases}
                    />
                )}
            </div>
        )
    }

    return (
        <FormProvider {...methods}>
            <Tabs
                value={tabIndex}
                classes={{
                    indicator: classes.tabsIndicator,
                    root: classes.tabsRoot,
                }}
                onChange={onTabClick}
            >
                {filteredChildren.map((child, idx) => {
                    // Won't add tab if null
                    return (
                        child && (
                            <Tab
                                key={idx}
                                classes={{
                                    selected: classes.tabRoot,
                                }}
                                label={get(child, 'props.label', '')}
                                disabled={true}
                            />
                        )
                    )
                })}
            </Tabs>
            <div className={classes.contentContainer}>
                {getTabContent(tabIndex, filteredChildren, recordData, methods)}
            </div>
            <Divider className={classes.divider} />
            {actionButtonHelper(
                isLastTab,
                !methods.formState.isValid,
                filteredChildren,
                formErrorState
            )}
        </FormProvider>
    )
}

export default WizardForm
