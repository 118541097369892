import React from 'react'
import { Typography } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const styles = makeStyles({
    subtitle: {
        color: 'rgb(134, 132, 140)',
        margin: '5px 0 25px 0',
    },
})

export default (props) => {
    const { uischema } = props
    const classes = styles()

    return (
        <Typography variant={'subtitle2'} className={classes.subtitle}>
            {' '}
            {uischema.text}{' '}
        </Typography>
    )
}
