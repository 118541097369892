import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { isConnectorConnected } from '@thefront/pandipackV2'
import { DisconnectDialog } from '../common'
import { get } from 'lodash-es'
import { useDataProvider, useNotify, Form } from 'react-admin'
import ConnectDialog from './ConnectDialog'
// TODO this is custom for kombo..
import { showKomboConnect } from '@kombo-api/connect'
import { isEmpty } from 'lodash'

const ConnectButton = (props) => {
    const { connector, tenant } = props
    const [connect, setConnect] = useState(true)
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const [openConnectDialog, setOpenConnectDialog] = useState(false)
    const [openDisconnectDialog, setOpenDisconnectDialog] = useState(false)
    const [oAuth2Link, setOauth2Link] = useState('')
    const [modalError, setModalError] = useState('')
    const [modalLoading, setModalLoading] = useState(false)

    const isConnected = isConnectorConnected(tenant, connector.name)

    // TODO this is custom for kombo..
    const [komboToken, setKomboToken] = useState('')

    useEffect(() => {
        if (!isEmpty(komboToken)) {
            connectBasic('kombo', { token: komboToken })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [komboToken])

    const connectBasicFailure = (error) => {
        // TODO remove toString once we normalize the returned error from author
        notify(error.toString(), { type: 'error' })
        setModalError(error.toString())
        setModalLoading(false)
        setOpenConnectDialog(true)
    }

    const connectBasicLoading = () => {
        setModalError('')
        setModalLoading(true)
    }

    const connectBasicSuccess = () => {
        setModalError('')
        setModalLoading(false)
        console.debug('CONNECT BASIC SUCCESS:', connector.name)
        notify('Save Success!')
        setOpenConnectDialog(false)
    }

    // Redirect to oauth2 link if applicable, else render the connector form
    const handleOpenConnectDialog = () => {
        if (
            connector.type !== 'basic' &&
            get(connector, 'metadata.multi_step') !== true &&
            ['web', 'hybrid', 'custom'].includes(
                get(connector, 'metadata.grant_flow')
            )
        ) {
            connectRequest(connector.name)
        } else {
            setOpenConnectDialog(true)
        }
        if (get(connector, 'metadata.grant_flow') === 'custom') {
            setOpenConnectDialog(true)
        }
    }

    const handleCloseConnectDialog = () => {
        setModalError('')
        setOpenConnectDialog(false)
    }

    const handleOpenDisconnectDialog = () => {
        setOpenDisconnectDialog(true)
    }

    const handleCloseDisconnectDialog = () => {
        setOpenDisconnectDialog(false)
    }

    const disconnectRequest = (connectorName) => {
        dataProvider
            .AUTHOR('author/disconnect_tenant', {
                data: {
                    connector_name: connectorName,
                    integration_name: tenant.integration.name,
                    tenant_id: tenant.id,
                },
            })
            .then(() => {
                console.debug('Disconnect SUCCESS: ', connector.name)
                notify('Disconnect Success!')
            })
    }

    const connectRequest = (connectorName, form_data = undefined) => {
        setModalError('')
        dataProvider
            .AUTHOR('author/connect_tenant', {
                data: {
                    connector_name: connectorName,
                    integration_name: tenant.integration.name,
                    tenant_id: tenant.id,
                    form_data,
                },
            })
            .then(async (res) => {
                // TODO this is custom for kombo..
                if (connectorName === 'kombo') {
                    setKomboToken(await showKomboConnect(res.data.link))
                } else if (res.data.link) {
                    setOauth2Link(res.data.link)
                    setOpenConnectDialog(true)
                } else {
                    //look for install URL / custom linnworks login flow
                    setOauth2Link(res.data.installUrl)
                    setOpenConnectDialog(true)
                }
            })
            .catch((error) => {
                notify('Error creating OAuth2 Link, please check credentials.')
                setModalError(
                    'Error creating OAuth2 Link, please check credentials.'
                )
                console.debug(error)
            })
    }

    const connectBasic = (connectorName, values) => {
        connectBasicLoading()
        dataProvider
            .AUTHOR('author/callback/basic', {
                data: {
                    connector_name: connectorName,
                    integration_name: tenant.integration.name,
                    tenant_id: tenant.id,
                    secrets: values,
                },
            })
            .then((res) => {
                connectBasicSuccess()
            })
            .catch((err) => {
                console.debug(err)
                connectBasicFailure(err.message.detail || err.message)
            })
    }

    const disconnect = () => {
        disconnectRequest(
            connector.name,
            get(tenant, 'integration.name'),
            tenant.id
        )
        setOpenConnectDialog(false)
        setConnect(true)
    }

    return (
        //need to wrap this in a form so react-admin input components get a 'control' prop...
        <Form>
            {connect && !isConnected ? (
                <Button
                    children="Reconnect"
                    onClick={handleOpenConnectDialog}
                    sx={{
                        color: '#626FFC',
                        margin: '5px 0 0 0',
                        padding: '0',
                        fontFamily: 'RobotoCondensedBold',
                        letterSpacing: '1.5px',
                    }}
                />
            ) : (
                <Button
                    children="Disconnect"
                    onClick={handleOpenDisconnectDialog}
                    sx={{
                        color: '#626FFC',
                        margin: '5px 0 0 0',
                        padding: '0',
                        fontFamily: 'RobotoCondensedBold',
                        letterSpacing: '1.5px',
                    }}
                />
            )}
            <ConnectDialog
                close={handleCloseConnectDialog}
                open={openConnectDialog}
                connectorName={connector.name}
                connector={connector}
                tenant={tenant}
                oAuth2Link={oAuth2Link}
                modalError={modalError}
                modalLoading={modalLoading}
                connectRequest={connectRequest}
                connectBasic={connectBasic}
            />
            <DisconnectDialog
                handleClose={handleCloseDisconnectDialog}
                open={openDisconnectDialog}
                connector={connector}
                disconnect={disconnect}
            />
        </Form>
    )
}

export default ConnectButton
