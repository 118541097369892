import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    CircularProgress,
    Typography,
    IconButton,
    Box,
} from '@mui/material'
import { isEmpty } from 'lodash-es'
import { HorizontalTextField } from './common'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileCopy from '@mui/icons-material/FileCopy'

const ConnectorSecretsDialog = ({
    close,
    open,
    dialogLoading,
    connector,
    connectorSecrets,
}) => {
    return (
        <Dialog
            open={open}
            maxWidth={'sm'}
            fullWidth={true}
            sx={{ padding: '20px' }}
        >
            <DialogTitle sx={{ margin: 'auto' }}>
                {connector?.label} Secrets
            </DialogTitle>

            <DialogContent
                sx={{
                    width: '90%',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 'auto',
                }}
            >
                {dialogLoading ? (
                    <CircularProgress sx={{ margin: 'auto' }} />
                ) : (
                    <Box>
                        {isEmpty(connectorSecrets) ? (
                            <Typography
                                sx={{ color: '#6d6d6d', alignSelf: 'center' }}
                            >
                                CONNECTOR SECRETS NOT AVAILABLE
                            </Typography>
                        ) : (
                            Object.keys(connectorSecrets).map((secretName) => {
                                return (
                                    <Box
                                        key={secretName}
                                        sx={{
                                            display: 'flex',
                                        }}
                                    >
                                        <CopyToClipboard
                                            text={connectorSecrets[secretName]}
                                        >
                                            <IconButton>
                                                <FileCopy />
                                            </IconButton>
                                        </CopyToClipboard>
                                        <HorizontalTextField
                                            key={secretName}
                                            record={connectorSecrets}
                                            rowName={secretName}
                                            source={secretName}
                                            truncate={true}
                                        />
                                    </Box>
                                )
                            })
                        )}
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close()}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConnectorSecretsDialog
