import React from 'react'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { FormControlLabel, FormHelperText, Link } from '@mui/material'
import { MuiCheckbox } from '@jsonforms/material-renderers'

const CheckBoxWithLink = (props) => {
    const { label } = props.uischema

    return (
        <FormControlLabel
            control={<MuiCheckbox {...props} />}
            label={
                <div style={{ padding: '20px' }}>
                    I accept the&nbsp;
                    <Link href={label} underline={'always'} target={'_blank'}>
                        Terms and Conditions.
                    </Link>
                    *
                    {props.description && (
                        <FormHelperText>{props.description}</FormHelperText>
                    )}
                </div>
            }
        />
    )
}

export default withJsonFormsControlProps(CheckBoxWithLink)
