import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useRedirect, useNotify, useDelete } from 'react-admin'

const deleteButtonStyles = makeStyles({
    deleteButton: {
        letterSpacing: '2px',
        textAlign: 'center',
        lineHeight: '21px',
        borderRadius: '0',
        width: '165px',
        height: '42px',
        fontSize: '16px',
    },
    white: {
        // There is really no reason for font size to be a function
        // of color, but this handles the transition from the old to newer design
        fontSize: '16px',
        color: '#fc3b40',
    },
    red: {
        fontSize: '15px',
        color: '#ffffff',
        backgroundColor: '#ff4c4c',
    },
    dialogContainer: {
        display: 'flex',
    },
    dialogContent: {
        width: '300px',
    },
    dialogDeleteButton: {
        color: '#fc3b40',
    },
})

const RESOURCENAMES = {
    integrations: 'Integration',
    tenants: 'Tenant',
    integrationreleases: 'Integration Release',
    jobs: 'Job',
    connectors: 'Connector',
    partners: 'Partner',
    organizations: 'Partner',
    users: 'User',
}

/**
 * Deletes single resource
 * @param record
 * @param resource
 * @param children
 * @returns {*}
 * @constructor
 */
const DeleteButton = ({
    record,
    resource,
    children,
    nestedResource,
    path,
    color = 'white',
    disabled,
}) => {
    const classes = deleteButtonStyles()
    const redirect = useRedirect()
    const notify = useNotify()
    const [open, setOpen] = useState(false)
    const resourceType = nestedResource ? nestedResource : resource

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    const [deleteResource, { isLoading }] = useDelete(
        resourceType,
        {
            id: record.id,
            previousData: record,
        },
        {
            onSuccess: ({ data }) => {
                notify(`${RESOURCENAMES[resourceType]} Deleted Successfully`)
                handleClose()
                redirect(path)
            },
            onFailure: (error) => {
                notify(
                    `Error deleting ${RESOURCENAMES[resourceType]}: ${error.message}`,
                    'warning'
                )
                handleClose()
            },
        }
    )

    return (
        <div className={classes.dialogContainer}>
            <Button
                className={`${classes.deleteButton} ${classes[color]}`}
                onClick={handleClickOpen}
                disabled={isLoading || disabled}
            >
                {children}
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="xs">
                <DialogTitle>
                    {' '}
                    Delete {RESOURCENAMES[resourceType]}?{' '}
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <DialogContentText>
                        This action is permanent!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => deleteResource()}
                        className={classes.dialogDeleteButton}
                    >
                        Delete
                    </Button>
                    <Button className="clearButton" onClick={handleClose}>
                        {' '}
                        Cancel{' '}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DeleteButton
