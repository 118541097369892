import React, { useEffect } from 'react'
import { useListContext } from 'react-admin'
import makeStyles from '@mui/styles/makeStyles'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Button, Toolbar } from '@mui/material'

const useListPaginationStyles = makeStyles({
    paginationBar: {
        display: 'flex',
        justifyContent: 'space-around',
        border: 'none',
        fontSize: '24px',
        '& .numbers': {
            fontSize: '15px',
        },
    },
})

export default ({ perPageCustom }) => {
    const {
        page,
        perPage,
        setPage,
        setPerPage,
        data,
        isLoading,
    } = useListContext()

    useEffect(() => {
        setPerPage(perPageCustom)

        // setPerPage gets updated too frequently to be in the dep array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perPageCustom])

    const displayCount = isLoading ? 0 : data?.length
    const classes = useListPaginationStyles()
    const first = (page - 1) * perPage + 1
    const last = first + displayCount - 1
    return (
        displayCount > 0 && (
            <Toolbar className={classes.paginationBar}>
                <div></div>
                <Button
                    color="primary"
                    key="prev"
                    onClick={() => setPage(page - 1)}
                    disabled={page === 1}
                >
                    <ChevronLeft />
                </Button>
                <span className={'numbers'}>
                    {first}-{last}
                </span>
                <Button
                    color="primary"
                    key="next"
                    onClick={() => setPage(page + 1)}
                    disabled={displayCount !== perPage}
                >
                    <ChevronRight />
                </Button>
                <div></div>
            </Toolbar>
        )
    )
}
