import React from 'react'
import {
    CircularProgress,
    Typography,
    ListItem,
    List,
    Button,
} from '@mui/material'
import {
    TextInput,
    TopToolbar,
    useGetList,
    useDelete,
    useRefresh,
    useNotify,
    required,
} from 'react-admin'
import BaseDialog from '../../BaseDialog'
import { CreateCategoryButton } from './CategoriesList'
import Clear from '@mui/icons-material/Clear'

const tagListStyles = (PandiumColors) => ({
    greyTitle: {
        color: PandiumColors.grey,
    },
    inputField: {
        width: '100%',
        margin: '10px 0 20px 0',
    },
    dialogLauncher: {
        background: PandiumColors.bluepurple,
    },
    hiddenField: {
        display: 'none',
        visibility: 'none',
    },
    button: {
        border: 'none',
        background: 'transparent',
        color: PandiumColors.bluepurple,
        fontWeight: 'bold',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none',
        },
    },
    toolbar: {
        width: '50%',
        minWidth: '360px',
    },
    list: {
        background: 'white',
        listStyle: 'none',
        flexWrap: 'wrap',
        width: '50%',
        minWidth: '360px',
        border: '1px solid rgba(0, 0, 0, 0.30)',
        padding: '8px',
        display: 'flex',
    },
    listItem: {
        padding: '5px',
        borderRadius: '3px 3px',
        margin: '5px 5px;',
        background: 'white',
        border: '1px solid rgba(0, 0, 0, 0.30)',
        width: 'fit-content',
    },
    deleteButton: {
        color: 'lightgrey',
        cursor: 'pointer',
        background: 'transparent',
        border: 'none',
    },
    deleteIcon: {
        width: '18px',
        height: '18px',
    },
})

const TagItem = ({ tag }) => {
    const refresh = useRefresh()
    const notify = useNotify()
    const [deleteOne, { deleteLoading }] = useDelete(
        'integrationlabels',
        {
            id: tag.id,
            previousData: tag,
        },
        {
            onSuccess: () => {
                refresh()
                notify('Deleted Successfully!')
            },
        }
    )

    const content = (
        <>
            <span>{tag.name}</span>
            <Button
                sx={(theme) => tagListStyles(theme.PandiumColors).deleteButton}
                onClick={() => deleteOne()}
            >
                <Clear
                    sx={(theme) =>
                        tagListStyles(theme.PandiumColors).deleteIcon
                    }
                />
            </Button>
        </>
    )

    return (
        <ListItem
            sx={(theme) => tagListStyles(theme.PandiumColors).listItem}
            key={tag.id}
        >
            {deleteLoading ? <CircularProgress /> : content}
        </ListItem>
    )
}

export default () => {
    const { data: tags, isLoading: loading } = useGetList('integrationlabels', {
        sort: { field: 'name', order: 'ASC' },
        filter: { labelType: 'TAG' },
        pagination: { page: 1, perPage: 500 },
    })

    if (loading) {
        return <CircularProgress />
    }

    const dialogContentFields = (
        <>
            <TextInput
                label="type"
                source="label_type"
                validate={required()}
                sx={(theme) => tagListStyles(theme.PandiumColors).hiddenField}
            />
            <TextInput
                label="Name"
                source="name"
                validate={required()}
                variant="filled"
                sx={(theme) => tagListStyles(theme.PandiumColors).inputField}
            />
        </>
    )

    return (
        <>
            <TopToolbar
                sx={(theme) => tagListStyles(theme.PandiumColors).toolbar}
            >
                <Typography
                    variant="h5"
                    sx={(theme) => tagListStyles(theme.PandiumColors).greyTitle}
                >
                    Manage Tags
                </Typography>
                <BaseDialog
                    SubmitButton={CreateCategoryButton}
                    initialValues={{ label_type: 'Tag' }}
                    openDialogButtonText="create"
                    dialogTitle={`Create New Tag`}
                    dialogLauncherClass="createButton"
                    dialogContentText=""
                    dialogContentFields={dialogContentFields}
                    closeDialogButtonText="Cancel"
                />
            </TopToolbar>
            <List
                component="ul"
                sx={(theme) => tagListStyles(theme.PandiumColors).list}
            >
                {tags.length === 0 ? (
                    <ListItem key={0}>
                        <p>Create some tags</p>
                    </ListItem>
                ) : (
                    tags.map((tag) => <TagItem tag={tag} key={tag.id} />)
                )}
            </List>
        </>
    )
}
