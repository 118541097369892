import React from 'react'
import { Link, useCreatePath, useRecordContext } from 'react-admin'

export default ({
    basePath,
    redirect = 'show',
    className,
    text = 'DETAILS',
}) => {
    const createPath = useCreatePath()
    const record = useRecordContext()
    return (
        <Link
            to={createPath({
                resource: basePath,
                type: redirect,
                id: record.id,
            })}
            className={className}
            onClick={(e) => e.stopPropagation()}
        >
            {' '}
            {text}{' '}
        </Link>
    )
}
