import React, { useEffect, useState } from 'react'
import {
    TextField,
    TextInput,
    useNotify,
    useUpdate,
    useCreate,
    useDelete,
    useRefresh,
    TopToolbar,
    useDataProvider,
    SelectInput,
    useGetList,
    useUpdateMany,
    useList,
    ListContextProvider,
    useListContext,
    required,
} from 'react-admin'
import BaseDialog from '../../BaseDialog'
import {
    Typography,
    Button,
    CircularProgress,
    Switch,
    Table,
    TableCell,
    TableHead,
    TableBody,
    TableRow,
} from '@mui/material'
import { get, isEmpty } from 'lodash-es'
import Sortable from 'sortablejs'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { EmptyState } from '../../react_admin/EmptyState'
import { useFormContext } from 'react-hook-form'
import { Tooltip } from '@mui/material'

export const showHideStyles = (PandiumColors) => ({
    greyTitle: {
        color: PandiumColors.grey,
    },
    inputField: {
        width: '100%',
        margin: '10px 0 20px 0',
    },
    dialogLauncher: {
        background: PandiumColors.bluepurple,
    },
    hiddenField: {
        display: 'none',
        visibility: 'none',
    },
    button: {
        border: 'none',
        background: 'transparent',
        color: PandiumColors.bluepurple,
        fontWeight: 'bold',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none',
        },
    },
})

const tableStyles = {
    headRow: {
        backgroundColor: '#F1F4FB',
    },
    headCell: {
        textAlign: 'center',
        fontWeight: 'bold',
    },
    bodyCell: {
        textAlign: 'center',
    },
    typeCell: {
        color: '#707279',
    },
    sectionBreak: {
        backgroundColor: '#D8D8D8',
    },
    sectionBreakEdit: {
        textAlign: 'center',
        paddingRight: '78px',
    },
    categoryName: {
        display: 'inline-flex',
        verticalAlign: 'center',
    },
}

const removeTooltipStyles = {
    tooltip: {
        fontFamily: 'Roboto',
        textAlign: 'center',
        width: '165px',
        height: 'auto',
        fontSize: '12px',
        backgroundColor: 'rgba(0,0,0,0.87)',
    },
}

const useUpdateOneRecord = (values, onSuccess) => {
    const notify = useNotify()
    return useUpdate(
        'integrationlabels',
        {
            id: values.id,
            data: values,
        },
        {
            onSuccess: (data) => {
                console.debug('Success: ', data)
                notify('Updated Succesfully!')
                if (onSuccess) {
                    onSuccess()
                }
            },
            onFailure: (error) => {
                notify('Error Updating')
                console.debug('Error: ', get(error, 'message', error))
            },
        }
    )
}

const useUpdateManyRecords = (values, onSuccess) => {
    const notify = useNotify()
    const refresh = useRefresh()
    return useUpdateMany(
        'integrationlabels',
        {
            ids: [values.id],
            data: values,
        },
        {
            onSuccess: (data) => {
                refresh()
                console.debug('Success: ', data)
                notify('Updated Succesfully!')
                if (onSuccess) {
                    onSuccess()
                }
            },
            onFailure: (error) => {
                notify('Error Updating')
                console.debug('Error: ', get(error, 'message', error))
            },
        }
    )
}

const ShowHideField = ({ record, isSubcategory = false, parentShow }) => {
    const displayValue = record?.show ? 'Hide' : 'Show'
    const [update] = useUpdateOneRecord({ id: record?.id, show: !record?.show })

    const clickShowHide = (e) => {
        update()
    }
    return (
        <Switch
            checked={record?.show}
            onChange={clickShowHide}
            name="show"
            inputProps={{
                'aria-label': `${displayValue} this category in the marketplace`,
            }}
            disabled={isSubcategory && !parentShow}
        />
    )
}

export const UpdateOneButton = ({ handleSubmit }) => {
    const { getValues, formState } = useFormContext()
    const [update, { isFetching: loading }] = useUpdateOneRecord(
        getValues(),
        handleSubmit
    )
    return (
        <Button
            onClick={() => {
                update()
            }}
            disabled={loading || !formState.isValid}
        >
            Save
        </Button>
    )
}

const UpdateManyButton = ({ handleSubmit }) => {
    const { getValues, formState } = useFormContext()
    const [update, { isFetching: loading }] = useUpdateManyRecords(
        getValues(),
        handleSubmit
    )
    return (
        <Button
            onClick={() => {
                update()
            }}
            disabled={loading || !formState.isValid}
        >
            Save
        </Button>
    )
}

export const CreateCategoryButton = ({ handleSubmit }) => {
    const methods = useFormContext()
    const notify = useNotify()
    const refresh = useRefresh()
    const [create, { isLoading }] = useCreate()
    return (
        <Button
            onClick={() => {
                create(
                    'integrationlabels',
                    { data: methods.getValues() },
                    {
                        onSuccess: (data) => {
                            console.debug('Success: ', data)
                            notify('Created Successfully!')
                            handleSubmit()
                            methods.reset({
                                name: null,
                                parentCategory: null,
                                label_type: null,
                                show: true,
                            })
                            refresh()
                        },
                        onFailure: (error) => {
                            notify('Oops, there was an issue.')
                            console.debug(
                                'Error: ',
                                get(error, 'message', error)
                            )
                        },
                    }
                )
            }}
            disabled={
                isLoading ||
                !methods.formState.isDirty ||
                !methods.formState.isValid
            }
        >
            Create
        </Button>
    )
}

const EditCategoryField = ({ record, hasSubcategories }) => {
    const notify = useNotify()
    const refresh = useRefresh()
    const [deleteOne, { deleteLoading }] = useDelete(
        'integrationlabels',
        {
            id: record?.id,
            previousData: record,
        },
        {
            onSuccess: () => {
                refresh()
                notify('Deleted Successfully!')
            },
        }
    )

    const editCategoryDialog = (
        <>
            <TextInput
                label="Current Name"
                source="name"
                variant="filled"
                sx={(theme) => showHideStyles(theme.PandiumColors).inputField}
                disabled={true}
                parse={() => record.name}
                format={(val) =>
                    record.name.includes('--') ? val.split('--')[1] : val
                }
            />
            <TextInput
                label="New Name"
                source="newName"
                variant="filled"
                sx={(theme) => showHideStyles(theme.PandiumColors).inputField}
                validate={required()}
            />
        </>
    )
    const RemoveButton = ({ hasSubcategories }) => {
        return hasSubcategories ? (
            <Tooltip
                placement="left"
                title={'Please remove all subcategories first'}
                classes={{ tooltip: removeTooltipStyles.tooltip }}
            >
                <span>
                    <Button
                        sx={removeTooltipStyles.button}
                        onClick={() => deleteOne()}
                        disabled={true}
                    >
                        Remove{' '}
                    </Button>
                </span>
            </Tooltip>
        ) : (
            <Button
                sx={removeTooltipStyles.button}
                onClick={() => deleteOne()}
                disabled={false}
            >
                Remove{' '}
            </Button>
        )
    }

    if (deleteLoading) {
        return <CircularProgress />
    }

    return (
        <>
            <RemoveButton hasSubcategories={hasSubcategories} />
            {record?.labelType !== 'Section Break' && (
                <BaseDialog
                    SubmitButton={
                        hasSubcategories ? UpdateManyButton : UpdateOneButton
                    }
                    dialogLauncherClass={'createButton'}
                    initialValues={record}
                    openDialogButtonText="Edit"
                    dialogTitle={`Edit ${record?.labelType}`}
                    dialogContentText=""
                    dialogContentFields={editCategoryDialog}
                    closeDialogButtonText="Cancel"
                />
            )}
        </>
    )
}

const CategoryTable = React.memo(
    ({ subcategoryMap }) => {
        const { data: records } = useListContext()
        return (
            <Table id="category-table">
                <TableHead>
                    <TableRow sx={tableStyles.headRow}>
                        <TableCell sx={tableStyles.headCell}>NAME</TableCell>
                        <TableCell sx={tableStyles.headCell}>TYPE</TableCell>
                        <TableCell sx={tableStyles.headCell}>
                            SHOW IN MARKETPLACE
                        </TableCell>
                        <TableCell sx={tableStyles.headCell}>ACTIONS</TableCell>
                    </TableRow>
                </TableHead>
                {Object.entries(subcategoryMap).map(
                    ([parent, { idx, id, subcategories }]) => {
                        const category = records[idx]
                        const isSectionBreak =
                            category?.labelType === 'Section Break' ?? false
                        return (
                            <TableBody
                                id={`category-${id}`}
                                key={`category-${id}`}
                            >
                                <TableRow
                                    id={id}
                                    key={id}
                                    sx={
                                        isSectionBreak
                                            ? tableStyles.sectionBreak
                                            : null
                                    }
                                >
                                    <TableCell>
                                        <Typography
                                            sx={tableStyles.categoryName}
                                        >
                                            <DragIndicatorIcon color="action" />{' '}
                                            {!isSectionBreak && category?.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={[
                                            tableStyles.bodyCell,
                                            tableStyles.typeCell,
                                        ]}
                                    >
                                        <TextField
                                            record={category}
                                            source="labelType"
                                            sortable={false}
                                        />
                                    </TableCell>
                                    <TableCell sx={tableStyles.bodyCell}>
                                        <ShowHideField
                                            record={category}
                                            sortable={false}
                                        />
                                    </TableCell>
                                    <TableCell
                                        sx={
                                            isSectionBreak
                                                ? tableStyles.sectionBreakEdit
                                                : tableStyles.bodyCell
                                        }
                                    >
                                        <EditCategoryField
                                            record={category}
                                            sortable={false}
                                            hasSubcategories={
                                                subcategoryMap[parent]
                                                    .subcategories.length
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                                {subcategories.length ? (
                                    <>
                                        {subcategories.map(
                                            ({ idx, id, name }) => (
                                                <TableRow
                                                    id={id}
                                                    key={id}
                                                    className="subcategory"
                                                >
                                                    <TableCell
                                                        style={{
                                                            paddingLeft: '40px',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={
                                                                tableStyles.categoryName
                                                            }
                                                        >
                                                            <DragIndicatorIcon color="action" />{' '}
                                                            {name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell
                                                        sx={[
                                                            tableStyles.bodyCell,
                                                            tableStyles.typeCell,
                                                        ]}
                                                    >
                                                        <TextField
                                                            record={
                                                                records[idx]
                                                            }
                                                            source="labelType"
                                                            sortable={false}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        sx={
                                                            tableStyles.bodyCell
                                                        }
                                                    >
                                                        <ShowHideField
                                                            record={
                                                                records[idx]
                                                            }
                                                            sortable={false}
                                                            isSubcategory={true}
                                                            parentShow={
                                                                category?.show
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        sx={
                                                            tableStyles.bodyCell
                                                        }
                                                    >
                                                        <EditCategoryField
                                                            record={
                                                                records[idx]
                                                            }
                                                            sortable={false}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </TableBody>
                        )
                    }
                )}
            </Table>
        )
    },
    (prev, next) => {
        return prev.subcategoryMap === next.subcategoryMap
    }
)

export default ({ title, labelType }) => {
    const dataProvider = useDataProvider()
    const [subcategoryMap, setSubcategoryMap] = useState({})
    const [updating, setUpdating] = useState(false)

    const { data: records, isLoading } = useGetList('integrationlabels', {
        sort: { field: 'orderIndex', order: 'ASC' },
        filter: {
            label_type__in: JSON.stringify([
                'CATEGORY',
                'SUBCATEGORY',
                'BREAK',
            ]),
            limit: 500,
        },
    })

    const listContext = useList({ data: records, isLoading })

    const updateList = async (span, newIdx) => {
        await dataProvider
            .update('integrationlabels', {
                id: span.id,
                data: { orderIndex: newIdx },
            })
            .then(({ data }) => {
                console.log(`${data.name} now in position ${newIdx}`)
            })
    }

    const retrySortable = () => {
        const retries = 3
        const millisecords = 1500
        let attempts = 0
        const el = document.querySelector('#category-table')
        if (!el) {
            if (++attempts >= retries) {
                console.debug(
                    `cannot get sorting feature after ${retries} retries.`
                )
            } else {
                setTimeout(() => retrySortable(), millisecords)
            }
        } else {
            new Sortable(el, {
                handle: 'svg',
                draggable: 'tbody',
                animation: 150,
                easing: 'cubic-bezier(1, 0, 0, 1)',

                onStart: (evt) => {
                    return evt.oldIndex
                },

                onEnd: async (evt) => {
                    setUpdating(true)
                    const list = document.querySelectorAll(
                        '#category-table tbody tr'
                    )

                    let arrayList = Array.from(list)
                    for (let newIdx in arrayList) {
                        try {
                            await updateList(arrayList[newIdx], newIdx)
                        } catch (error) {
                            console.error(
                                `Error updating order index: ${error}`
                            )
                        }
                    }
                    setUpdating(false)
                },
            })

            // makes each parent tbody a sortable object
            const makeSortable = (id) => {
                const parentEl = document.querySelector(`#category-${id}`)
                if (!parentEl) {
                    if (++attempts >= retries) {
                        console.debug(
                            `cannot get sorting feature after ${retries} retries.`
                        )
                    } else {
                        setTimeout(() => makeSortable(), millisecords)
                    }
                } else {
                    new Sortable(parentEl, {
                        handle: 'svg',
                        draggable: `.subcategory`,
                        animation: 150,
                        easing: 'cubic-bezier(1, 0, 0, 1)',

                        onStart: (evt) => {
                            return evt.oldIndex
                        },
                        onEnd: async (evt) => {
                            setUpdating(true)
                            const list = document.querySelectorAll(
                                '#category-table tbody tr'
                            )

                            const arrayList = Array.from(list)

                            for (let i in arrayList) {
                                try {
                                    await updateList(arrayList[i], i, records)
                                } catch (error) {
                                    console.error(
                                        `Error updating order index: ${error}`
                                    )
                                }
                            }
                            setUpdating(false)
                        },
                    })
                }
            }
            for (let key in subcategoryMap) {
                if (subcategoryMap[key].subcategories.length) {
                    makeSortable(subcategoryMap[key].id)
                }
            }
        }
    }

    // creat subcategory map with parent relationship
    useEffect(() => {
        let tempMap = {}
        if (!updating && !isLoading) {
            records?.forEach((cat, idx) => {
                if (cat.name.includes('--')) {
                    const [parent, sub] = cat.name.split('--')
                    tempMap[parent]?.subcategories.push({
                        idx: idx,
                        id: cat.id,
                        name: sub,
                    })
                } else if (!tempMap[cat.name]) {
                    let key = cat.name === 'N/A' ? `_${cat.id}` : cat.name
                    tempMap[key] = { idx: idx, id: cat.id, subcategories: [] }
                }
            })
            setSubcategoryMap(tempMap)
        }
    }, [updating, records, isLoading])

    // create sortable objects as subcategory map updates
    useEffect(() => {
        retrySortable()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subcategoryMap])

    const categoryTypeChoices = [
        { id: 'Category', name: 'Category' },
        { id: 'Subcategory', name: 'Subcategory' },
        { id: 'Section Break', name: 'Section Break' },
    ]

    const [showParents, setShowParents] = useState(false)
    const [isSectionBreak, setIsSectionBreak] = useState()
    const handleChange = (e) => {
        setShowParents(e.target.value === 'Subcategory' ?? false)
        setIsSectionBreak(e.target.value === 'Section Break' ?? false)
    }
    const parentCategories = []
    for (let id in records) {
        if (
            !records[id].name.includes('--') &&
            records[id].labelType === 'Category'
        )
            parentCategories.push({ id: id, name: records[id].name })
    }

    const createCategoryDialog = (
        <>
            <SelectInput
                fullWidth
                source="label_type"
                label="Element Type"
                choices={categoryTypeChoices}
                translateChoice={false}
                onChange={handleChange}
                // RA uses react-hook-form for validation: https://marmelab.com/react-admin/Validation.html
                validate={required()}
            />
            {!isSectionBreak && (
                <TextInput
                    label="Name Element"
                    source="name"
                    variant="filled"
                    sx={(theme) =>
                        showHideStyles(theme.PandiumColors).inputField
                    }
                    validate={required()}
                />
            )}
            {!isSectionBreak && showParents && (
                <SelectInput
                    fullWidth
                    source="parentCategory"
                    label="Parent Category"
                    translateChoice={false}
                    choices={parentCategories}
                    optionValue="name"
                    validate={required()}
                />
            )}
        </>
    )

    if (isLoading) {
        return <CircularProgress />
    }

    return (
        <>
            <TopToolbar>
                <Typography
                    variant="h5"
                    sx={(theme) =>
                        showHideStyles(theme.PandiumColors).greyTitle
                    }
                >
                    {title}
                </Typography>
                <BaseDialog
                    SubmitButton={CreateCategoryButton}
                    openDialogButtonText="create"
                    dialogTitle={`Create New ${labelType}`}
                    dialogLauncherClass="createButton"
                    dialogContentText=""
                    dialogContentFields={createCategoryDialog}
                    closeDialogButtonText="Cancel"
                    initialValues={{ show: true }}
                />
            </TopToolbar>
            <ListContextProvider value={listContext}>
                {isEmpty(records) ? (
                    <EmptyState
                        emptyStateText={'No integration categories to show'}
                    />
                ) : (
                    <CategoryTable subcategoryMap={subcategoryMap} />
                )}
            </ListContextProvider>
        </>
    )
}
