import React, { useState } from 'react'
import { CreateButton, UpdateButton } from '../../../Wizard/WizardFormButtons'
import { Divider, Button, Box } from '@mui/material'

import { useRedirect } from 'react-admin'
import { connect } from 'react-redux'
import { PageTitle } from '../../../common'
import TabsBar from '../../../TabsBar'
import { Form } from 'react-admin'
import ProvisionConnectorForm from '../../../Auth/ProvisionConnectorForm'
import PartnerSubmissionForm from '../Edit/PartnerSubmissionForm'
import PandiForm from '../../themeConfig/PandiForm'
import {
    nameDetails,
    connectorSelector,
    internalConfigsCreate,
} from '../IntegrationCreateEditSchemas'
import { useFormContext } from 'react-hook-form'
import { isEmpty, get } from 'lodash-es'

const Buttons = ({
    tabs,
    tabValue,
    setTabValue,
    setInitialValues,
    initialValues,
    errors,
    isParentOrg,
}) => {
    const form = useFormContext()
    const values = form.getValues()

    const redirect = useRedirect()
    const isLastTab = tabValue === tabs.length - 1

    const connectorNameBlank = (connectors) =>
        isEmpty(connectors) ||
        connectors.some((connector) => connector?.name === '')

    const formError =
        !form.formState.isValid ||
        connectorNameBlank(values?.connectors) ||
        !get(values, 'name', false) ||
        !get(values, 'longName', false)
    return (
        <Box
            sx={{
                marginTop: '25px',
                '& > button': {
                    width: '165px',
                    marginRight: '10px',
                    lineHeight: '15px',
                },
            }}
        >
            {tabs[tabValue] === 'Configure' && (
                <>
                    <Button
                        className="clearButton"
                        onClick={() => redirect(`/integrations`)}
                    >
                        CANCEL
                    </Button>
                    <CreateButton
                        resource="integrations"
                        className="filledButton"
                        values={values}
                        next={(data) => {
                            if (!isLastTab) {
                                setTabValue(tabValue + 1)
                            }
                            setInitialValues(data)
                        }}
                        disabled={formError}
                        shouldRedirect={isLastTab}
                        text="CREATE"
                    />
                </>
            )}
            {tabs[tabValue] === 'Configure' && !isParentOrg && isLastTab && (
                //for partners when there is no submission form
                <CreateButton
                    resource="integrations"
                    className="clearButton"
                    values={{
                        ...values,
                        approvalStatus: 'Submitted',
                    }}
                    next={(data) => {
                        if (!isLastTab) {
                            setTabValue(tabValue + 1)
                        }
                        setInitialValues(data)
                    }}
                    disabled={!isEmpty(errors)}
                    shouldRedirect={isLastTab}
                    text="SUBMIT FOR REVIEW"
                />
            )}
            {tabs[tabValue] === 'Provision' && (
                <Button
                    className="filledButton"
                    onClick={() =>
                        isLastTab
                            ? redirect(
                                  `/integrations/${initialValues?.id}/show`
                              )
                            : setTabValue(tabValue + 1)
                    }
                >
                    DONE
                </Button>
            )}
            {tabs[tabValue] === 'Submit for Review' && (
                <UpdateButton
                    disabled={!isEmpty(errors)}
                    values={values}
                    resource="integrations"
                    record={initialValues}
                />
            )}
            {tabs[tabValue] === 'Submit for Review' && !isParentOrg && (
                <UpdateButton
                    disabled={
                        !isEmpty(errors) || !values.discussion?.enableSubmit
                    }
                    values={{
                        ...values,
                        approvalStatus: 'Submitted',
                    }}
                    resource="integrations"
                    record={initialValues}
                    text="SUBMIT FOR REVIEW"
                    className="clearButton"
                />
            )}
        </Box>
    )
}
export const defaultSyncScheduleTimes = [
    { cron: '*/30 * * * *', schedule: 'Every 30 minutes' },
    { cron: '5 */1 * * *', schedule: 'Every 1 Hour' },
    { cron: '20 */12 * * *', schedule: 'Every 12 Hours' },
    { cron: '0 1 * * *', schedule: 'Once Per Day' },
]
const InternalIntegrationCreate = ({
    isParentOrg,
    showCustomSubmissionForm,
    orgName,
}) => {
    const [tabValue, setTabValue] = useState(0)
    const tabs = ['Configure']
    if (isParentOrg) {
        tabs.push('Provision')
    }
    if (showCustomSubmissionForm) {
        tabs.push('Submit for Review')
    }
    const [formErrorState, setFormErrorState] = useState([])

    const [initialValues, setInitialValues] = useState({
        type: 'Internal',
        approvalStatus: isParentOrg ? 'Approved' : 'Draft',
        marketplaceSettings: { published: false, schedule: '*/30 * * * *' },
        connectors: [{ name: '', isGlobal: false }],
        repositoryUrl: '',
        syncScheduleTimes: defaultSyncScheduleTimes,
        discussion: {
            partnerName: orgName,
            enableSubmit: !showCustomSubmissionForm,
        },
    })

    return (
        <Box
            sx={(theme) => ({
                width: '776px',
                [theme.breakpoints.up('sm')]: {
                    width: '896px',
                },
                [theme.breakpoints.up('md')]: {
                    width: '960px',
                },
                [theme.breakpoints.up('lg')]: {
                    width: '1120px',
                },
                [theme.breakpoints.up('xl')]: {
                    width: '1280px',
                },
                [theme.breakpoints.up('xxl')]: {
                    width: '1608px',
                },
            })}
        >
            <PageTitle title="New Integration" />
            {tabs.length > 1 && (
                <TabsBar
                    tabs={tabs}
                    // we want this to be a wizard, so tabs are not clickable
                    handleChange={null}
                    tabValue={tabValue}
                />
            )}
            <Form defaultValues={initialValues} mode="onChange">
                <>
                    {tabs[tabValue] === 'Configure' && (
                        <PandiForm
                            schema={[
                                ...connectorSelector,
                                ...nameDetails,
                                ...internalConfigsCreate,
                            ]}
                        />
                    )}
                    {tabs[tabValue] === 'Provision' && (
                        <ProvisionConnectorForm mode={'CREATE'} />
                    )}
                    {tabs[tabValue] === 'Submit for Review' && (
                        <PartnerSubmissionForm
                            values={initialValues}
                            setValues={setInitialValues}
                            readOnly={false}
                            setErrors={setFormErrorState}
                            errors={formErrorState}
                        />
                    )}
                    <Divider />
                    <Buttons
                        tabs={tabs}
                        tabValue={tabValue}
                        setTabValue={setTabValue}
                        setInitialValues={setInitialValues}
                        initialValues={initialValues}
                        errors={formErrorState}
                        isParentOrg={isParentOrg}
                    />
                </>
            </Form>
        </Box>
    )
}

const mapStateToProps = (state) => ({
    orgName: state.org.name,
    isParentOrg: state.org.isParentOrg,
    showCustomSubmissionForm:
        Boolean(state.org.customSubmissionForm) && !state.org.isParentOrg,
})

export default connect(mapStateToProps)(InternalIntegrationCreate)
