import React from 'react'
import { TextInput } from 'react-admin'
import { rankWith, isStringControl } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'

export const CustomStringInputTester = rankWith(
    Number.MAX_VALUE,
    isStringControl
)

export const CustomStringInput = withJsonFormsControlProps(
    ({ uischema, path, handleChange, description }) => (
        <TextInput
            source={path}
            label={uischema.label}
            size="medium"
            margin="normal"
            fullWidth={true}
            onChange={(e) => handleChange(path, e.target.value)}
            helperText={description}
        />
    )
)
