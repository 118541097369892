import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Unwrapped } from '@jsonforms/material-renderers'
import { withJsonFormsControlProps } from '@jsonforms/react'

const { MaterialEnumControl } = Unwrapped

const useStyles = makeStyles(() => ({
    input: {
        '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
            minWidth: '225px',
        },
    },
}))

function CustomMaterialEnumControl(props) {
    const classes = useStyles()
    const options = props.schema.enum?.map((option) => ({
        label: option,
        value: option,
    }))

    return (
        // TODO get rid of clear button?
        <div className={classes.input}>
            <MaterialEnumControl
                {...props}
                uischema={
                    !props.uischema.options?.trim
                        ? { ...props.uischema, options: { trim: true } }
                        : props.uischema
                }
                options={options}
                closeIcon={null}
            />
        </div>
    )
}

export default withJsonFormsControlProps(CustomMaterialEnumControl)
