import React from 'react'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    boldLarge: {
        fontFamily: 'RobotoCondensedBold',
        fontSize: '20px',
        paddingBottom: '5px',
    },
    regLarge: {
        color: '#6d6d6d',
        fontSize: '20px',
        marginBottom: '10px',
    },
})

export default ({ label, children }) => {
    const classes = useStyles()
    return (
        <Grid container justifyContent="flex-start">
            <Grid item xs={12} className={classes.regLarge}>
                {' '}
                {label}{' '}
            </Grid>
            <Grid item xs={12} className={classes.boldLarge}>
                {' '}
                {children}{' '}
            </Grid>
        </Grid>
    )
}
