import React from 'react'
import queryString from 'query-string'
import Iframe from 'react-iframe'

export default () => {
    const { name, preferred_username, email } = JSON.parse(
        sessionStorage.getItem('kc')
    )?.idTokenParsed
    const params = queryString.stringify({
        name: name || email,
        preferred_username: preferred_username || email,
        email,
    })

    return (
        <div style={{ height: '90vh', minHeight: '70vh' }}>
            <Iframe
                url={`${process.env.REACT_APP_MARKETPLACE_LINK}?${encodeURI(
                    params
                )}`}
                width="100%"
                height="100%"
                id="imp-iframe"
                title="imp-iframe"
                display="initial"
                position="relative"
                frameBorder={0}
            />
        </div>
    )
}
