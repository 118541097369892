import React from 'react'
import { Routes, Route } from 'react-router-dom'
import ExternalIntegrationCreate from './ExternalIntegrationCreate'
import InternalIntegrationCreate from './InternalIntegrationCreate'
import IntegrationList from '../IntegrationList'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
    return {
        parentOrgName: state.org.parentName,
        isParentOrg: state.org.isParentOrg,
    }
}

export default connect(mapStateToProps)(({ isParentOrg, parentOrgName }) => {
    return !isParentOrg && parentOrgName === 'vena' ? ( //TODO: remove when partner permissions are fleshed out
        <Routes>
            <Route path="/external" element={<ExternalIntegrationCreate />} />
            <Route path="/" element={<IntegrationList />} />
        </Routes>
    ) : (
        <Routes>
            <Route path="/internal" element={<InternalIntegrationCreate />} />
            <Route path="/external" element={<ExternalIntegrationCreate />} />
            <Route path="/" element={<IntegrationList />} />
        </Routes>
    )
})
