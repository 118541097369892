import React, { useState, useEffect } from 'react'
import AceEditor from 'react-ace'
import 'brace/mode/css'
import 'brace/theme/cobalt'
import { useNotify } from 'react-admin'
import { useSelector } from 'react-redux'
import { Box, Button, Typography } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { useDropzone } from 'react-dropzone'
import { uploadFile } from './common'

const sampleCSS = `
/***************************************************************************** 
Please DO NOT target any generated classes you may find in the DOM, these are 
not stable. If you need to target an element not covered by one of these 
classes, please contact your TAM.

Please note, all properties must be followed by !important.

Font changes will only work on individual levels, not globally.
******************************************************************************/

/*****
 * pandium hero banner -styles for the hero banner
 * ***/

.pandium-integration-list .pandium-hero-banner {
    background-color: yellow; !important;
}

.pandium-integration-list .pandium-hero-banner .pandium-main-text {
    color: white; !important;
}

.pandium-integration-list .pandium-hero-banner .pandium-sub-text {
    color: white; !important;
}

.pandium-integration-list .pandium-hero-banner .pandium-primary-button {
    background-color: thistle; !important;
}

.pandium-integration-list .pandium-hero-banner .pandium-secondary-button {
    background-color: thistle; !important;
}


/*****
 * integration list -styles for the list page for available published apps
 * ***/

.pandium-integration-list {
    background-color: #AED6F1; !important;
}


/*****
 * category list -styles for the category list sidebar
 * ***/

.pandium-integration-list .pandium-category-list {
    background-color: thistle; !important;
}

.pandium-integration-list .pandium-category-list .pandium-category-name {
    text-transform: lowercase; !important;
}

.pandium-integration-list .pandium-category-list .pandium-category-title {
    color: black; !important;
}

.pandium-integration-list .pandium-category-list .pandium-active {
    color: black; !important;
}


/*****
 * integration list header -styles for the top of the list page for 
 * available published apps
 * ***/

.pandium-integration-list .pandium-header .pandium-main-text{
    color: white; !important;
}

.pandium-integration-list .pandium-header .pandium-sub-text{
    color: white; !important;
}

.pandium-integration-list .pandium-header .pandium-primary-button{
    background-color: thistle; !important;
}


/*****
 * integration list body -styles for the body of the list page for available 
 * published apps
 * ***/

.pandium-integration-list .pandium-body .pandium-integration-card {
    background-color: thistle; !important;
}

.pandium-integration-list .pandium-body .pandium-search-bar {
    background-color: thistle; !important;
}

.pandium-integration-list .pandium-body .pandium-card-button {
    background-color: #AED6F1; !important;
}

.pandium-integration-list .pandium-body .pandium-see-all-button {
    background-color: thistle; !important;
}

.pandium-integration-list .pandium-body .pandium-category-name {
    background-color: thistle; !important;
}


/*****
 * installed apps header -styles for the top of the list page for installed apps
 * ***/

.pandium-installed-apps .pandium-header .pandium-main-text {
    color: white; !important;
}

.pandium-installed-apps .pandium-header .pandium-sub-text {
    color: white; !important;
}

.pandium-installed-apps .pandium-header .pandium-primary-button {
    color: white; !important;
}


/*****
 * installed apps -styles for the list page for installed apps
 * ***/

.pandium-installed-apps {
    background-color: #AED6F1; !important;
}

.pandium-installed-apps .pandium-search-bar {
    background-color: thistle; !important;
}

.pandium-installed-apps .pandium-installed-card {
    background-color: thistle; !important;
}

.pandium-installed-apps .pandium-installed-card .pandium-sync-button{
    background-color: thistle; !important;
}

.pandium-installed-apps .pandium-installed-card .pandium-tenant-name {
}

.pandium-installed-apps .pandium-installed-card .pandium-connect-button{
    background-color: thistle; !important;
}


/*****
 * integration detail -styles for the install page for a single app
 * ***/

.pandium-integration-detail {
    background-color: thistle; !important;
}

.pandium-integration-detail .pandium-back-button {
    background-color: thistle; !important;
}

.pandium-integration-detail .pandium-app-details {
}

.pandium-integration-detail .pandium-app-details .pandium-logo-box {
}

.pandium-integration-detail .pandium-app-details .pandium-logo-box .pandium-logo {
}

.pandium-integration-detail .pandium-app-details .pandium-text {
}

.pandium-integration-detail .pandium-app-details .pandium-install-button {
}

.pandium-integration-detail .pandium-app-details .pandium-install-link {
}

.pandium-integration-detail .pandium-media-gallery {
}

.pandium-integration-detail .pandium-media-gallery .pandium-main-image {
}

.pandium-integration-detail .pandium-media-gallery .pandium-thumbnail {
}


/*****
 * ftux -styles for the app install flow
 * ***/

.pandium-ftux {
}

.pandium-ftux .pandium-header {
}

.pandium-ftux .pandium-connector-frame {
}

.pandium-ftux .pandium-connector-frame .pandium-main-text {
}

.pandium-ftux .pandium-connector-frame .pandium-text {
}

.pandium-ftux .pandium-connector-frame .pandium-connector-box  {
}

.pandium-ftux .pandium-connector-frame .pandium-connector-box .pandium-logo {
}

.pandium-ftux .pandium-connector-frame .pandium-connector-box .pandium-name {
}

.pandium-ftux .pandium-connector-frame .pandium-connector-box .pandium-button { 
}

.pandium-ftux .pandium-tenant-configs {
}

.pandium-ftux .pandium-sync-schedule { 
}

.pandium-ftux .pandium-sync-schedule .pandium-main-text {
}

.pandium-ftux .pandium-sync-schedule .pandium-schedule-time {
}

.pandium-ftux .pandium-sync-schedule .pandium-text {   
}

.pandium-ftux .pandium-sync-schedule .pandium-dropdown {
}

.pandium-ftux .pandium-buttons {    
}

.pandium-ftux .pandium-buttons .pandium-cancel {
}

.pandium-ftux .pandium-buttons .pandium-save {
}

.pandium-ftux .pandium-buttons .pandium-save-and-sync {
}

.pandium-logs-page .pandium-header {
}

.pandium-logs-page .pandium-logs-list {
}

.pandium-schedule-page {
}

.pandium-connection-settings-page {
}
`

const sectionHeadline = 'Upload custom CSS file'

const FileUploader = ({ setEditedText, setIsUploading }) => {
    const onFileChange = (files) => {
        const fileReader = new FileReader()
        fileReader.onloadend = () => {
            const content = fileReader.result
            setEditedText(content)
            setIsUploading(false)
        }
        fileReader.readAsText(files[0])
    }
    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop: onFileChange,
        noClick: true,
    })

    return (
        <section>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Box
                    sx={{
                        width: '100%',
                        height: '500px',
                        backgroundColor: 'gray',
                        position: 'relative',
                    }}
                >
                    <Box
                        width={'100%'}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'absolute',
                            zIndex: 2,
                        }}
                    >
                        <Button
                            onClick={() => setIsUploading(false)}
                            sx={{
                                color: 'black',
                                alignSelf: 'flex-end',
                            }}
                        >
                            X
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '10px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '500px',
                            width: '100%',
                            position: 'absolute',
                            zIndex: 1,
                        }}
                    >
                        <CloudUploadIcon fontSize={'large'} />
                        <Button variant={'contained'} onClick={open}>
                            Choose a file to upload
                        </Button>
                        <Typography>Or drag and drop it here</Typography>
                    </Box>
                </Box>
            </div>
        </section>
    )
}
const FileLinkFromText = ({ text, name, children = '' }) => {
    const blob = new Blob([text], { type: 'text/css' })
    const url = window.URL.createObjectURL(blob)
    return (
        <a href={url} download={name}>
            {children}
        </a>
    )
}

const SaveButton = ({ text, onSaveSuccess, ...rest }) => {
    const notify = useNotify()
    const upload = async () => {
        const newFile = new File([text], 'custom.css', {
            type: 'text/css',
        })
        uploadFile('css', newFile, 'text/css').then((res) => {
            if (res !== 'error') {
                console.log(`Success! ${res}`)
                notify('Upload success')
                onSaveSuccess()
            } else {
                console.error(res)
                notify('Something went wrong', {
                    type: 'error',
                })
            }
        })
    }
    return <Button onClick={upload} {...rest} />
}

const ButtonBox = ({ children }) => (
    <Box
        width={'20%'}
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
        children={children}
    />
)

export default function CustomCSSManager() {
    const [loading, setLoading] = useState(true)
    const orgName = useSelector((state) => state.org.name)

    const [isUploding, setIsUploading] = useState(false)

    const [serverText, setServerText] = useState('')
    const [editedText, setEditedText] = useState('')
    const isDraft = serverText !== editedText

    const onEditorChange = (newValue) => {
        setEditedText(newValue)
    }

    const onDownloadClick = () => {
        const now = new Date()
        const filename = `pandium_marketplace_${now.getFullYear()}_${now.getMonth()}_${now.getDate()}_${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}`
        const blob = new Blob([editedText], {
            type: 'text/css',
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        link.click()
    }

    // fetch existing css file (if it exists) on component mount
    useEffect(() => {
        const staticUrl =
            process.env.REACT_APP_STATIC_URI ||
            `https://static.${window.location.hostname
                .split('.')
                .slice(1)
                .join('.')}`
        const getFile = async () =>
            fetch(`${staticUrl}/${orgName}/css/custom.css`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'text/css',
                },
            })
                .then((response) => {
                    if (response.ok) {
                        return response.text()
                    }
                })
                .then((text) => {
                    if (text) {
                        setServerText(text)
                        setEditedText(text)
                    }
                    setLoading(false)
                })

        getFile()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!loading) {
        return (
            <Box sx={{ margin: '14px', width: '940px' }}>
                <Box width="66%" marginBottom="12px">
                    <Typography
                        variant="h6"
                        sx={{ color: '#707279', marginBottom: '14px' }}
                    >
                        {sectionHeadline}
                    </Typography>
                    <Typography variant="subtitle2">
                        To get started, download a sample{' '}
                        <FileLinkFromText
                            text={sampleCSS}
                            name={'pandium-marketplace-sample.css'}
                        >
                            here
                        </FileLinkFromText>{' '}
                        for a list of available class names. Once done please
                        upload the file below to apply.
                    </Typography>
                </Box>
                <Box width="80%">
                    {isUploding ? (
                        <FileUploader
                            setEditedText={setEditedText}
                            setIsUploading={setIsUploading}
                        />
                    ) : (
                        <AceEditor
                            mode="css"
                            value={editedText}
                            theme="cobalt"
                            name={'csseditor'}
                            onChange={onEditorChange}
                            width={'100%'}
                            fontSize={12}
                            lineHeight={19}
                            showPrintMargin={false}
                        />
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            marginTop: '10px',
                        }}
                    >
                        <ButtonBox>
                            <Button
                                size={'large'}
                                variant={'outlined'}
                                onClick={() => setIsUploading(true)}
                            >
                                Upload
                            </Button>
                        </ButtonBox>
                        <ButtonBox>
                            <Button
                                variant={'outlined'}
                                size={'large'}
                                onClick={onDownloadClick}
                            >
                                Download
                            </Button>
                        </ButtonBox>
                        <ButtonBox>
                            {isDraft && (
                                <Typography
                                    variant={'subtitle2'}
                                    color={'#00ffff'}
                                >
                                    Draft
                                </Typography>
                            )}
                        </ButtonBox>
                        <ButtonBox>
                            <Button
                                size={'large'}
                                variant={'outlined'}
                                disabled={!isDraft}
                                onClick={() => setEditedText(serverText)}
                            >
                                Reset
                            </Button>
                        </ButtonBox>
                        <ButtonBox>
                            <SaveButton
                                text={editedText}
                                variant={'filled'}
                                className={'filledButton'}
                                disabled={!isDraft}
                                onSaveSuccess={() => {
                                    setServerText(editedText)
                                }}
                            >
                                Save
                            </SaveButton>
                        </ButtonBox>
                    </Box>
                </Box>
            </Box>
        )
    }
}
