import React from 'react'
import {
    Grid,
    Typography,
    Divider,
    Radio,
    RadioGroup,
    FormControlLabel,
    Tooltip,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/HelpOutline'
import {
    TextInput,
    SelectInput,
    BooleanInput,
    Labeled,
    useInput,
    NumberInput,
} from 'react-admin'
import FontPicker from './FontPicker'
import { get } from 'lodash-es'
import ColorPicker from './ColorPicker'
import cronstrue from 'cronstrue'
import { useFormContext } from 'react-hook-form'

const MailingList = ({ source, label, validate, errors, values }) => {
    // Grabs the specific nested values needed from the values prop
    const localValues = get(values, source)
    return (
        <Grid container key={source}>
            <Grid
                item
                xs={4}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& .MuiFormHelperText-root': {
                        position: 'absolute',
                    },
                }}
            >
                {label}
            </Grid>
            <Grid
                item
                xs={1}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& .MuiFormHelperText-root': {
                        position: 'absolute',
                    },
                }}
            >
                <BooleanInput source={`${source}.enabled`} label={false} />
            </Grid>
            <Grid item xs={4}>
                <TextInput
                    disabled={!localValues.enabled}
                    fullWidth
                    source={`${source}.emails`}
                    size="medium"
                    margin="normal"
                    label={'Email address'}
                    multiline={true}
                    validate={validate}
                    helperText={
                        errors[source]?.emails ||
                        'Separate multiple emails with a comma and space.'
                    }
                />
            </Grid>
        </Grid>
    )
}

const TextField = ({
    source,
    label,
    helperText,
    validate,
    disabled = false,
    multiline = false,
    defaultValue = undefined,
    errors,
}) => {
    return (
        <>
            <Grid item xs={7}>
                <TextInput
                    defaultValue={defaultValue}
                    fullWidth
                    key={source}
                    source={source}
                    size="medium"
                    margin="normal"
                    label={label}
                    helperText={errors[source] || helperText}
                    disabled={disabled}
                    multiline={multiline}
                    validate={validate}
                    variant="filled"
                />
            </Grid>
            <Grid item xs={5}></Grid>
        </>
    )
}

const TextArea = ({ source, label, helperText, validate }) => (
    <>
        <Grid item xs={7}>
            <TextInput
                fullWidth
                key={source}
                source={source}
                size="medium"
                margin="normal"
                label={label}
                helperText={helperText}
                validate={validate}
                multiline={true}
                minRows={9}
                maxRows={15}
            />
        </Grid>
        <Grid item xs={5} />
    </>
)

const DropDown = ({
    source,
    label,
    choices = [],
    validate,
    defaultValue = null,
}) => {
    return (
        <>
            <Grid item xs={5}>
                <SelectInput
                    fullWidth
                    emptyText={'Select One'}
                    emptyValue={''}
                    choices={choices}
                    helperText={false}
                    variant="filled"
                    size="medium"
                    margin="normal"
                    label={label}
                    source={source}
                    validate={validate}
                    defaultValue={defaultValue}
                />
            </Grid>
            <Grid item xs={7} />
        </>
    )
}

const Font = ({ source, label }) => (
    <>
        <Grid item xs={5}>
            <FontPicker source={source} label={label} />
        </Grid>
        <Grid item xs={7} />
    </>
)

const Boolean = ({ source, label, helperText, values }) => {
    return (
        <Grid item xs={12}>
            <BooleanInput
                source={source}
                label={label}
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    '& .MuiFormControlLabel-root': {
                        flexDirection: 'row-reverse',
                        justifyContent: 'space-between',
                        minWidth: '300px',
                        marginLeft: '0',
                    },
                    '& .MuiSwitch-switchBase.Mui-checked': {
                        color: '#626FFC !important',
                    },
                    '& .MuiSwitch-track': {
                        backgroundColor: '#626FFC !important',
                    },
                }}
                helperText={
                    get(values, source) ? helperText?.true : helperText?.false
                }
            />
        </Grid>
    )
}

const Color = ({ source, label }) => (
    <>
        <Grid item xs={5}>
            <ColorPicker source={source} label={label} />
        </Grid>
        <Grid item xs={7}></Grid>
    </>
)

// NOTE: This is a specialized dropdown that is only used in
// the integration configuration, because we want the options for
// the dropdown to draw from another field in the main form. It would
// be nice to have a more generalized solution to this.
const CronPicker = ({ source, label }) => {
    const formMethods = useFormContext()
    const values = formMethods.getValues()

    const choices = values?.syncScheduleTimes?.reduce(
        (choices, currentValue) => {
            if (currentValue.schedule && currentValue.cron) {
                return [
                    ...choices,
                    {
                        name: currentValue.schedule,
                        id: currentValue.cron,
                    },
                ]
            } else {
                return choices
            }
        },
        []
    )

    const cronValidator = (cronString) => {
        if (cronString === '') {
            return undefined
        }
        try {
            cronstrue.toString(cronString)
        } catch {
            return 'please make another selection'
        }
    }

    return (
        <DropDown
            source={source}
            label={label}
            choices={choices}
            validate={[cronValidator]}
        />
    )
}

const RadioButtons = ({ label, source, choices }) => {
    const controller = useInput({ source })
    return (
        <Grid item xs={10}>
            <Labeled label={label}>
                <RadioGroup {...controller.field}>
                    {choices.map((choice) => (
                        <span key={choice.id}>
                            <FormControlLabel
                                value={choice.id}
                                control={<Radio />}
                                disableTypography
                                label={
                                    <span style={{ display: 'inline-flex' }}>
                                        <Typography>{choice.name}</Typography>
                                        <Tooltip
                                            placement="right"
                                            title={choice.tooltip}
                                        >
                                            <HelpIcon
                                                fontSize="small"
                                                sx={{ alignSelf: 'center' }}
                                            />
                                        </Tooltip>
                                    </span>
                                }
                            />
                        </span>
                    ))}
                </RadioGroup>
            </Labeled>
        </Grid>
    )
}

const Custom = ({ source, component, label, errors }) => (
    <Grid item xs={12}>
        {component({
            source: source,
            key: source,
            label: label,
            helperText: errors[source],
        })}
    </Grid>
)

const NumberField = ({ source, min, max, label, defaultValue, errors }) => (
    <>
        <Grid item xs={5}>
            <NumberInput
                fullWidth
                defaultValue={defaultValue}
                label={label}
                min={min}
                max={max}
                source={source}
                errors={errors}
            />
        </Grid>
        <Grid item xs={7} />
    </>
)

const PandiField = ({ source, input, values = {}, errors }) => {
    const { type, label, choices, showIf, component, defaultValue } = input

    // use 'showIf' to make displaying one field in a form
    // dependent on another field being set. Specifically,
    // this allows the toggle to show the seperate apps button
    // to only appear if the marketplaces are split
    // Uses a comma separated string for multiple conditions
    if (showIf) {
        const showConditions = showIf.split(',')
        for (const condition of showConditions) {
            if (!get(values, condition, false)) {
                return <div />
            }
        }
    }

    switch (type) {
        case 'colorPicker':
            return <Color source={source} label={label} />
        case 'fontPicker':
            return <Font source={source} label={label} />
        case 'dropDown':
            return (
                <DropDown
                    source={source}
                    label={label}
                    choices={choices}
                    defaultValue={defaultValue}
                />
            )
        case 'text':
            return <TextField source={source} {...input} errors={errors} />
        case 'textarea':
            return <TextArea source={source} {...input} errors={errors} />
        case 'boolean':
            return <Boolean values={values} source={source} {...input} />
        case 'radio':
            return (
                <RadioButtons label={label} source={source} choices={choices} />
            )
        case 'cronPicker':
            return <CronPicker source={source} label={label} />
        case 'custom':
            return (
                <Custom
                    component={component}
                    source={source}
                    {...input}
                    errors={errors}
                />
            )
        case 'mailingList':
            return (
                <MailingList
                    source={source}
                    label={label}
                    values={values}
                    {...input}
                    errors={errors}
                />
            )
        case 'number':
            return <NumberField source={source} {...input} errors={errors} />
        default:
            return <div />
    }
}

/**
 * @param schema An array of JSON objects, each representing a section
 * of the form to be rendered. Sections should have the following fields:
 * {
 *      title: <string>,
 *      subheading: <string>,
 *      docLink: <string>,
 *      divider: <boolean> (optional) set to false if you don't want a divider
 *      at the bottom of the section
 *      fields: object with keys coresponding to source
 *              values from the accompanying
 *              values object, and the fields
 *              {
 *                  type: <string>,
 *                  label: <string>,
 *                  helperText: <string, object if type =='boolean'> (optional),
 *                  choices: <array> (only if type == 'dropDown' or 'radio'),
 *                   validate: <array of validators> (optional),
 *                  showIf: <string> (optional, make displaying this field
 *                                    conditional on another field being 'true')
 *              }
 * }
 * @param values values object from a containing Form compnent
 * state to form, may be needed for some custom components
 */

export default ({ schema, disabled = false, values }) => {
    const { formState } = useFormContext()
    return (
        <Grid
            container
            sx={{
                width: '940px',
                '& > div > div > hr': {
                    marginBottom: '30px',
                    marginTop: '40px',
                },
                '& > fieldset': {
                    border: 'none',
                },
            }}
        >
            <fieldset disabled={disabled}>
                {schema.map(
                    (
                        { title, subheading, docLink, fields, divider = true },
                        index
                    ) => (
                        <Grid container item xs={12} key={index}>
                            <Grid
                                item
                                xs={8}
                                sx={{
                                    marginTop: '10px',
                                    marginBottom: '20px',
                                    '& .MuiTypography-h6': {
                                        color: '#707279',
                                        marginBottom: '14px',
                                    },
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography variant="h6">
                                    {title.toUpperCase()}
                                </Typography>
                                <Typography variant="subtitle2">
                                    {subheading}
                                    {docLink && (
                                        <>
                                            {
                                                ' More information can be found in our '
                                            }
                                            <a
                                                href={docLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                developer documentation
                                            </a>
                                            .
                                        </>
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}></Grid>
                            {Object.keys(fields).map((source) => {
                                return (
                                    <PandiField
                                        source={source}
                                        input={fields[source]}
                                        key={source}
                                        values={values}
                                        errors={formState.errors}
                                    />
                                )
                            })}
                            {divider && (
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            )}
                        </Grid>
                    )
                )}
            </fieldset>
        </Grid>
    )
}
