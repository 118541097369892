import React, { useState } from 'react'
import { TextInput } from 'ra-ui-materialui'
import { Typography, Divider, Button, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useNavigate } from 'react-router-dom'
import { useRecordContext, useSaveContext, useRedirect } from 'react-admin'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { useForm, FormProvider } from 'react-hook-form'

const useReadFeedbackStyles = makeStyles({
    container: {
        width: '95%',
        backgroundColor: 'RGBA(237, 250, 120, 0.33)',
        padding: '12px 24px',
        border: 'solid 1px #EDFA78',
        marginBottom: '40px',
        '& > h6': {
            fontSize: '20px',
            fontWeight: '700',
            lineHeight: '3',
        },
        '& > pre': {
            whiteSpace: 'pre-wrap',
        },
    },
})
export const ReadFeedback = ({ feedback, parentName }) => {
    const classes = useReadFeedbackStyles()
    return (
        <div className={classes.container}>
            <Typography variant="h6">{`FEEDBACK FROM ${
                parentName?.toUpperCase() || ''
            }`}</Typography>
            <Typography component="pre">{feedback}</Typography>
        </div>
    )
}

const useCreateFeedbackStyles = makeStyles({
    container: {
        width: '100%',
    },
    border: {
        marginBottom: '40px',
        border: '#DBDEE5 solid 1px',
    },
    blueBar: {
        backgroundColor: '#F7FAFF',
        padding: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& > h6': {
            fontWeight: '1000',
        },
    },
    textBoxDiv: {
        padding: '10px',
        '& > p': {
            lineHeight: '2.5',
        },
    },
    textInput: {
        width: '600px',
    },
    buttons: {
        '& > button': {
            marginRight: '24px',
            marginTop: '25px',
        },
    },
    blueButton: {
        backgroundColor: '#353DDF',
        color: 'white',
    },
})
export const CreateFeedback = ({ showBackButton }) => {
    const record = useRecordContext()
    const classes = useCreateFeedbackStyles()
    const { save } = useSaveContext()
    const redirect = useRedirect()
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const methods = useForm({
        defaultValues: record,
    })
    return (
        <FormProvider {...methods}>
            <div className={classes.container}>
                <div className={classes.border}>
                    <div className={classes.blueBar}>
                        <Typography variant="h6">
                            INTEGRATION FEEDBACK
                        </Typography>
                        <IconButton
                            onClick={() => setOpen(!open)}
                            aria-label={`toggle feedback box ${
                                open ? 'closed' : 'open'
                            }`}
                            size="large"
                        >
                            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    </div>
                    {open && (
                        <div className={classes.textBoxDiv}>
                            <Typography>{`Send comments to ${record.discussion.partnerName} including changes required before publishing.`}</Typography>
                            <TextInput
                                fullWidth
                                helperText={false}
                                label="Add feedback..."
                                multiline
                                minRows={8}
                                InputProps={{ disableUnderline: true }}
                                source="discussion.feedback"
                                className={classes.textInput}
                            />
                        </div>
                    )}
                </div>
                <Divider />
                <div className={classes.buttons}>
                    {showBackButton && (
                        <Button
                            variant="text"
                            size="large"
                            onClick={() =>
                                navigate(`/integrations/${record.id}/edit`)
                            }
                        >
                            BACK
                        </Button>
                    )}

                    <Button
                        variant="contained"
                        className={classes.blueButton}
                        size="large"
                        onClick={() =>
                            save(
                                {
                                    ...methods.getValues(),
                                    approvalStatus: 'Reviewed',
                                },
                                { onSuccess: () => redirect('/integrations') }
                            )
                        }
                    >
                        SEND FEEDBACK
                    </Button>
                    <Button
                        variant="outlined"
                        size="large"
                        onClick={() =>
                            save(
                                {
                                    ...methods.getValues(),
                                    approvalStatus: 'Approved',
                                },
                                { onSuccess: () => redirect('/integrations') }
                            )
                        }
                    >
                        APPROVE
                    </Button>
                </div>
            </div>
        </FormProvider>
    )
}
