import React, { useState } from 'react'
import { Divider } from '@mui/material'
import { TopToolbar } from 'react-admin'
import { connect } from 'react-redux'
import CategoriesList from '../resources/IntegrationLabels/CategoriesList'
import TagsList from '../resources/IntegrationLabels/TagsList'
import ThemeConfigEdit from '../resources/themeConfig/ThemeConfigEdit'
import TabsBar from '../TabsBar'
import FlagsList from '../resources/IntegrationLabels/FlagsList'
import CustomCSSManager from '../CustomCSSManager'

const SettingsTabPage = ({ hasGallery, id }) => {
    const [tabValue, setTabValue] = useState(0)
    const handleChange = (event, newValue) => {
        setTabValue(newValue)
    }

    const tabs = [
        'Theme',
        'Content',
        'Categories',
        'Flags',
        'Tags',
        'Custom CSS',
    ]

    // only some organizations have galleries
    if (hasGallery) {
        tabs.push('Gallery')
    }

    return (
        <>
            <TopToolbar>
                <h1>Marketplace Settings</h1>
            </TopToolbar>
            <Divider className="pageHead" />
            <TabsBar
                tabs={tabs}
                tabValue={tabValue}
                handleChange={handleChange}
            />
            {['Theme', 'Content', 'Gallery'].includes(tabs[tabValue]) && (
                <ThemeConfigEdit tabName={tabs[tabValue]} id={id} />
            )}
            {tabs[tabValue] === 'Categories' && (
                <CategoriesList title={'Categories'} labelType={'Category'} />
            )}
            {tabs[tabValue] === 'Flags' && (
                <FlagsList title={'Flags'} labelType={'Flag'} />
            )}
            {tabs[tabValue] === 'Tags' && <TagsList />}
            {tabs[tabValue] === 'Custom CSS' && <CustomCSSManager />}
        </>
    )
}

const mapStateToProps = (state) => ({
    hasGallery: state.org.hasGallery,
    id: state.org.theme?.id,
})
export default connect(mapStateToProps)(SettingsTabPage)
