import React, { useState } from 'react'
import { Button } from '@mui/material'
import { SelectInput, TextInput } from 'react-admin'
import { toString } from 'cronstrue'
import { useFormContext } from 'react-hook-form'

/**
 *
 * @param {string} cronString
 * @returns {[string, boolean]} [humanized string, has error]
 */
const humanizeString = (cronString) => {
    if (cronString === 'paused') {
        return ['', false]
    } else {
        try {
            return [
                toString(cronString, {
                    throwExceptionOnParseError: true,
                }),
                false,
            ]
        } catch (err) {
            return [err.toString(), true]
        }
    }
}

/**
 *
 * @param source
 * @param pauseInDropdown
 * @param label
 * @param integrationScheduleList
 * @param setFormErrorState
 * @param formErrorState
 * @param initialValue
 * @returns {*}
 * @constructor
 */
const CronInput = ({
    source,
    pauseInDropdown,
    label,
    integrationScheduleList,
    setFormErrorState,
    formErrorState,
    initialValue,
}) => {
    const { getValues, setValue } = useFormContext()
    const values = getValues()

    const [humanCron, setHumanCron] = useState(humanizeString(initialValue)[0])

    const choices = [...integrationScheduleList]
    if (pauseInDropdown) {
        if (values.paused) {
            setValue(source, 'paused')
        }
        choices.push({ cron: 'paused', schedule: 'paused' })
    }
    const [isSimple, setIsSimple] = useState(
        Boolean(choices.find((option) => option.cron === values[source]))
    )

    const onCronValueChange = (e) => {
        const [humanString, isError] = humanizeString(e.target.value)
        setHumanCron(humanString)
        setFormErrorState(isError)
        if (pauseInDropdown) {
            setValue('paused', e.target.value === 'paused')
        }
    }

    return (
        <>
            <div style={{ display: 'flex' }}>
                <SelectInput
                    emptyText={'Select Option'}
                    source={source}
                    choices={choices}
                    optionValue="cron"
                    optionText="schedule"
                    onChange={onCronValueChange}
                    label={'Schedule'}
                    variant="outlined"
                    sx={{
                        width: '256px',
                        '& > div.MuiInputBase-root > div': {
                            padding: '16px 12px 10px',
                        },
                        display: !isSimple && 'none',
                    }}
                />
                <TextInput
                    source={source}
                    label={label || false}
                    sx={{
                        width: '256px',
                        display: isSimple && 'none',
                    }}
                    variant={'filled'}
                    onChange={onCronValueChange}
                />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '20px',
                    }}
                >
                    <Button
                        onClick={() => setIsSimple(!isSimple)}
                        sx={{
                            width: '60px',
                            fontSize: '12px',
                            paddingTop: 0,
                            marginLeft: '1em',
                            height: '18px',
                            backgroundColor: '#FFF',
                            color: '#626FFC',
                            fontWeight: 'bold',
                        }}
                    >
                        {isSimple ? 'Advanced' : 'Simple'}
                    </Button>
                </div>
            </div>
            {!isSimple && (
                <div
                    style={{
                        height: '50px',
                        whiteSpace: 'pre-line',
                        color: formErrorState && 'red',
                    }}
                >
                    {humanCron.replace('Error: ', '')}
                </div>
            )}
        </>
    )
}

export default CronInput
