import React, { useState, useRef, useEffect } from 'react'
import {
    Button,
    MenuList,
    MenuItem,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    Tooltip,
} from '@mui/material'
import { useDataProvider, useNotify } from 'react-admin'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { isEmpty } from 'lodash-es'

const SyncButton = (props) => {
    const { record, modes, payload, buttonStyles, syncType, onClick } = props
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const [open, setOpen] = useState(false)
    const [syncing, setSyncing] = useState(false)
    const anchorRef = useRef(null)

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open)
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = open
    }, [open])

    const startSync = (mode) => {
        if (onClick) {
            onClick()
        }
        const success_notification = mode === 'init' ? 'Updating...' : 'Syncing'
        dataProvider
            .SYNC_INITIATED('runs', {
                data: {
                    tenantId: record.id,
                    mode: mode,
                    ...payload,
                },
            })
            .then((res) => {
                if (res.data) {
                    notify(success_notification)
                }
            })
    }

    useEffect(() => {
        if (!isEmpty(record.status?.currentRun)) {
            setSyncing(true)
        } else {
            setSyncing(false)
        }
    }, [record.status])

    const popupMessage = syncing
        ? 'There is a sync in progress. Please wait to sync again.'
        : record.status?.auth?.connected === false
        ? 'You must be connected to run a sync.'
        : props.disabled
        ? 'Syncing disabled, please connect and make sure required fields are entered.'
        : ''

    return (
        <Tooltip
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        fontSize: '14px',
                        padding: '8px ',
                        backgroundColor: 'rgba(0,0,0,0.87)',
                    },
                },
            }}
            title={
                !isEmpty(popupMessage) && (
                    <div>
                        <p>{popupMessage}</p>
                    </div>
                )
            }
            aria-label="add"
            placement="bottom-start"
        >
            {modes ? (
                <div style={{ width: 'fit-content' }}>
                    <Button
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        variant="contained"
                        color="primary"
                        onClick={handleToggle}
                        disabled={props.disabled || syncing}
                        sx={buttonStyles}
                    >
                        {props.label} <ArrowDropDownIcon />
                    </Button>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        placement="bottom-end"
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom'
                                            ? 'center top'
                                            : 'center bottom',
                                }}
                            >
                                <Paper
                                    sx={{
                                        marginTop: '8px',
                                        marginRight: '-1px',
                                        width: '193px',
                                    }}
                                >
                                    <ClickAwayListener
                                        onClickAway={handleClose}
                                    >
                                        <MenuList
                                            autoFocusItem={open}
                                            id="menu-list-grow"
                                            onKeyDown={handleListKeyDown}
                                        >
                                            {modes.map((type) => {
                                                return (
                                                    <MenuItem
                                                        key={type}
                                                        value={type}
                                                        onClick={(event) => {
                                                            startSync(type)
                                                            handleClose(event)
                                                        }}
                                                        sx={{
                                                            width: '193px',
                                                        }}
                                                    >
                                                        {' '}
                                                        {type[0].toUpperCase() +
                                                            type
                                                                .slice(1)
                                                                .toLowerCase()
                                                                .replace(
                                                                    '_',
                                                                    ' '
                                                                ) +
                                                            ' sync'}{' '}
                                                    </MenuItem>
                                                )
                                            })}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
            ) : (
                <div style={{ width: 'fit-content' }}>
                    <Button
                        ref={anchorRef}
                        variant="contained"
                        color="primary"
                        onClick={() => startSync(syncType)}
                        disabled={props.disabled || syncing}
                        sx={buttonStyles}
                    >
                        {props.label}
                    </Button>
                </div>
            )}
        </Tooltip>
    )
}

export default SyncButton
