import React from 'react'
import { TenantShowWrapper } from '../TenantShow'
import TenantEditSchedule from './TenantEditSchedule'
import TenantEditSettings from './TenantEditSettings'
import { Routes, Route, useParams } from 'react-router-dom'
import { useGetOne, useGetList } from 'react-admin'

const TenantEdit = (props) => {
    const { id: tenantID } = useParams()
    const { data: tenant, isLoading: tenantsLoading } = useGetOne('tenants', {
        id: tenantID,
    })
    const {
        data: integrationReleases,
        isLoading: releasesLoading,
    } = useGetList('integrationreleases', {
        pagination: { perPage: 500, page: 1 },
        filter: { integration_id: tenant?.integration.id },
    })
    return (
        !tenantsLoading &&
        !releasesLoading && (
            <Routes>
                <Route
                    path="/edit/schedule"
                    element={<TenantEditSchedule {...props} tenant={tenant} />}
                />
                <Route
                    path="/edit/settings"
                    element={
                        <TenantEditSettings
                            {...props}
                            tenant={tenant}
                            integrationReleases={integrationReleases}
                        />
                    }
                />
                <Route
                    path="/edit"
                    element={<TenantShowWrapper {...props} tenant={tenant} />}
                />
            </Routes>
        )
    )
}

export default TenantEdit
