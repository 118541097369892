import React, { useState } from 'react'
import { Button, Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Delete } from '@mui/icons-material'
import { get, isEmpty } from 'lodash-es'
import { uploadImage } from '../common'
import { useFormContext } from 'react-hook-form'

const useMediaStyles = makeStyles({
    input: {
        display: 'none',
    },
    previewImg: {
        maxWidth: '100%',
    },
    placeholderImage: {
        width: '100%',
        height: '143px',
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '140px',
        width: '240px',
        border: '1px dashed #979797',
        backgroundColor: '#F4F4F4',
    },
    gridContainer: {
        margin: 0,
    },
    filledButton: {
        color: 'white',
        width: '135px',
        height: '40px',
        backgroundColor: '#626FFC',
        fontSize: '15px',
        fontFamily: 'RobotoCondensedBold',
        borderRadius: '0',
        letterSpacing: '2px',
        margin: '0 5px 0 5px',
        '&:disabled': {
            background: 'transparent',
        },
        boxShadow: 'none',
    },
    imageWrapper: {
        position: 'relative',
    },
    deleteButton: {
        position: 'absolute',
        top: '0',
        right: '0',
        color: '#626FFC',
        padding: '5px',
    },
})

export default ({ source }) => {
    const formMethods = useFormContext()
    const values = formMethods.getValues()

    const [imageUrls, setImageUrls] = useState(
        get(values, 'marketplaceSettings.media', [])
    )
    const classes = useMediaStyles()
    const maxFileSize = 1024 * 512

    const fileSelectedHandler = (e) => {
        const selectedFiles = Array.from(e.target.files)

        if (
            selectedFiles.some((file) => {
                return file.size > maxFileSize
            })
        ) {
            window.alert('Please upload images smaller than 512kb')
        } else {
            Promise.all(
                selectedFiles.map((file) => {
                    return uploadImage(
                        'integrations',
                        file,
                        values?.id,
                        'media'
                    )
                })
            ).then(
                (urls) => {
                    const combinedArr = [
                        ...urls.map((img) => {
                            if (img !== 'error') return { src: img }
                            else return null
                        }),
                        ...imageUrls,
                    ]
                    setImageUrls(combinedArr)

                    // Set media urls on the form
                    formMethods.setValue(source, combinedArr)
                },
                (err) => {
                    console.debug(err)
                }
            )
        }
    }

    // Delete based on index
    const handleDelete = (delIndex) => {
        // Can't use splice because it edits the array in place and for some reason react will not
        // recognize the modified array as a 'new' instance and won't update the state.
        const filteredArr = imageUrls.filter((el, idx) => {
            return delIndex !== idx
        })
        setImageUrls(filteredArr)
        formMethods.setValue(source, filteredArr)
    }

    return (
        <Grid container spacing={3} className={classes.gridContainer}>
            {!isEmpty(imageUrls) ? (
                imageUrls.map((image, idx) => {
                    return (
                        <Grid item xs={3} key={idx}>
                            <div className={classes.imageWrapper}>
                                <img
                                    className={classes.previewImg}
                                    src={image.src}
                                    alt={idx}
                                    onError={(ev) =>
                                        (ev.target.src =
                                            'https://upload.wikimedia.org/wikipedia/commons/thumb/8/81/Blank-document-broken.svg/48px-Blank-document-broken.svg.png?20191204040146')
                                    }
                                />
                                <IconButton
                                    onClick={() => handleDelete(idx)}
                                    className={classes.deleteButton}
                                    size="large"
                                >
                                    <Delete />
                                </IconButton>
                            </div>
                        </Grid>
                    )
                })
            ) : (
                <Grid item xs={3}>
                    <img
                        className={classes.placeholderImage}
                        src={
                            'https://returntofreedom.org/store/wp-content/uploads/default-placeholder.png'
                        }
                        alt="placeholder"
                    />
                </Grid>
            )}
            <>
                <input
                    accept="image/*"
                    onChange={fileSelectedHandler}
                    multiple
                    id={source}
                    type="file"
                    className={classes.input}
                />
                <Grid item xs={3}>
                    <div className={classes.buttonContainer}>
                        <label htmlFor={source}>
                            <Button
                                children="Upload"
                                variant="contained"
                                component={'span'}
                                className={classes.filledButton}
                            />
                        </label>
                    </div>
                </Grid>
            </>
        </Grid>
    )
}
