import React from 'react'
import {
    useRedirect,
    TextInput,
    ReferenceInput,
    useUpdate,
    useNotify,
    useRecordContext,
    AutocompleteInput,
} from 'react-admin'
import { Divider, Button, Typography } from '@mui/material'
import { get } from 'lodash-es'
import { Capitalize } from '@thefront/pandipackV2'
import { CustomLabel } from '../../../CustomLabel'
import LogoUploader from '../../../react_admin/LogoUploader'
import { urlValidation } from '../../../inputValidators'
import { updateOrgAction } from '../../../../customActions'
import { connect } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'

const matchEmailAutoCompleteSuggestion = (filter, choice) => {
    if (choice.email)
        return choice.email.toLowerCase().includes(filter.toLowerCase())
}

const EditButton = ({ resource, values, record, children, updateOrg }) => {
    const notify = useNotify()
    const redirect = useRedirect()
    const [edit] = useUpdate(
        resource,
        {
            id: values.id,
            data: values,
            previousData: record,
        },
        {
            onSuccess: (data) => {
                // if the user is editing an organization (as opposed to a partner),
                // it is the user's own organization. We should also update the
                // information in local state
                if (resource === 'organizations') {
                    updateOrg(updatedOrg)
                }
                console.debug('Update Success: ', data)
                notify(`${Capitalize(resource)} Updated`)
                redirect(`/account`)
            },
            onFailure: (error) => {
                console.debug(`Error: ${get(error, 'message', error)}`)
                notify(`Error updating ${Capitalize(resource)}`)
            },
        }
    )

    const { settings, name, id } = values
    const updatedOrg = {
        name: name,
        orgId: id,
        marketplaceBaseUrl: settings?.marketplaceBaseUrl,
        isParentOrg: true,
    }
    return (
        <Button
            className="filledButton"
            onClick={() => {
                edit()
            }}
            disabled={values?.primaryUser?.id === -1}
        >
            {children}
        </Button>
    )
}

const partnerEditFormStyles = {
    actionButtonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    inputField: {
        width: '328px',
        margin: '0',
        display: 'flex',
    },
    divider: {
        margin: '30px 0',
    },
    button: {
        margin: '0 24px',
    },
}

const PartnerEditForm = ({
    entitlements,
    isParentOrg,
    orgId,
    resource,
    updateOrg,
    namespace,
}) => {
    const redirect = useRedirect()
    const record = useRecordContext()
    const methods = useForm({
        defaultValues: record,
    })
    const isLiteAccount = entitlements?.level === 'lite'

    return (
        <FormProvider
            onSubmit={(e) => {
                e.preventDefault()
            }}
            {...methods}
        >
            <>
                <CustomLabel
                    title="Organization"
                    subtitle="Company profile info"
                />
                <TextInput
                    label="Name"
                    source="name"
                    variant="filled"
                    sx={partnerEditFormStyles.inputField}
                    disabled={true}
                    InputProps={{ disableUnderline: true }}
                />
                {namespace !== 'staging-preview' && (
                    <ReferenceInput
                        label="Primary Admin"
                        source="primaryUser.id"
                        resource="users"
                        reference="users"
                        sort={{ field: 'email', order: 'DESC' }}
                        perPage={100}
                        filter={{
                            partner_id: isParentOrg ? null : orgId,
                            is_parent: isParentOrg,
                        }}
                    >
                        <AutocompleteInput
                            optionText="email"
                            matchSuggestion={matchEmailAutoCompleteSuggestion}
                            sx={partnerEditFormStyles.inputField}
                        />
                    </ReferenceInput>
                )}

                <LogoUploader
                    source="settings.primaryLogo"
                    label={'LOGO (2X1)'}
                    formDescription={'Best size 480 px x 240 px'}
                    values={methods.getValues()}
                    helperText="Used to customize the AdminDash"
                    resource="organizations"
                    id={record?.id}
                />

                <LogoUploader
                    source="settings.secondaryLogo"
                    label={'LOGO (1X1)'}
                    formDescription={'Best size 240 px x 240 px'}
                    values={methods.getValues()}
                    helperText={'Used to customize the AdminDash'}
                    resource="organizations"
                    id={record?.id}
                />
                <Divider sx={partnerEditFormStyles.divider} />
                {resource === 'organizations' && isParentOrg && (
                    <>
                        <Typography>Navigation Settings</Typography>
                        <TextInput
                            sx={partnerEditFormStyles.inputField}
                            variant="filled"
                            InputProps={{ disableUnderline: true }}
                            source="settings.marketplaceBaseUrl"
                            label="Marketplace URL"
                            helperText={
                                isLiteAccount ? (
                                    <span>
                                        This setting applies to a Marketplace!
                                        If you’re interested in one, check out
                                        how to upgrade{' '}
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://www.pandium.com/upgrade"
                                        >
                                            here!
                                        </a>
                                    </span>
                                ) : (
                                    'Base URL for your Integration Marketplace'
                                )
                            }
                            validate={urlValidation}
                            disabled={isLiteAccount}
                        />
                        <Divider sx={partnerEditFormStyles.divider} />
                    </>
                )}
                <div style={partnerEditFormStyles.actionButtonsWrapper}>
                    <div>
                        {resource === 'partners' && (
                            <Button
                                onClick={() => {
                                    redirect(`/${resource}/${orgId}/show`)
                                }}
                                className={'clearButton'}
                                sx={partnerEditFormStyles.button}
                            >
                                {' '}
                                CANCEL{' '}
                            </Button>
                        )}
                        <EditButton
                            resource={resource}
                            values={methods.getValues()}
                            record={record}
                            updateOrg={updateOrg}
                        >
                            Save Changes
                        </EditButton>
                    </div>
                </div>
            </>
        </FormProvider>
    )
}

const mapStateToProps = (state) => ({
    entitlements: state.org.entitlements,
    isParentOrg: state.org.isParentOrg,
    orgId: state.org.orgId,
})

const mapDispatchToProps = (dispatch) => ({
    updateOrg: (org) => dispatch(updateOrgAction(org)),
})
export default connect(mapStateToProps, mapDispatchToProps)(PartnerEditForm)
