import React, { useState } from 'react'
import { Edit, useRedirect, useSaveContext } from 'react-admin'
import { Divider, Button } from '@mui/material'
import { PageTitle } from '../../../common'
import CronInput from '../../../CronInput'
import { get } from 'lodash-es'
import PauseToggle from '../../../PauseToggle'
import { CustomLabel } from '../../../CustomLabel'
import { connect } from 'react-redux'
import { useForm, FormProvider, useFormContext } from 'react-hook-form'

const UpdateButton = ({ disabled }) => {
    const { save } = useSaveContext()
    const { getValues } = useFormContext()

    return (
        <Button
            disabled={disabled}
            onClick={() => save(getValues())}
            className="filledButton"
            sx={{
                width: '165px',
                height: '42px',
                margin: '0 5px 0 5px',
            }}
        >
            SAVE CHANGES
        </Button>
    )
}

const TenantEditSchedule = (props) => {
    const { tenant, ...rest } = props
    const methods = useForm({
        defaultValues: tenant,
    })
    const resource = 'tenants'
    const { theme } = props
    const redirect = useRedirect()
    const [formErrorState, setFormErrorState] = useState(false)
    const integrationScheduleList = get(
        tenant,
        'integration.sync_schedule_times'
    )

    const pauseInDropdown = theme.configs.marketplaceSettings.jointCronSelector

    return (
        <div>
            {tenant && <PageTitle title={'Sync Schedule: ' + tenant.name} />}
            <Divider className="pageHead" />
            <Edit
                {...rest}
                mutationMode="pessimistic"
                actions={null}
                component="div"
                redirect={(resource, id) => `${resource}/${id}/edit`}
            >
                <FormProvider {...methods}>
                    <>
                        <CustomLabel title="SYNC ENTITIES" />
                        <CronInput
                            source="userSchedule"
                            pauseInDropdown={pauseInDropdown}
                            integrationScheduleList={integrationScheduleList}
                            label={false}
                            setFormErrorState={setFormErrorState}
                            formErrorState={formErrorState}
                            initialValue={tenant.userSchedule}
                        />
                        {!pauseInDropdown && (
                            <PauseToggle
                                values={methods.getValues()}
                                source="paused"
                                label="Pause Sync Schedule"
                                helpText="Disable to resume sync schedule"
                                disabled={
                                    methods.getValues().userSchedule ===
                                    'paused'
                                }
                            />
                        )}
                        <Divider sx={{ margin: '30px 0' }} />
                        <Button
                            className="clearButton"
                            onClick={() => {
                                redirect(
                                    '/' + resource + '/' + tenant.id + '/show'
                                )
                            }}
                        >
                            {' '}
                            CANCEL{' '}
                        </Button>
                        <UpdateButton
                            disabled={
                                formErrorState || !methods.formState.isDirty
                            }
                        />
                    </>
                </FormProvider>
            </Edit>
        </div>
    )
}

const mapStateToProps = (state) => ({
    theme: state.org.theme,
})

export default connect(mapStateToProps)(TenantEditSchedule)
