import React, { Component } from 'react'
import { Divider } from '@mui/material'

import withStyles from '@mui/styles/withStyles'

const styles = {
    divider: {
        margin: '10px 0',
    },
}

export class JsonFormDivider extends Component {
    render() {
        const { classes } = this.props
        return <Divider className={classes.divider} />
    }
}

export default withStyles(styles)(JsonFormDivider)
