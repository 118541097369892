import React from 'react'
import { Help } from '@mui/icons-material'
import RichTooltip from './RichTooltip'

const labelStyles = {
    root: {
        margin: '22px 0 0 0',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        color: '#6d6d6d',
        fontSize: '21px',
        margin: '20px 0 0 0',
        alignItems: 'center',
        display: 'flex',
    },
    subtitle: {
        display: 'block',
        margin: '10px 0 0px 0px',
        fontSize: '14px',
    },
    muiTitle: {
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '0',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '1',
        display: 'flex',
        alignItems: 'center',
    },
    toolTipsIcon: {
        height: '25px',
        width: '25px',
        alignItems: 'center',
        margin: '0 0 0 5px',
    },
}

export default ({ uischema }) => {
    return uischema.title && !uischema.subtitle ? (
        <div style={labelStyles.root}>
            <label style={labelStyles.muiTitle}>
                {uischema.title}
                {uischema.hintText && (
                    <RichTooltip title={uischema.hintText} placement="right">
                        <Help sx={labelStyles.toolTipsIcon} />
                    </RichTooltip>
                )}
            </label>
        </div>
    ) : (
        <div style={labelStyles.root}>
            {uischema.title && (
                <label style={labelStyles.title}>
                    {uischema.title}
                    {uischema.hintText && (
                        <RichTooltip
                            title={uischema.hintText}
                            placement="right"
                        >
                            <Help sx={labelStyles.toolTipsIcon} />
                        </RichTooltip>
                    )}
                </label>
            )}
            {uischema.subtitle && (
                <label style={labelStyles.subtitle}>
                    {' '}
                    {uischema.subtitle}{' '}
                </label>
            )}
        </div>
    )
}
