import { Tab, Tabs } from '@mui/material'
import React from 'react'

const accountShowTabBarStyles = {
    tab: {
        borderBottom: 'solid 2px #DBDEE5',
        fontFamily: 'RobotoCondensedBold',
        fontSize: '15px',
        letterSpacing: '2px',
        color: '#999BA2',
    },
    tabsIndicator: {
        backgroundColor: '#635ee7',
    },
    tabsRoot: {
        margin: '24px 0',
    },
    tabRoot: {
        '&:disabled': {
            color: '#635ee7',
            opacity: '1',
        },
        color: '#635ee7',
        opacity: '1',
    },
}

export default ({ handleChange, tabValue, tabs }) => {
    return (
        <Tabs
            sx={{
                '&& .MuiTabs-indicator': accountShowTabBarStyles.tabsIndicator,
                ...accountShowTabBarStyles.tabsRoot,
            }}
            value={tabValue}
            onChange={handleChange}
        >
            {tabs.map((tab) => (
                <Tab
                    sx={{
                        ...accountShowTabBarStyles.tab,
                        '&& .Mui-selected': accountShowTabBarStyles.tabRoot,
                    }}
                    label={tab}
                    key={tab}
                />
            ))}
        </Tabs>
    )
}
