import { PasswordInput } from 'ra-ui-materialui'

export const passwordForm = [
    {
        title: 'Change Password',
        subheading:
            'Password must contain a mix of upper and lower case letters, numbers, and symbols, and be at least 15 characters long.',
        divider: false,
        fields: {
            currentPassword: {
                type: 'custom',
                component: ({ helperText }) => (
                    <PasswordInput
                        source="currentPassword"
                        helperText={helperText}
                    />
                ),
            },
            newPassword: {
                type: 'custom',
                component: ({ helperText }) => (
                    <PasswordInput
                        source="newPassword"
                        helperText={helperText}
                    />
                ),
            },
            confirmPassword: {
                type: 'custom',
                component: ({ helperText }) => (
                    <PasswordInput
                        source="confirmPassword"
                        helperText={helperText}
                    />
                ),
            },
        },
    },
]

export const emailForm = [
    {
        title: 'Change Email',
        divider: false,
        fields: {
            currentEmail: {
                type: 'text',
                source: 'currentEmail',
                disabled: true,
            },
            newEmail: {
                type: 'text',
                source: 'newEmail',
            },
            confirmEmail: {
                type: 'text',
                source: 'confirmEmail',
            },
            currentPassword: {
                type: 'custom',
                component: ({ helperText }) => (
                    <PasswordInput
                        source="currentPassword"
                        helperText={helperText}
                    />
                ),
            },
        },
    },
]
