import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const ACTIVITYPHASES = [
    'In Progress',
    'Initializing',
    'Succeeded',
    'Failed (Integration Issue)',
    'Failed (Platform Issue)',
    'Failed (Timeout)',
]

const useActivityStatusSelectFilterStyles = makeStyles({
    selectField: {
        height: '45px',
        borderRadius: 0,
        width: 'auto',
        '& > div': {
            fontSize: '13px',
            fontWeight: 'bold',
        },
        '&:hover': {
            '& > fieldset': {
                border: '2px solid black',
            },
        },
        minWidth: '150px',
    },
})

/**
 * Allows you to filter jobs based on status.phase
 * @param props
 * @returns {*}
 */
export default (props) => {
    const { handleChange, filters } = props
    const classes = useActivityStatusSelectFilterStyles()
    return (
        <FormControl variant="outlined">
            <InputLabel margin="dense"> Status </InputLabel>
            <Select
                onChange={(e) => {
                    handleChange('status.phase', e)
                }}
                value={filters?.status?.phase || ''}
                className={classes.selectField}
                label="Status"
            >
                {ACTIVITYPHASES.map((phase) => {
                    return (
                        <MenuItem key={phase} value={phase}>
                            {phase}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}
