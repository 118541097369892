import React, { useState } from 'react'
import ListTopToolbar from '../List/ListTopToolbar'
import AccountShowTabBar from './AccountShowTabBar'
import UserListTab from '../user/UserListTab'
import { LinearProgress } from '@mui/material'
import { useGetOne, EditBase } from 'react-admin'
import CompanyEdit from '../partners/Edit/PartnerEditForm'
import CustomQuestionsForm from './CustomQuestionsForm'
import { connect } from 'react-redux'
import NotificationForm from './NotificationForm'
import { useSearchParams } from 'react-router-dom'
import SourceControlForm from './SourceControlForm'
import ApiPortal from './ApiPortal'

const AccountShow = ({ namespace, isParentOrg, userId, id, entitlements }) => {
    const isLiteAccount = entitlements?.level === 'lite'
    let [searchParams, setSearchParams] = useSearchParams()
    const [tabValue, setTabValue] = useState(
        searchParams.get('tab') ? parseInt(searchParams.get('tab')) : 0
    )

    const handleChange = (event, newValue) => {
        setTabValue(newValue)
        setSearchParams({ tab: newValue })
    }

    const {
        isLoading: orgLoading,
        data: organization,
    } = useGetOne('organizations', { id: id })

    let tabs
    if (isLiteAccount) {
        tabs = ['Company', 'Users', 'Notifications', 'Source Control']
    } else if (!isParentOrg) {
        tabs = ['Company', 'Users', 'Source Control']
    } else {
        // full customer account
        tabs = [
            'Company',
            'Users',
            'Notifications',
            'Integration Form',
            'Partner Form',
            'Source Control',
            'API Access',
        ]
    }

    if (orgLoading) {
        return <LinearProgress />
    }

    return (
        <>
            <ListTopToolbar pageTitle="Admin Settings" />
            <AccountShowTabBar
                tabValue={tabValue}
                handleChange={handleChange}
                tabs={tabs}
                isLiteAccount={isLiteAccount}
            />
            {tabs[tabValue] === 'Company' && (
                <EditBase id={organization?.id} resource="organizations">
                    <div style={{ margin: '20px' }}>
                        <CompanyEdit
                            namespace={namespace}
                            resource="organizations"
                        />
                    </div>
                </EditBase>
            )}
            {tabs[tabValue] === 'Users' &&
                namespace !== 'staging-preview' && ( //TODO: this is here for preview demo page not to view uses
                    <UserListTab
                        userId={userId}
                        namespace={namespace}
                        basePath="/users"
                        resource="users"
                    />
                )}
            {tabs[tabValue] === 'Notifications' && (
                <NotificationForm
                    resource="organizations"
                    record={organization}
                />
            )}
            {tabs[tabValue] === 'Integration Form' && (
                <CustomQuestionsForm
                    id={id}
                    formPath={'settings.submissionForm'}
                    formType="integration"
                />
            )}
            {tabs[tabValue] === 'Partner Form' && (
                <CustomQuestionsForm
                    id={id}
                    formPath={'partnerIntakeForm'}
                    formType="partner"
                />
            )}
            {tabs[tabValue] === 'Source Control' && (
                <SourceControlForm orgID={id} />
            )}
            {tabs[tabValue] === 'API Access' && <ApiPortal />}
        </>
    )
}

const mapStateToProps = (state) => ({
    isParentOrg: state.org.isParentOrg,
    entitlements: state.org.entitlements,
})

export default connect(mapStateToProps)(AccountShow)
