import { UploadLogoToOrg } from '../../react_admin/LogoUploader'

const fontRange = (start, increment, count) =>
    [...Array(count).keys()].map((number) => {
        const val = (number * increment + start).toString() + 'px'
        return { id: val, name: val }
    })

export const themeConfigSchema = (orgName) => [
    {
        title: 'THEME COLORS',
        docLink:
            'https://docs.pandium.com/platform-guides/administrator-guides/marketplace-settings#marketplace_theme',
        subheading:
            'Select a primary, secondary and background color for your Marketplace.',
        fields: {
            'configs.palette.primary.main': {
                type: 'colorPicker',
                label: 'Primary Color',
            },
            'configs.palette.secondary.main': {
                type: 'colorPicker',
                label: 'Secondary Color',
            },
            'configs.palette.background.default': {
                type: 'colorPicker',
                label: 'Background Color',
            },
        },
    },
    {
        title: 'TEXT STYLES & FORMATTING',
        docLink:
            'https://docs.pandium.com/platform-guides/administrator-guides/marketplace-settings#text_styling',
        subheading:
            'This will be shown in search results, the homepage and category pages.',
        fields: {
            'configs.typography.fontFamily': {
                type: 'fontPicker',
            },
            'configs.typography.h3.fontSize': {
                label: 'Title Font Size',
                type: 'dropDown',
                choices: fontRange(20, 4, 14),
            },
            'configs.typography.subtitle1.fontSize': {
                label: 'Subtitle Font Size',
                type: 'dropDown',
                choices: fontRange(12, 2, 14),
            },
            'configs.dateFormat': {
                label: 'Date Format',
                type: 'dropDown',
                choices: [
                    { id: 'M/d/yyyy p', name: '1/15/1999, 12:00 AM' },
                    { id: 'M/d/yyyy pp', name: '1/15/1999, 12:00:00 AM' },
                    { id: 'MMM/d/yyyy p', name: 'Jan/15/1999, 12:00 AM' },
                    {
                        id: 'MMM/d/yyyy pp',
                        name: 'Jan/15/1999, 12:00:00 AM',
                    },
                    { id: 'd/M/yyyy p', name: '1/15/1999, 12:00 AM' },
                    { id: 'd/M/yyyy pp', name: '15/1/1999, 12:00:00 AM' },
                    { id: 'yyyy/M/d p', name: '1999/1/15, 12:00 AM' },
                    { id: 'yyyy/M/d pp', name: '1999/1/15, 12:00:00 AM' },
                ],
            },
        },
    },
    {
        title: 'CONFIGURE COMPONENTS',
        docLink:
            'https://docs.pandium.com/platform-guides/administrator-guides/marketplace-settings#configurecomponents',
        subheading: '',
        fields: {
            'configs.marketplaceSettings.justify': {
                type: 'dropDown',
                label: 'Marketplace Justification',
                defaultValue: 'left',
                choices: [
                    { id: 'left', name: 'Left' },
                    { id: 'center', name: 'Center' },
                    { id: 'right', name: 'Right' },
                ],
            },
            'configs.sortOrder': {
                type: 'dropDown',
                label: 'Marketplace Sort Order',
                choices: [
                    {
                        id: 'alphabetical',
                        name: 'Alphabetical',
                    },
                    {
                        id: 'orderIndex',
                        name: 'Order Index',
                    },
                ],
            },
            'configs.marketplaceSettings.fullWidthMarketplace': {
                type: 'boolean',
                label: 'Full-Width Marketplace',
            },
            'configs.marketplaceSettings.hideScrollBar': {
                type: 'boolean',
                label: 'Hide Scrollbar',
            },
            'configs.marketplaceSettings.hasSearchbar': {
                type: 'boolean',
                label: 'Display Searchbar',
            },
            'configs.marketplaceSettings.hasCategories': {
                label: 'Display Categories',
                type: 'boolean',
            },
            'configs.marketplaceSettings.displayCarousel': {
                label: 'Display Carousel Feature',
                type: 'boolean',
            },
            'configs.marketplaceSettings.separateApps': {
                label: 'Separate Marketplace and Apps',
                type: 'boolean',
            },
            'configs.marketplaceSettings.displaySeparateAppsButton': {
                label: 'Separate Marketplace Button',
                type: 'boolean',
                showIf: 'configs.marketplaceSettings.separateApps',
            },
            'configs.marketplaceSettings.hasInstalledSearchbar': {
                label: 'Search Bar for Installed Apps',
                type: 'boolean',
                showIf: 'configs.marketplaceSettings.separateApps',
            },
            'configs.marketplaceSettings.jointCronSelector': {
                label: 'Include Pause in Sync Dropdown',
                type: 'boolean',
            },
            'configs.searchIcon.color': {
                type: 'colorPicker',
                label: 'Search Icon',
            },
            'configs.toolTips.iconColor': {
                type: 'colorPicker',
                label: 'Tool Tip Icon',
            },
            'configs.marketplaceSettings.cardBorderRadius': {
                type: 'dropDown',
                label: 'Card Border-Radius',
                choices: [
                    { id: '0px', name: '0px' },
                    { id: '4px', name: '4px' },
                    { id: '8px', name: '8px' },
                    { id: '12px', name: '12px' },
                ],
            },
            'configs.marketplaceSettings.carouselWidth': {
                type: 'dropDown',
                label: 'Carousel Width',
                defaultValue: '1',
                choices: [
                    { name: '50%', id: '.5' },
                    { name: '60%', id: '.6' },
                    { name: '70%', id: '.7' },
                    { name: '80%', id: '.8' },
                    { name: '90%', id: '.9' },
                    { name: 'full width', id: '1' },
                ],
            },
            'configs.marketplaceSettings.integrationCardBoxShadow': {
                type: 'text',
                label: 'Integration Card Box Shadow',
            },
            'configs.marketplaceSettings.integrationCardHoverShadow': {
                type: 'text',
                label: 'Integration Card Hover Shadow',
            },
            'configs.marketplaceSettings.integrationCardClickShadow': {
                type: 'text',
                label: 'Integration Card Click Shadow',
            },
            'configs.marketplaceSettings.categoryTileCount': {
                type: 'number',
                label: 'Category Tile Count',
                defaultValue: '3',
                min: '1',
                max: '20',
            },
            'configs.marketplaceSettings.flagType': {
                type: 'dropDown',
                label: 'Flag Type',
                defaultValue: 'topRight',
                choices: [
                    { id: 'fullWidthTop', name: 'Full Width Top' },
                    { id: 'topLeft', name: 'Top Left' },
                    { id: 'topRight', name: 'Top Right' },
                    { id: 'fullWidthBottom', name: 'Full Width Bottom' },
                    { id: 'bottomLeft', name: 'Bottom Left' },
                    { id: 'bottomRight', name: 'Bottom Right' },
                ],
            },
        },
    },
    {
        title: 'Integration Hero Banner',
        subheading:
            'This will be shown above the homepage. (set the hero content in the content tab)',
        fields: {
            'configs.marketplaceSettings.heroBanner.integrationList.enabled': {
                label: 'Enable Hero Banner',
                type: 'boolean',
            },
            'configs.marketplaceSettings.heroBanner.integrationList.height': {
                type: 'number',
                label: 'Height of hero banner in px',
                defaultValue: '400',
                min: '0',
                showIf:
                    'configs.marketplaceSettings.heroBanner.integrationList.enabled',
            },
            'configs.marketplaceSettings.heroBanner.integrationList.backgroundImage': {
                type: 'custom',
                component: (props) => <UploadLogoToOrg {...props} />,
                label: 'Banner Background Image',
                showIf:
                    'configs.marketplaceSettings.heroBanner.integrationList.enabled',
            },
            'configs.marketplaceSettings.heroBanner.integrationList.heroImage': {
                type: 'custom',
                component: (props) => <UploadLogoToOrg {...props} />,
                label: 'Hero Image',
                showIf:
                    'configs.marketplaceSettings.heroBanner.integrationList.enabled',
            },
            'configs.marketplaceSettings.heroBanner.integrationList.backgroundColor': {
                type: 'colorPicker',
                label: 'Background Color',
                showIf:
                    'configs.marketplaceSettings.heroBanner.integrationList.enabled',
            },
            'configs.marketplaceSettings.heroBanner.integrationList.gradient': {
                label: 'Use Background Gradient',
                type: 'boolean',
                showIf:
                    'configs.marketplaceSettings.heroBanner.integrationList.enabled',
            },
            'configs.marketplaceSettings.heroBanner.integrationList.gradientSecondaryColor': {
                type: 'colorPicker',
                label: 'Secondary Gradient Color',
                // Uses a comma separated string for multiple conditions
                showIf:
                    'configs.marketplaceSettings.heroBanner.integrationList.gradient,configs.marketplaceSettings.heroBanner.integrationList.enabled',
            },
            'configs.marketplaceSettings.heroBanner.integrationList.gradientAngle': {
                type: 'number',
                label: 'Gradient Angle',
                defaultValue: '0',
                min: '0',
                max: '359',
                showIf:
                    'configs.marketplaceSettings.heroBanner.integrationList.gradient,configs.marketplaceSettings.heroBanner.integrationList.enabled',
            },
            'configs.marketplaceSettings.heroBanner.integrationList.textColor': {
                type: 'colorPicker',
                label: 'Text Color',
                showIf:
                    'configs.marketplaceSettings.heroBanner.integrationList.enabled',
            },
            'configs.marketplaceSettings.heroBanner.integrationList.secondaryTextColor': {
                type: 'colorPicker',
                label: 'Secondary Text Color',
                showIf:
                    'configs.marketplaceSettings.heroBanner.integrationList.enabled',
            },
            'configs.marketplaceSettings.heroBanner.integrationList.primaryButton.enabled': {
                label: 'Enable Primary Button',
                type: 'boolean',
                showIf:
                    'configs.marketplaceSettings.heroBanner.integrationList.enabled',
            },
            'configs.marketplaceSettings.heroBanner.integrationList.primaryButton.url': {
                label: 'Primary Button Redirect URL',
                type: 'text',
                showIf:
                    'configs.marketplaceSettings.heroBanner.integrationList.enabled,configs.marketplaceSettings.heroBanner.integrationList.primaryButton.enabled',
            },
            'configs.marketplaceSettings.heroBanner.integrationList.secondaryButton.enabled': {
                label: 'Enable Secondary Button',
                type: 'boolean',
                showIf:
                    'configs.marketplaceSettings.heroBanner.integrationList.enabled',
            },
            'configs.marketplaceSettings.heroBanner.integrationList.secondaryButton.url': {
                label: 'Secondary Button Redirect URL',
                type: 'text',
                showIf:
                    'configs.marketplaceSettings.heroBanner.integrationList.enabled,configs.marketplaceSettings.heroBanner.integrationList.secondaryButton.enabled',
            },
        },
    },
    {
        title: 'MARKETPLACE SSO BACKGROUND CONNECT',
        subheading: `This enables automatic SSO for ${orgName} if it’s configured.`,
        docLink:
            'https://docs.pandium.com/platform-guides/administrator-guides/marketplace-settings#enable-background-connect',
        fields: {
            'configs.marketplaceSettings.storeBackgroundConnect': {
                label: 'Enable Background Connect',
                type: 'boolean',
            },
        },
    },
    {
        title: 'MARKETPLACE VERSION SETTINGS',
        subheading:
            'Customers with existing marketplaces may want to test their content on the Pandium Sandbox before deploying new layouts.',
        fields: {
            'configs.marketplaceSettings.IntegrationShow2024': {
                type: 'boolean',
                label: 'Use 2024 Version of App Details Page',
            },
        },
    },
]

export const marketplaceContentSchema = () => [
    {
        title: 'APP LIST CONTENT',
        subheading: '',
        docLink: 'https://docs.pandium.com/marketplaces/marketplace-settings',
        fields: {
            'configs.marketplaceSettings.marketplaceTitle': {
                type: 'text',
                label: 'Marketplace Title',
                helperText: 'The marketplace title text',
            },
            'configs.marketplaceSettings.marketplaceSubheader': {
                type: 'text',
                label: 'Marketplace Subheader',
                helperText: 'The subheader placed below the marketplace title',
            },
            'configs.marketplaceSettings.categoriesTitle': {
                type: 'text',
                label: 'Categories Title',
                helperText: 'The name for the categories filter sidebar',
            },
            'configs.marketplaceSettings.appListTitle': {
                type: 'text',
                label: 'Apps List Title',
                helperText: 'The title placed above the list of apps',
            },
            'configs.marketplaceSettings.appListSubheader': {
                type: 'text',
                label: 'Apps List Subheader',
                helperText:
                    'The subheader placed below the title of the list of apps',
            },
            'configs.marketplaceSettings.carouselTitle': {
                type: 'text',
                label: 'Marketplace Carousel Title',
                helperText: `The name for the marketplace carousel, if left blank default name will be 'Featured'`,
            },
            'configs.marketplaceSettings.heroBanner.integrationList.heroBannerTitleText': {
                type: 'text',
                label: 'Hero Banner Title Text',
                helperText: 'The marketplace title text',
            },
            'configs.marketplaceSettings.heroBanner.integrationList.heroBannerSubText': {
                type: 'text',
                label: 'Hero Banner Sub Text',
                helperText: 'The subheader placed below the marketplace title',
            },
            'configs.marketplaceSettings.heroBanner.integrationList.heroBannerPrimaryBtnText': {
                type: 'text',
                label: 'Hero Banner Primary Button Text',
            },
            'configs.marketplaceSettings.heroBanner.integrationList.heroBannerSecondaryBtnText': {
                type: 'text',
                label: 'Hero Banner Secondary Button Text',
            },
            'configs.marketplaceSettings.myAppsButtonLabel': {
                label: '"My Apps" button label (link to installed apps)',
                type: 'text',
                defaultValue: 'My apps',
                helperText:
                    'Separate app buttons must be turned on in the "Theme" tab',
            },
            'configs.marketplaceSettings.searchbarText': {
                type: 'text',
                label: 'Searchbar placeholder text',
                defaultValue: 'Search integration',
                helperText: 'search bar must be turned on in the "Theme" tab',
            },
            'configs.marketplaceSettings.integrationNullSearchMessage': {
                type: 'text',
                label: 'Null search result message',
                defaultValue: 'No results found',
            },
            'configs.marketplaceSettings.learnMoreButtonLabel': {
                label:
                    '"Learn More" button label (link to individual app detail pages)',
                type: 'text',
                defaultValue: 'Learn more',
            },
        },
    },
    {
        title: 'APP DETAIL CONTENT',
        subheading: '',
        fields: {
            'configs.marketplaceSettings.backButtonLabel': {
                label:
                    '“All Integrations” (Back button on integration details page)',
                type: 'text',
                defaultValue: 'All integrations',
            },
            'configs.marketplaceSettings.externalIntegrationButton': {
                type: 'text',
                label: 'External App Button',
                helperText:
                    'The button display text for the external app details page',
            },
            'configs.marketplaceSettings.installedExternalIntegrationButton': {
                type: 'text',
                label: 'Installed External App Button',
                helperText:
                    'The button display text for installed external apps',
            },
            'configs.marketplaceSettings.connectButtonLabel': {
                label:
                    '“Connect App” (Integration Detail page for Internal Apps)',
                type: 'text',
                defaultValue: 'Connect app',
            },
            'configs.marketplaceSettings.syncLink': {
                type: 'text',
                label: 'Sync Link',
                helperText:
                    'The link display text for the Sync Schedule link on a tenant page',
            },
            'configs.marketplaceSettings.devUrlText': {
                type: 'text',
                label: 'Developer Website URL Text',
                helperText: `Update URL text or leave blank for default text 'Developer Website'`,
            },
            'configs.marketplaceSettings.supportUrlText': {
                type: 'text',
                label: 'Support URL Text',
                helperText: `Update URL text or leave blank for default text 'Get Support'`,
            },
            'configs.marketplaceSettings.docUrlText': {
                type: 'text',
                label: 'Documentation URL Text',
                helperText: `Update URL text or leave blank for default text 'Documentation'`,
            },
            'configs.marketplaceSettings.instancesButtonLabel': {
                label:
                    '“App Instances” (Integration Detail page when 1+ installed)',
                type: 'text',
                defaultValue: 'App instances',
            },
        },
    },
    {
        title: 'INSTALLED APP LIST CONTENT',
        subheading: '',
        fields: {
            'configs.marketplaceSettings.moreAppsButtonLabel': {
                label: '"More Apps" button label (link to all apps)',
                type: 'text',
                defaultValue: 'Get more apps',
                helperText:
                    'Separate app buttons must be turned on in the "Theme" tab',
            },
            'configs.marketplaceSettings.syncButton': {
                type: 'text',
                label: 'Sync Button',
                defaultValue: 'Sync now',
                helperText:
                    'The button display text for the Sync Button on a tenant page',
            },
            'configs.marketplaceSettings.connectionSettingsLabel': {
                label: '“Connection Settings” (Installed Apps Page hamburger)',
                type: 'text',
                defaultValue: 'Connection Settings',
            },
            'configs.marketplaceSettings.logsLabel': {
                label: '“Sync Logs” (Installed Apps Page hamburger)',
                type: 'text',
                defaultValue: 'Sync logs',
            },
            'configs.marketplaceSettings.connectButtonLabel': {
                label: 'Connect” (Installed apps tile)',
                type: 'text',
                defaultValue: 'Connect',
            },
            'configs.marketplaceSettings.reconnectButtonLabel': {
                label: 'Reconnect” (Installed apps tile)',
                type: 'text',
                defaultValue: 'Reconnect',
            },
            'configs.marketplaceSettings.tenantSearchbarText': {
                type: 'text',
                label: 'Searchbar placeholder text for installed apps',
                defaultValue: 'Search installed apps',
                helperText: 'search bar must be turned on in the "Theme" tab',
            },
            'configs.marketplaceSettings.tenantNullSearchMessage': {
                type: 'text',
                label: 'Null search result message for installed apps',
                defaultValue: 'No results found',
            },
        },
    },
]

export const galleryCustomizationSchema = () => [
    {
        title: 'GALLERY SETTINGS',
        subheading: '',
        fields: {
            'configs.gallery.title': {
                type: 'text',
                label: 'Page Title',
                helperText:
                    'This text will appear in the browser tab of the gallery page',
            },
        },
    },
    {
        title: 'BUTTON SETTINGS',
        subheading:
            'Customize the category buttons visible on the integration page, and on the homepage in mobile view.',
        fields: {
            'configs.gallery.buttons.categoryButtonColor': {
                type: 'colorPicker',
                label: 'Button Background',
            },
            'configs.gallery.buttons.categoryButtonText': {
                type: 'colorPicker',
                label: 'Button Text',
            },
        },
    },
    {
        title: 'HERO BANNER CONFIGURATION',
        subheading: '',
        fields: {
            'configs.gallery.banner.useBanner': {
                type: 'boolean',
                label: 'Display Hero Banner',
            },
            'configs.gallery.banner.html': {
                type: 'textarea',
                label: 'Hero HTML',
                helperText: 'HTML to be displayed on hero banner',
                showIf: 'configs.gallery.banner.useBanner',
            },
            'configs.gallery.banner.css': {
                type: 'textarea',
                label: 'Hero CSS',
                helperText:
                    'CSS to support the above HTML. Please prefix each definition with the class .pandiumHeroBanner',
                showIf: 'configs.gallery.banner.useBanner',
            },
            'configs.gallery.banner.background': {
                type: 'custom',
                component: (props) => <UploadLogoToOrg {...props} />,
                label: 'Hero Banner Background Image',
                showIf: 'configs.gallery.banner.useBanner',
            },
            'configs.gallery.banner.hero': {
                type: 'custom',
                component: (props) => <UploadLogoToOrg {...props} />,
                label: 'Hero Image',
                showIf: 'configs.gallery.banner.useBanner',
            },
        },
    },
    {
        title: 'HERO FOOTER CONFIGURATION',
        subheading: '',
        fields: {
            'configs.gallery.lowerBanner.useBanner': {
                type: 'boolean',
                label: 'Display Hero Footer',
            },
            'configs.gallery.lowerBanner.html': {
                type: 'textarea',
                label: 'Hero Footer HTML',
                helperText: 'HTML to be dispalyed on lower banner',
                showIf: 'configs.gallery.lowerBanner.useBanner',
            },
            'configs.gallery.lowerBanner.css': {
                type: 'textarea',
                label: 'Hero Footer CSS',
                helperText:
                    'CSS to support the above HTML. Please prefix each definition with the class .pandiumHeroFooter',
                showIf: 'configs.gallery.lowerBanner.useBanner',
            },
        },
    },
    {
        title: 'CUSTOM HTML',
        subheading: '',
        fields: {
            'configs.gallery.headTags': {
                type: 'textarea',
                label: 'Custom Head Tags',
                helperText:
                    'HTML code that will be inserted inside the head tag of the gallery page',
            },
            'configs.gallery.topBanner': {
                type: 'textarea',
                label: 'Top Banner',
                helperText:
                    'HTML code that will be inserted at the top of the body tag of the gallery page',
            },
            'configs.gallery.topBannerCss': {
                type: 'textarea',
                label: 'Top Banner CSS',
                helperText:
                    'CSS to support the above HTML. Please prefix each definition with the class .pandiumTopBanner',
            },
            'configs.gallery.footer': {
                type: 'textarea',
                label: 'Footer',
                helperText:
                    'HTML code that will be inserted at the bottom of the body tag of the gallery page',
            },
            'configs.gallery.footerCss': {
                type: 'textarea',
                label: 'Footer CSS',
                helperText:
                    'CSS to support the above HTML. Please prefix each definition with the class .pandiumTopBanner',
            },
        },
    },
    {
        title: 'HUBSPOT FORM',
        subheading: 'Embed a HubSpot form into your tile detail pages.',
        fields: {
            'configs.gallery.hubspotFormHeading': {
                type: 'text',
                label: 'heading',
                helperText: 'give your form a heading',
            },
            'configs.gallery.hubspotPortalId': {
                type: 'text',
                label: 'Portal Id',
                helperText: 'get this from Hubspot',
            },
            'configs.gallery.hubspotFormId': {
                type: 'text',
                label: 'Form Id',
                helperText: 'get this from Hubspot',
            },
            'configs.gallery.hubspotCss': {
                type: 'textarea',
                label: 'Hubspot CSS',
                helperText:
                    'CSS to support the above embedded form. Please prefix each definition with the class .pandiumHubspotForm',
            },
        },
    },
]
