import React from 'react'
import PandiForm from '../../themeConfig/PandiForm'

export default ({ empName }) => {
    const schema = [
        {
            title: 'NAMING AND LINKS',
            subheading: `This information will appear in the ${empName} marketplace.`,
            divider: false,
            fields: {
                'marketplaceSettings.emp.longName': {
                    type: 'text',
                    label: 'Integration Name',
                },
                'marketplaceSettings.emp.supportURL': {
                    type: 'text',
                    label: 'Support URL',
                },
                'marketplaceSettings.emp.docURL': {
                    type: 'text',
                    label: 'Documentation URL',
                },
            },
        },
    ]

    return <PandiForm schema={schema} />
}
