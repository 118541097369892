import React, { useState } from 'react'
import { LinearProgress } from '@mui/material'
import { isEmpty } from 'lodash-es'
import { connect } from 'react-redux'
import { PageTitle } from '../../../common'
import IntegrationEditFormWrapper from './IntegrationEditFormWrapper'
import PandiForm from '../../themeConfig/PandiForm'
import PartnerSubmissionForm from './PartnerSubmissionForm'
import {
    externalConfigs,
    internalConfigsEdit,
} from '../IntegrationCreateEditSchemas'
import Divider from '../../../jsonFormComponents/Divider'

const IntegrationEditConfigure = ({
    isParentOrg,
    hasCustomSubmissionForm,
    ...props
}) => {
    const { integration } = props
    const [partnerFormErrors, setPartnerFormErrors] = useState([])
    const schema =
        integration.type === 'External' ? externalConfigs : internalConfigsEdit
    const showCustomSubmissionForm =
        integration.approvalStatus !== 'Approved' && hasCustomSubmissionForm

    return (
        <div>
            <PageTitle title={`Configure: ${integration.name}`} />
            {!isEmpty(integration) ? (
                <IntegrationEditFormWrapper
                    showDelete={true}
                    disableSave={!isEmpty(partnerFormErrors)}
                    isParentOrg={isParentOrg}
                    {...props}
                >
                    <PandiForm
                        schema={schema}
                        disabled={
                            isParentOrg &&
                            integration.approvalStatus !== 'Approved'
                        }
                    />
                    {showCustomSubmissionForm && <Divider />}
                    {showCustomSubmissionForm && (
                        <PartnerSubmissionForm
                            values={integration}
                            readOnly={isParentOrg}
                            errors={partnerFormErrors}
                            setErrors={setPartnerFormErrors}
                        />
                    )}
                </IntegrationEditFormWrapper>
            ) : (
                <LinearProgress />
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    isParentOrg: state.org.isParentOrg,
    hasCustomSubmissionForm: Boolean(state.org.customSubmissionForm),
})

export default connect(mapStateToProps)(IntegrationEditConfigure)
