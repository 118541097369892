import React from 'react'
import { Edit, TextInput, BooleanInput, SelectInput } from 'ra-ui-materialui'
import { useForm, FormProvider, useFieldArray } from 'react-hook-form'
import { Button, Typography, Grid, Divider } from '@mui/material'
import { camelCase, get, set, isEmpty } from 'lodash-es'
import DeleteIcon from '@mui/icons-material/Delete'
import { urlValidation } from '../../inputValidators'
import { useRecordContext, useUpdate, useNotify } from 'react-admin'

const choices = [
    { id: 'string', name: 'Text Input' },
    { id: 'boolean', name: 'Terms and Conditions' },
]

const titleData = {
    integration: {
        title: 'Integration Submission Custom Setup',
        subtitle:
            'Add required information when Partners submit integrations for review.',
    },
    partner: {
        title: 'Custom Partner Details',
        subtitle: 'Add required information when Partners create a profile.',
    },
}

const InnerFormStyles = {
    requiredToggle: {
        '& .MuiFormControlLabel-root': {
            flexDirection: 'row-reverse',
            marginLeft: '0px',
            marginTop: '15px',
            width: '115px',
        },
    },
    deleteButtonContainer: {
        display: 'grid',
        '& > button': {
            color: 'black',
        },
    },
    saveButtonContainer: {
        display: 'grid',
        justifyItems: 'right',
        '& > button': {
            width: '90px',
        },
    },
    textField: {
        minWidth: '95%',
    },
}

const validateCreateQuestions = (values) => {
    const errors = {}
    let badType =
        values.questions?.length > 0 &&
        values.questions
            ?.map((question) => (question.type === -1 ? 1 : 0))
            .reduce((a, b) => a + b)
    if (badType >= 1) {
        errors.questions = 'Must select type'
    }
    let badUrl =
        values.questions?.length > 0 &&
        values.questions
            ?.map((question) =>
                question.type === 'boolean' && urlValidation(question.label)
                    ? 1
                    : 0
            )
            .reduce((a, b) => a + b)
    if (badUrl >= 1) {
        errors.questions = 'Must be valid url'
    }

    return { values: values, errors: errors }
}

export const InnerForm = ({ formPath }) => {
    const record = useRecordContext()
    const notify = useNotify()
    const [update] = useUpdate()

    let defaultSchemas = {
        schema: {
            type: 'object',
            properties: {},
            required: [],
        },
        uischema: {
            type: 'VerticalLayout',
            elements: [],
        },
    }
    // OR provides default schemas if get returns null
    let { schema, uischema } = !isEmpty(get(record, formPath))
        ? get(record, formPath)
        : defaultSchemas

    // This form is stored in JSONforms format. Here we convert any perviously
    //saved version of the form into an array that we can pass into the initial
    // values of the creation form below.
    const unpackSchemas = () => {
        const newArrayForFormValues = uischema?.elements.map((element) => {
            const split = element.scope.split('/')
            const key = split[split.length - 1]
            return {
                label: element.label,
                required: schema.required.includes(key),
                ...schema.properties[key],
            }
        })
        return newArrayForFormValues
    }

    // Convert the array of form values entered by the user into a schema and
    // uischema for JSONforms.
    const packSchemas = (values) => {
        const newSchemaProperties = {}
        const newSchemaRequired = []
        const newUiElements = []
        values.questions?.forEach((question) => {
            if (question.label) {
                const { required, label, ...properties } = question
                const key = camelCase(label)
                newSchemaProperties[key] = { ...properties }
                if (newSchemaProperties[key].type === 'string') {
                    newSchemaProperties[key].minLength = 1
                } else if (newSchemaProperties[key].type === 'boolean') {
                    newSchemaProperties[key].enum = [true]
                }
                if (required) {
                    newSchemaRequired.push(key)
                }
                newUiElements.push({
                    type: 'Control',
                    scope: `#/properties/${key}`,
                    label: label,
                    // turn on text wrap
                    options: question.type === 'string' ? { multi: true } : {},
                })
            }
        })
        if (newUiElements.length === 0) {
            // handle the case where user deletes all questions:
            // form no longer exists and shouldn't be shown to partners.
            set(record, formPath, null)
        } else {
            set(record, formPath, {
                schema: {
                    type: 'object',
                    properties: newSchemaProperties,
                    required: newSchemaRequired,
                },
                uischema: {
                    type: 'VerticalLayout',
                    elements: newUiElements,
                },
            })
        }
        return record
    }

    const methods = useForm({
        defaultValues: { questions: unpackSchemas() },
        resolver: validateCreateQuestions,
        mode: 'onBlur',
    })
    const { fields, append, remove } = useFieldArray({
        control: methods.control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'questions', // unique name for your Field Array,
    })

    return (
        <FormProvider {...methods}>
            <Grid item xs={12} container spacing={3}>
                <>
                    {fields.map((field, index) => (
                        <Grid
                            item
                            xs={12}
                            container
                            spacing={0}
                            key={field.id}
                            aria-label={`Form for Custom Field ${index + 1}`}
                        >
                            <Grid item xs={5}>
                                <TextInput
                                    helperText={false}
                                    label={
                                        methods.getValues().questions[index]
                                            .type === 'string'
                                            ? `Custom Field ${index + 1}`
                                            : 'Paste Terms and Conditions URL'
                                    }
                                    source={`questions.${index}.label`}
                                    sx={InnerFormStyles.textField}
                                    validate={
                                        methods.getValues().questions[index]
                                            .type === 'string'
                                            ? null
                                            : urlValidation
                                    }
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <SelectInput
                                    emptyText={'Select One'}
                                    emptyValue={-1}
                                    fullWidth
                                    label={'Type'}
                                    source={`questions.${index}.type`}
                                    choices={choices}
                                    initialValue={'string'}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={1}
                                sx={InnerFormStyles.deleteButtonContainer}
                            >
                                <Button
                                    aria-label={`delete ${index + 1}`}
                                    onClick={() => remove(index)}
                                >
                                    <DeleteIcon />
                                </Button>
                            </Grid>
                            <Grid item xs={8}>
                                <TextInput
                                    fullWidth
                                    variant="standard"
                                    helperText={false}
                                    label="Add description (optional)"
                                    source={`questions.${index}.description`}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <BooleanInput
                                    label="required"
                                    source={`questions.${index}.required`}
                                    sx={InnerFormStyles.requiredToggle}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Divider />
                            </Grid>
                        </Grid>
                    ))}
                </>
                <Grid item xs={12}>
                    <Button
                        onClick={() => {
                            append({})
                        }}
                    >
                        + ADD FIELD
                    </Button>
                </Grid>
                <Grid item xs={7} sx={InnerFormStyles.saveButtonContainer}>
                    <Button
                        className="filledButton"
                        onClick={() => {
                            update('organizations', {
                                id: record.id,
                                data: { ...packSchemas(methods.getValues()) },
                                previousData: record,
                            })
                            notify('Questions Updated!')
                        }}
                        disabled={!methods.formState.isValid}
                    >
                        SAVE
                    </Button>
                </Grid>
            </Grid>
        </FormProvider>
    )
}

export default ({ formPath, formType, id }) => {
    return (
        <Grid container width="1100px">
            <Grid item xs={12}>
                <Typography
                    variant="h5"
                    sx={{
                        color: '#707279',
                    }}
                >
                    {titleData[formType].title}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>{titleData[formType].subtitle}</Typography>
            </Grid>
            <Edit
                sx={{
                    width: '100%',
                }}
                id={id}
                resource={'organizations'}
                actions={null}
                mutationMode="pessimistic"
                component="div"
                successMessage="Form successfully saved"
            >
                <InnerForm formPath={formPath} />
            </Edit>
        </Grid>
    )
}
