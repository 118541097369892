import React, { useState } from 'react'
import { SketchPicker } from 'react-color'
import { Typography, Popover, ClickAwayListener, Box } from '@mui/material'
import { Controller } from 'react-hook-form'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

const presetColors = [
    '#D0021B',
    '#F5A623',
    '#F8E71C',
    '#8B572A',
    '#7ED321',
    '#417505',
    '#BD10E0',
    '#9013FE',
    '#4A90E2',
    '#50E3C2',
    '#B8E986',
    '#000000',
    '#4A4A4A',
    '#9B9B9B',
    '#FFFFFF',
]

const colorPickerStyles = {
    container: (open) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        whiteSpace: 'normal',
        '& > button': {
            display: 'flex',
            flexDirection: 'column',
            padding: '7px 17px',
            margin: '19px 0',
            width: '220px',
            border: '1px solid',
            borderColor: open ? 'black' : 'grey',
            borderRadius: '4px',
            backgroundColor: 'white',
            '&:hover': {
                borderColor: 'black',
            },
            '& > label': {
                color: 'rgba(0, 0, 0, 0.6)',
                padding: '0px',
                fontSize: '12px',
                lineHeight: '16px',
                alignSelf: 'flex-start',
            },
            '& > span': {
                width: '100%',
                display: 'inline-flex',
                justifyContent: 'space-between',
                fontSize: '16px',
                fontWeight: 'bold',
                '& > span': {
                    width: '100%',
                    display: 'inline-flex',
                    justifyContent: 'space-between',
                    '& > svg': {
                        marginTop: '-10px',
                        color: 'rgba(0,0,0,0.54)',
                    },
                },
            },
        },
    }),
    colorBox: (hex) => ({
        height: '25px',
        width: '25px',
        backgroundColor: hex,
        marginTop: '-4px',
        borderRadius: '4px',
    }),
    button: (open) => ({}),
    popper: {
        zIndex: '1500',
    },
}

export const RenderColorPicker = ({
    input,
    label,
    modifiers,
    placement,
    theme,
}) => {
    const defaultColor = theme?.configs?.palette?.primary?.main

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleOpen = (event) => setAnchorEl(event.currentTarget)
    const handleClose = (event) => setAnchorEl(null)

    const [popoverAnchorOrigin, popoverTransformOrigin] =
        placement === 'right'
            ? [
                  {
                      vertical: 'center',
                      horizontal: 'right',
                  },
                  {
                      vertical: 'center',
                      horizontal: 'left',
                  },
              ]
            : [
                  {
                      vertical: 'bottom',
                      horizontal: 'center',
                  },
                  {
                      vertical: 'top',
                      horizontal: 'center',
                  },
              ]

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Box sx={colorPickerStyles.container(open)}>
                <Typography>{label}</Typography>
                <button
                    aria-controls={open ? 'open-color-picker' : undefined}
                    aria-label={label}
                    aria-haspopup="true"
                    onClick={handleOpen}
                >
                    <label>Hex</label>
                    <span>
                        <span>
                            {input.value?.replace('#', '').toUpperCase() ||
                                defaultColor?.replace('#', '').toUpperCase()}
                        </span>
                        <span>
                            <Box
                                sx={colorPickerStyles.colorBox(
                                    input.value || defaultColor
                                )}
                            />
                            {placement === 'right' ? (
                                <ArrowRightIcon />
                            ) : (
                                <ArrowDropDownIcon />
                            )}
                        </span>
                    </span>
                </button>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    role={undefined}
                    sx={{ ...colorPickerStyles.popper, ...modifiers }}
                    anchorOrigin={popoverAnchorOrigin}
                    transformOrigin={popoverTransformOrigin}
                >
                    <SketchPicker
                        presetColors={presetColors}
                        color={input.value || defaultColor}
                        onChange={(event, value) => {
                            input.onChange(event.hex)
                        }}
                    />
                </Popover>
            </Box>
        </ClickAwayListener>
    )
}

export default ({ source, label, modifiers = null, placement, theme }) => (
    <Controller
        name={source}
        render={({ field }) => (
            <RenderColorPicker
                input={field}
                label={label}
                modifiers={modifiers}
                placement={placement}
                theme={theme}
            />
        )}
    />
)
