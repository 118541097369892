import React from 'react'
import BaseShow from '../../Show/BaseShow'
import { SimpleShowLayout, useGetOne } from 'react-admin'
import BaseTopToolbar from '../../../react_admin/BaseTopToolbar'
import GeneralSection from './GeneralSection'
import PasswordSection from './PasswordSection'
import EmailSection from './EmailSection'
import { Divider } from '@mui/material'
import { useParams } from 'react-router-dom'

const UserShow = () => {
    const { id } = useParams()
    const { data: user } = useGetOne('users', { id: id })
    return (
        <BaseShow
            record={user}
            actions={null}
            TopToolbar={<BaseTopToolbar pageTitle="My Account" />}
        >
            <SimpleShowLayout>
                <GeneralSection record={user} />
                <Divider />
                <EmailSection record={user} />
                <Divider />
                <PasswordSection record={user} />
            </SimpleShowLayout>
        </BaseShow>
    )
}

export default UserShow
