import React from 'react'
import IntegrationShow from '../IntegrationShow'
import { Routes, Route } from 'react-router-dom'
import {
    useGetOne,
    Edit,
    useNotify,
    useRedirect,
    useGetRecordId,
} from 'react-admin'
import { LinearProgress } from '@mui/material'
import IntegrationEditConfigure from './IntegrationEditConfigure'
import IntegrationEditMarketplace from './IntegrationEditMarketplace'
import IntegrationEditConnection from './IntegrationEditConnection'
import { connect } from 'react-redux'
import { ReadFeedback, CreateFeedback } from './PartnerFeedbackBoxs'

const IntegrationEditWrapper = ({ isParentOrg, parentName }) => {
    const id = useGetRecordId()
    const { data: integration, isLoading } = useGetOne('integrations', {
        id: id,
    })
    const feedback = integration?.discussion?.feedback
    const showReadFeedback =
        feedback && !isParentOrg && integration?.approvalStatus !== 'Approved'
    const showCreateFeedback =
        isParentOrg &&
        ['Submitted', 'Reviewed'].includes(integration?.approvalStatus)
    const notify = useNotify()
    const redirect = useRedirect()
    const pageName = window.location.toString().split('/').pop()

    if (!integration || isLoading) {
        return <LinearProgress />
    }

    return (
        <>
            {showReadFeedback && (
                <ReadFeedback parentName={parentName} feedback={feedback} />
            )}
            <Routes>
                <Route
                    path="edit/configure"
                    element={
                        <IntegrationEditConfigure integration={integration} />
                    }
                />
                <Route
                    path="/edit/marketplace"
                    element={
                        <IntegrationEditMarketplace integration={integration} />
                    }
                />
                <Route
                    path="/edit/connection"
                    element={
                        <IntegrationEditConnection integration={integration} />
                    }
                />
                <Route
                    path="/edit"
                    element={<IntegrationShow integration={integration} />}
                />
            </Routes>
            {showCreateFeedback && (
                <Edit
                    component="div"
                    actions={null}
                    mutationOptions={{
                        onSuccess: () => {
                            notify('Feedback submitted')
                            redirect('/integrations')
                        },
                    }}
                    resource={'integrations'}
                    mutationMode="pessimistic"
                >
                    <CreateFeedback showBackButton={pageName !== 'edit'} />
                </Edit>
            )}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isParentOrg: state.org.isParentOrg,
        parentName: state.org.parentName,
    }
}

export default connect(mapStateToProps)(IntegrationEditWrapper)
